import React from 'react';

import { Route, Switch } from 'react-router';
import MessagesTableContainer from './views/messagesTable/MessagesTableContainer';

const AdminMessagesPages = () => {
	return (
		<Switch>
			<Route exact path='/admin/maintenance-messages' component={MessagesTableContainer} />
		</Switch>
	);
};

export default AdminMessagesPages;
