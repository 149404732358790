import { TranslationOutlined, CheckOutlined, MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import { EventCategory } from 'App/common/enums';
import { EventCategoryGrouping } from 'App/common/utils/eventCategoryGrouping.utils';
import { LocalStorage } from 'App/common/utils/localStorage.utils';
import i18n, { languages, fullLanguages } from 'i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Default } from '../Responsive/Default';
import { Mobile } from '../Responsive/Mobile';
import HomeMenuItem from './HomeMenuItem';

import './Navbar.less';

const NotLoggedInNavbar = () => {
	const { t } = useTranslation('page');
	const location = useLocation();
	const history = useHistory();
	const [openKeys, setOpenKeys] = useState([]);

	// const signUpMenuItem = (isMobile: boolean) => (
	// 	<Menu.Item key='/sign-up' icon={<UserAddOutlined />} className={isMobile ? '' : ''}>
	// 		<Link to='/sign-up'>{t('Navbar.SignUp')}</Link>
	// 	</Menu.Item>
	// );

	// const signInMenuItem = (isMobile: boolean) => (
	// 	<Menu.Item key='/sign-in' icon={<LoginOutlined />} className={isMobile ? '' : ''}>
	// 		<Link to='/sign-in'>{t('Navbar.SignIn')}</Link>
	// 	</Menu.Item>
	// );

	const clearMenu = () => {
		setOpenKeys([]);
	}

	const changeLanguage = (values: any) => {
		LocalStorage.language.save(values.key);
		i18n.changeLanguage(values.key);
	};
	const languagesMenuItem = (isMobile: boolean) => (
		<Menu.SubMenu
			key='languages'
			title={isMobile ? t('Navbar.Language') : i18n.language.toUpperCase()}
			icon={<TranslationOutlined className={isMobile ? '' : 'mr-0'} />}
			className={isMobile ? '' : ''}
		>
			{languages.map((language) => (
				<Menu.Item
					key={language}
					onClick={changeLanguage}
					icon={i18n.language === language && <CheckOutlined />}
				>
					{fullLanguages.find((lang) => lang.key === language).value}
				</Menu.Item>
			))}
		</Menu.SubMenu>
	);

	const navigateToSearch = () => {
		clearMenu();
		history.push('/#szukaj');
	}
	const searchEventsItem = () => (
		<Menu.Item
			key={'/#szukaj'}
			onClick={navigateToSearch}
		>
			{t('Navbar.SearchEvent').toUpperCase()}
		</Menu.Item>
	);

	const navigateToCategory = (category: EventCategory) => {
		clearMenu();
		history.push(`/?categories=${category}`);
	}
	const categoriesItem = (isMobile: boolean) => {
		const categories = EventCategoryGrouping.toOptions(t);
		
		return <Menu.SubMenu
			key='categories'
			title={t('Navbar.Categories').toUpperCase()}
		>
			{categories.map(category => category.children
				? (
					<Menu.SubMenu
						key={category.value}
						title={category.label}
					>
						{category.children.map(sub => (
							<Menu.Item
								className='category-option'
								key={sub.value}
								onClick={() => navigateToCategory(sub.value as any)}
							>
								{sub.label}
							</Menu.Item>
						))}
					</Menu.SubMenu>
				) : (
					<Menu.Item
						className='category-option'
						key={category.value}
						onClick={() => navigateToCategory(category.value as any)}
					>
						{category.label}
					</Menu.Item>
				))
			}
		</Menu.SubMenu>
	};

	const navigateToSubmitEvent = () => {
		clearMenu();
		history.push('/dodaj-wydarzenie');
	}
	const submitEventItem = () => (
		<Menu.Item
			key={'/dodaj-wydarzenie'}
			onClick={navigateToSubmitEvent}
		>
			{t('Navbar.AddEvent').toUpperCase()}
		</Menu.Item>
	)

	const navigateToContact = () => {
		clearMenu();
		history.push('/kontakt');
	}
	const contactItem = () => (
		<Menu.Item
			key={'/kontakt'}
			onClick={navigateToContact}
		>
			{t('Navbar.Contact').toUpperCase()}
		</Menu.Item>
	)

	const onOpenChange = _openKeys => {
		setOpenKeys([..._openKeys]);
	}

	const onClickMenu = () => {
		setOpenKeys(['notlogged-mobile']);
	}

	return (
		<>
			<Mobile>
				<Row>
					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-mobile' />
						</Menu>
					</Col>
					<Col span={8}>
						<Menu 
							mode='horizontal' 
							selectedKeys={[location.pathname]} 
							className='justify-content-end' 
							openKeys={openKeys} 
							onOpenChange={onOpenChange}
							theme={'dark'}
						>
							<Menu.SubMenu
								key='notlogged-mobile'
								className='float-right to-close'
								icon={<MenuOutlined className='mr-0' />}
								onTitleClick={onClickMenu}
							>
								<>
									{/* {signUpMenuItem(true)}
									{signInMenuItem(true)} */}
									{searchEventsItem()}
									{categoriesItem(true)}
									{submitEventItem()}
									{contactItem()}
									<Menu.Divider />
									{languagesMenuItem(true)}
								</>
							</Menu.SubMenu>
						</Menu>
					</Col>
				</Row>
			</Mobile>
			<Default>
				<Row justify='space-between'>
					<Col span={8}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-default' />
						</Menu>
					</Col>

					<Col span={16}>
						<Menu 
							mode='horizontal' 
							selectedKeys={[location.pathname]} 
							className='justify-content-end' 
							theme={'dark'}
							style={{ backgroundColor: 'black', color: 'white' }}
						>
							{/* {signUpMenuItem(false)}
							{signInMenuItem(false)}*/}
							{searchEventsItem()}
							{categoriesItem(false)}
							{submitEventItem()}
							{contactItem()}
							{languagesMenuItem(false)}
						</Menu>
					</Col>
				</Row>
			</Default>
		</>
	);
};

export default NotLoggedInNavbar;
