import React from 'react';

import './PrivacyPolicyContainer.less';

export const PrivacyPolicyContainer = () => {
	const sectionBreak = () => (
		<>
			<br />
			<hr />
			<br />
		</>
	);

	const phone = () => '881 966 232';

	const email = () => <a href='mailto:kalendarzmotoryzacyjny@gmail.com'>kalendarzmotoryzacyjny@gmail.com</a>;

	return (
		<div className='privacy-policy-container'>
			<h1>
				<b>POLITYKA PRYWATNOŚCI SERWISU INTERNETOWEGO KALENDARZ-MOTORYZACYJNY.PL</b>
			</h1>
			<br />
			<b>SPIS TREŚCI:</b>
			<br /> <br />
			<ol>
				<li>POSTANOWIENIA OGÓLNE</li>
				<li>PODSTAWY PRZETWARZANIA DANYCH</li>
				<li>CEL, PODSTAWA I OKRES PRZETWARZANIA DANYCH W SERWISIE INTERNETOWYM</li>
				<li>ODBIORCY DANYCH W SERWISIE INTERNETOWYM</li>
				<li>PROFILOWANIE W SERWISIE INTERNETOWYM</li>
				<li>PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ</li>
				<li>COOKIES W SERWISIE INTERNETOWYM I ANALITYKA</li>
				<li>POSTANOWIENIA KOŃCOWE</li>
			</ol>
			{sectionBreak()}
			<h2>1) POSTANOWIENIA OGÓLNE</h2>
			<ol>
				<li>
					Niniejsza polityka prywatności Serwisu Internetowego ma charakter informacyjny, co oznacza, że nie
					jest ona źródłem obowiązków dla Użytkowników Serwisu Internetowego. Polityka prywatności zawiera
					przede wszystkim zasady dotyczące przetwarzania danych osobowych przez Administratora w Serwisie
					Internetowym, w tym podstawy, cele i okres przetwarzania danych osobowych oraz prawa osób, których
					dane dotyczą, a także informacje w zakresie stosowania w Serwisie Internetowym plików Cookies oraz
					narzędzi analitycznych.
				</li>
				<li>
					Administratorem danych osobowych zbieranych za pośrednictwem Serwisu Internetowego jest Agnieszka
					Kujawa prowadząca działalność gospodarczą pod firmą AGNIESS.PL AGNIESZKA KUJAWA wpisana do
					Centralnej Ewidencji i Informacji o Działalności Gospodarczej Rzeczypospolitej Polskiej prowadzonej
					przez ministra właściwego do spraw gospodarki, posiadająca: adres do doręczeń: ul. Szkolna 43a,
					64-000 Turew, NIP 6981856234, REGON 386483806, adres poczty elektronicznej: {email()} oraz numer
					telefonu: {phone()} - zwana dalej „Administratorem” i będąca jednocześnie Usługodawcą Serwisu
					Internetowego.
				</li>
				<li>
					Dane osobowe w Serwisie Internetowym przetwarzane są przez Administratora zgodnie z obowiązującymi
					przepisami prawa, w szczególności zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE)
					2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
					danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
					(ogólne rozporządzenie o ochronie danych) - zwane dalej „RODO” lub „Rozporządzenie RODO”. Oficjalny
					tekst Rozporządzenia RODO:{' '}
					<a href='http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679'>
						http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679
					</a>
					.
				</li>
				<li>
					Korzystanie z Serwisu Internetowego jest dobrowolne. Podobnie związane z tym podanie danych
					osobowych przez korzystającego z Serwisu Internetowego Użytkownika jest dobrowolne, z zastrzeżeniem
					dwóch przypadków: (1) <b>zawarcie umowy z Administratorem</b> - gdy Użytkownik chce skorzystać z
					usług, w tym Usług Elektronicznych świadczonych przez Administratora, to niepodanie w przypadkach i
					w zakresie wskazanym na stronie Serwisu Internetowego, w Regulaminie oraz w niniejszej polityce
					prywatności danych osobowych niezbędnych do skorzystania z określonej usługi skutkuje brakiem
					możliwości skorzystania z tej usługi. Podanie danych osobowych jest w takim wypadku wymogiem umownym
					i jeżeli osoba, które dane dotyczą, chce skorzystać z usługi, w tym Usługi Elektronicznej
					świadczonej przez Administratora, to jest zobowiązana do podania wymaganych danych; (2){' '}
					<b>obowiązki ustawowe Administratora</b> - gdy Użytkownik zawiera z Administratorem odpłatną umowę
					(np. wynajmuje powierzchnię reklamową Serwisu), podanie danych osobowych jest wymogiem ustawowym
					wynikającym z powszechnie obowiązujących przepisów prawa nakładających na Administratora obowiązek
					przetwarzania danych osobowych (w celu prowadzenia ksiąg podatkowych).
				</li>
				<li>
					Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których przetwarzane
					przez niego dane osobowe dotyczą, a w szczególności jest odpowiedzialny i zapewnia, że zbierane
					przez niego dane są: (1) przetwarzane zgodnie z prawem; (2) zbierane dla oznaczonych, zgodnych z
					prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami; (3) merytorycznie
					poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane; (4) przechowywane w postaci
					umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia
					celu przetwarzania oraz (5) przetwarzane w sposób zapewniający odpowiednie bezpieczeństwo danych
					osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową
					utratą, zniszczeniem lub uszkodzeniem, za pomocą odpowiednich środków technicznych lub
					organizacyjnych.
				</li>
				<li>
					Uwzględniając charakter, zakres, kontekst i cele przetwarzania oraz ryzyko naruszenia praw lub
					wolności osób fizycznych o różnym prawdopodobieństwie i wadze zagrożenia, Administrator wdraża
					odpowiednie środki techniczne i organizacyjne, aby przetwarzanie odbywało się zgodnie z
					Rozporządzeniem RODO i aby móc to wykazać. Środki te są w razie potrzeby poddawane przeglądom i
					uaktualniane. Administrator stosuje środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu
					przez osoby nieuprawnione danych osobowych przesyłanych drogą elektroniczną.
				</li>
				<li>
					Wszelkie słowa, wyrażenia i akronimy występujące w niniejszej polityce prywatności i rozpoczynające
					się dużą literą (np. <b>Usługodawca</b>, <b>Serwis Internetowy</b>, <b>Usługa Elektroniczna</b>)
					należy rozumieć zgodnie z ich definicją zawartą w Regulaminie Serwisu Internetowego dostępnym na
					stronach Serwisu Internetowego.
				</li>
			</ol>
			<h2>2) PODSTAWY PRZETWARZANIA DANYCH</h2>
			<ol>
				<li>
					Administrator uprawniony jest do przetwarzania danych osobowych w przypadkach, gdy - i w takim
					zakresie, w jakim - spełniony jest co najmniej jeden z poniższych warunków: (1) osoba, której dane
					dotyczą wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub większej liczbie
					określonych celów; (2) przetwarzanie jest niezbędne do wykonania umowy, której stroną jest osoba,
					której dane dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem
					umowy; (3) przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na
					Administratorze; lub (4) przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
					interesów realizowanych przez Administratora lub przez stronę trzecią, z wyjątkiem sytuacji, w
					których nadrzędny charakter wobec tych interesów mają interesy lub podstawowe prawa i wolności
					osoby, której dane dotyczą, wymagające ochrony danych osobowych, w szczególności gdy osoba, której
					dane dotyczą, jest dzieckiem.
				</li>
				<li>
					Przetwarzanie danych osobowych przez Administratora wymaga każdorazowo zaistnienia co najmniej
					jednej z podstaw wskazanych w pkt. 2.1 polityki prywatności. Konkretne podstawy przetwarzania danych
					osobowych Użytkowników Serwisu Internetowego przez Administratora są wskazane w kolejnym punkcie
					polityki prywatności - w odniesieniu do danego celu przetwarzania danych osobowych przez
					Administratora.
				</li>
			</ol>
			<h2>3) CEL, PODSTAWA I OKRES PRZETWARZANIA DANYCH W SERWISIE INTERNETOWYM</h2>
			<ol>
				<li>
					Każdorazowo cel, podstawa i okres oraz odbiorcy danych osobowych przetwarzanych przez Administratora
					wynika z działań podejmowanych przez danego Użytkownika w Serwisie Internetowym.
				</li>
				<li>
					Administrator może przetwarzać dane osobowe w Serwisie Internetowym w następujących celach, na
					następujących podstawach oraz przez następujący okres:
				</li>
			</ol>
			<table>
				<tr>
					<th>Cel przetwarzania danych</th>
					<th>Podstawa prawna przetwarzania danych</th>
					<th>Okres przechowywania danych</th>
				</tr>
				<tr>
					<td>
						Zawarcie umowy, w tym umowy o świadczenie Usług Elektronicznych, lub podjęcie działań na żądanie
						osoby, której dane dotyczą, przed zawarciem umowy
					</td>
					<td>
						Artykuł 6 ust. 1 lit. b) Rozporządzenia RODO (umowa) - przetwarzanie jest niezbędne do zawarcia
						i wykonania umowy o korzystanie z Usług Elektronicznych, której stroną jest osoba, której dane
						dotyczą, lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy
					</td>
					<td>
						Dane są przechowywane przez okres niezbędny do wykonania, rozwiązania lub wygaśnięcia w inny
						sposób zawartej umowy z Administratorem.
					</td>
				</tr>
				<tr>
					<td>Marketing bezpośredni (np. w zakresie profilowania)</td>
					<td>
						Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) -
						przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów
						Administratora - polegających na dbaniu o interesy i dobry wizerunek Administratora, jego
						Serwisu Internetowego oraz dążeniu do świadczenia Usług Elektronicznych
					</td>
					<td>
						Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez
						Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w
						stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności
						gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności Kodeksu Cywilnego
						(podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności
						gospodarczej wynosi trzy lata). <br />
						Administrator nie może przetwarzać danych w celu marketingu bezpośredniego w przypadku wyrażenia
						skutecznego sprzeciwu w tym zakresie przez osobę, której dane dotyczą.
					</td>
				</tr>
				<tr>
					<td>Marketing (np. newsletter)</td>
					<td>
						Artykuł 6 ust. 1 lit. a) Rozporządzenia RODO (zgoda) - osoba, której dane dotyczą, wyraziła
						zgodę na przetwarzanie swoich danych osobowych w celu otrzymywania informacji marketingowych od
						Administratora
					</td>
					<td>
						Dane przechowywane są do momentu wycofania zgody przez osobę, której dane dotyczą na dalsze
						przetwarzanie jej danych w tym celu.
					</td>
				</tr>
				<tr>
					<td>Prowadzenie ksiąg podatkowych</td>
					<td>
						Artykuł 6 ust. 1 lit. c) Rozporządzenia RODO (obowiązek prawny) w zw. z art. 86 § 1 Ordynacji
						podatkowej tj. z dnia 17 stycznia 2017 r. (Dz.U. z 2017 r. poz. 201 ze zm.) - przetwarzanie jest
						niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze
					</td>
					<td>
						Dane są przechowywane przez okres wymagany przepisami prawa nakazującymi Administratorowi
						przechowywanie ksiąg podatkowych (do czasu upływu okresu przedawnienia zobowiązania podatkowego,
						chyba że ustawy podatkowe stanowią inaczej).
					</td>
				</tr>
				<tr>
					<td>
						Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić Administrator lub jakie mogą być
						podnoszone wobec Administratora
					</td>
					<td>
						Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) -
						przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów
						Administratora - polegających na ustaleniu, dochodzeniu lub obronie roszczeń, jakie może
						podnosić Administrator lub jakie mogą być podnoszone wobec Administratora
					</td>
					<td>
						Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez
						Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń jakie mogą być
						podnoszone wobec Administratora (podstawowy termin przedawnienia dla roszczeń wobec
						Administratora wynosi sześć lat).
					</td>
				</tr>
				<tr>
					<td>Korzystanie ze strony Serwisu Internetowego i zapewnienie jej prawidłowego działania</td>
					<td>
						Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) -
						przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów
						Administratora - polegających na prowadzeniu i utrzymaniu strony Serwisu Internetowego
					</td>
					<td>
						Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez
						Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w
						stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności
						gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności Kodeksu Cywilnego
						(podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności
						gospodarczej wynosi trzy lata).
					</td>
				</tr>
				<tr>
					<td>Prowadzenie statystyk i analiza ruchu w Serwisie Internetowym</td>
					<td>
						Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie uzasadniony interes administratora) -
						przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych interesów
						Administratora - polegających na prowadzeniu statystyk i analizie ruchu w Serwisie Internetowym
						celem poprawy funkcjonowania Serwisu Internetowego i zwiększenia zasięgu świadczonych Usług
						Elektronicznych
					</td>
					<td>
						Dane są przechowywane przez okres istnienia prawnie uzasadnionego interesu realizowanego przez
						Administratora, nie dłużej jednak niż przez okres przedawnienia roszczeń Administratora w
						stosunku do osoby, której dane dotyczą, z tytułu prowadzonej przez Administratora działalności
						gospodarczej. Okres przedawnienia określają przepisy prawa, w szczególności Kodeksu Cywilnego
						(podstawowy termin przedawnienia dla roszczeń związanych z prowadzeniem działalności
						gospodarczej wynosi trzy lata).
					</td>
				</tr>
			</table>
			<h2>4) ODBIORCY DANYCH W SERWISIE INTERNETOWYM</h2>
			<ol>
				<li>
					Dla prawidłowego funkcjonowania Serwisu Internetowego, w tym dla prawidłowego świadczenia przez
					Administratora usług Kalendarz-motoryzacyjny.pl, w tym Usług Elektronicznych, konieczne jest
					korzystanie przez Administratora z usług podmiotów zewnętrznych (takich jak np. dostawca
					oprogramowania, podmiot obsługujący płatności itp.). Administrator korzysta wyłącznie z usług takich
					podmiotów przetwarzających, którzy zapewniają wystarczające gwarancje wdrożenia odpowiednich środków
					technicznych i organizacyjnych, tak by przetwarzanie spełniało wymogi Rozporządzenia RODO i chroniło
					prawa osób, których dane dotyczą.
				</li>
				<li>
					Dane osobowe mogą być przekazywane przez Administratora do państwa trzeciego, przy czym
					Administrator zapewnia, że w takim przypadku odbywać się to będzie w stosunku do państwa
					zapewniającego odpowiedni stopień ochrony, a w razie braku właściwej decyzji potwierdzającej
					odpowiedni stopień ochrony tego państwa, przynajmniej na podstawie standardowych klauzul ochrony
					danych - zgodnie z Rozporządzeniem RODO, a osoba której dane dotyczą, ma możliwość uzyskania kopii
					swoich danych. Administrator przekazuje zebrane dane osobowe jedynie w przypadku oraz w zakresie
					niezbędnym do zrealizowania danego celu przetwarzania danych zgodnego z niniejszą polityką
					prywatności.
				</li>
				<li>
					Przekazanie danych przez Administratora nie następuje w każdym wypadku i nie do wszystkich
					wskazanych w polityce prywatności odbiorców lub kategorii odbiorców - Administrator przekazuje dane
					wyłącznie wtedy, gdy jest to niezbędne do realizacji danego celu przetwarzania danych osobowych i
					tylko w zakresie niezbędnym do jego zrealizowania.
				</li>
				<li>
					Dane osobowe Użytkowników Serwisu Internetowego mogą być przekazywane następującym odbiorcom lub
					kategoriom odbiorców:
					<ol type='a'>
						<li>
							<b>
								dostawcy usług zaopatrujący Administratora w rozwiązania techniczne, informatyczne oraz
								organizacyjne, umożliwiające Administratorowi prowadzenie działalności gospodarczej, w
								tym Serwisu Internetowego i świadczonych za jego pośrednictwem Usług Elektronicznych
							</b>
							<br />
							(w szczególności dostawcy oprogramowania komputerowego do prowadzenia Serwisu Internetowego,
							dostawcy poczty elektronicznej i hostingu oraz dostawcy oprogramowania do zarządzania firmą
							i udzielania pomocy technicznej Administratorowi) - Administrator udostępnia zebrane dane
							osobowe Użytkownika wybranemu dostawcy działającemu na jego zlecenie jedynie w przypadku
							oraz w zakresie niezbędnym do zrealizowania danego celu przetwarzania danych zgodnego z
							niniejszą polityką prywatności.
						</li>
						<li>
							<b>
								dostawcy usług księgowych, prawnych i doradczych zapewniający Administratorowi wsparcie
								księgowe, prawne lub doradcze
							</b>{' '}
							(w szczególności biuro księgowe, kancelaria prawna lub firma windykacyjna) - Administrator
							udostępnia zebrane dane osobowe Użytkownika wybranemu dostawcy działającemu na jego zlecenie
							jedynie w przypadku oraz w zakresie niezbędnym do zrealizowania danego celu przetwarzania
							danych zgodnego z niniejszą polityką prywatności.
						</li>
					</ol>
				</li>
			</ol>
			<h2>5) PROFILOWANIE W SERWISIE INTERNETOWYM</h2>
			<ol>
				<li>
					Rozporządzenie RODO nakłada na Administratora obowiązek informowania o zautomatyzowanym podejmowaniu
					decyzji, w tym o profilowaniu, o którym mowa w art. 22 ust. 1 i 4 Rozporządzenia RODO, oraz -
					przynajmniej w tych przypadkach - istotne informacje o zasadach ich podejmowania, a także o
					znaczeniu i przewidywanych konsekwencjach takiego przetwarzania dla osoby, której dane dotyczą.
					Mając to na uwadze, Administrator podaje w tym punkcie polityki prywatności informacje dotyczące
					możliwego profilowania.
				</li>
				<li>
					Administrator może korzystać w Serwisie Internetowym z profilowania do celów marketingu
					bezpośredniego, ale decyzje podejmowane na jego podstawie przez Administratora nie dotyczą zawarcia
					lub odmowy zawarcia umowy z Administratorem czy też możliwości korzystania z Usług Elektronicznych w
					Serwisie Internetowym. Efektem korzystania z profilowania w Serwisie Internetowym może być np.
					przypomnienie o niedokończonych działaniach w Serwisie, przesłanie propozycji Ogłoszenia lub usługi,
					która może odpowiadać zainteresowaniom lub preferencjom danej osoby lub też zaproponowanie lepszych
					warunków w porównaniu do standardowej oferty Serwisu Internetowego. Mimo profilowania to dana osoba
					podejmuje swobodnie decyzję, czy będzie chciała skorzystać np. z otrzymanej w ten sposób oferty lub
					rabatu Administratora.
				</li>
				<li>
					Profilowanie w Serwisie Internetowym polega na automatycznej analizie lub prognozie zachowania danej
					osoby na stronie Serwisu Internetowego, np. poprzez analizę dotychczasowej historii działań
					podejmowanych w Serwisie Internetowym. Warunkiem takiego profilowania jest posiadanie przez
					Administratora danych osobowych danej osoby, aby móc jej następnie przesłać np. rabat lub ofertę.
				</li>
				<li>
					Osoba, której dane dotyczą, ma prawo do tego, by nie podlegać decyzji, która opiera się wyłącznie na
					zautomatyzowanym przetwarzaniu, w tym profilowaniu, i wywołuje wobec tej osoby skutki prawne lub w
					podobny sposób istotnie na nią wpływa.
				</li>
			</ol>
			<h2>6) PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ</h2>
			<ol>
				<li>
					<b>Prawo dostępu, sprostowania, ograniczenia, usunięcia lub przenoszenia</b> - osoba, której dane
					dotyczą, ma prawo żądania od Administratora dostępu do swoich danych osobowych, ich sprostowania,
					usunięcia („prawo do bycia zapomnianym”) lub ograniczenia przetwarzania oraz ma prawo do wniesienia
					sprzeciwu wobec przetwarzania, a także ma prawo do przenoszenia swoich danych. Szczegółowe warunki
					wykonywania wskazanych wyżej praw wskazane są w art. 15-21 Rozporządzenia RODO.
				</li>
				<li>
					<b>Prawo do cofnięcia zgody w dowolnym momencie</b> - osoba, której dane przetwarzane są przez
					Administratora na podstawie wyrażonej zgody (na podstawie art. 6 ust. 1 lit. a) lub art. 9 ust. 2
					lit. a) Rozporządzenia RODO), ma prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność
					z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
				</li>
				<li>
					<b>Prawo wniesienia skargi do organu nadzorczego</b> - osoba, której dane przetwarzane są przez
					Administratora, ma prawo wniesienia skargi do organu nadzorczego w sposób i trybie określonym w
					przepisach Rozporządzenia RODO oraz prawa polskiego, w szczególności ustawy o ochronie danych
					osobowych. Organem nadzorczym w Polsce jest Prezes Urzędu Ochrony Danych Osobowych.
				</li>
				<li>
					<b>Prawo do sprzeciwu</b> - osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść sprzeciw
					- z przyczyn związanych z jej szczególną sytuacją - wobec przetwarzania dotyczących jej danych
					osobowych opartego na art. 6 ust. 1 lit. e) (interes lub zadania publiczne) lub f) (prawnie
					uzasadniony interes administratora), w tym profilowania na podstawie tych przepisów.
					Administratorowi w takim przypadku nie wolno już przetwarzać tych danych osobowych, chyba że wykaże
					on istnienie ważnych prawnie uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów,
					praw i wolności osoby, której dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony
					roszczeń.
				</li>
				<li>
					<b>Prawo do sprzeciwu dot. marketingu bezpośredniego</b> - jeżeli dane osobowe są przetwarzane na
					potrzeby marketingu bezpośredniego, osoba, której dane dotyczą, ma prawo w dowolnym momencie wnieść
					sprzeciw wobec przetwarzania dotyczących jej danych osobowych na potrzeby takiego marketingu, w tym
					profilowania, w zakresie, w jakim przetwarzanie jest związane z takim marketingiem bezpośrednim.
				</li>
				<li>
					W celu realizacji uprawnień, o których mowa w niniejszym punkcie polityki prywatności można
					kontaktować się z Administratorem poprzez przesłanie stosownej wiadomości pisemnie lub pocztą
					elektroniczną na adres Administratora wskazany na wstępie polityki prywatności.
				</li>
			</ol>
			<h2>7) COOKIES W SERWISIE INTERNETOWYM I ANALITYKA</h2>
			<ol>
				<li>
					Pliki Cookies (ciasteczka) są to niewielkie informacje tekstowe w postaci plików tekstowych,
					wysyłane przez serwer i zapisywane po stronie osoby odwiedzającej stronę Serwisu Internetowego (np.
					na dysku twardym komputera, laptopa, czy też na karcie pamięci smartfonu - w zależności z jakiego
					urządzenia korzysta odwiedzający Serwis Internetowy). Szczegółowe informacje dot. plików Cookies, a
					także historię ich powstania można znaleźć m. in. tutaj:{' '}
					<a href='https://pl.wikipedia.org/wiki/HTTP_cookie'>https://pl.wikipedia.org/wiki/HTTP_cookie</a>.
				</li>
				<li>
					Pliki Cookies, które mogą być wysyłane przez stronę Serwisu Internetowego, można podzielić na różne
					rodzaje, według następujących kryteriów:
					<table>
						<tr>
							<td>
								<b>Ze względu na ich dostawcę:</b>
								<ol>
									<li>własne (tworzone przez stronę Serwisu Internetowego Administratora) oraz</li>
									<li>należące do osób/podmiotów trzecich (innych niż Administrator)</li>
								</ol>
							</td>
							<td>
								<b>
									Ze względu na ich okres przechowywania na urządzeniu osoby odwiedzającej stronę
									Serwisu Internetowego:
								</b>
								<ol>
									<li>
										sesyjne (przechowywane do czasu opuszczenia Serwisu Internetowego lub wyłączenia
										przeglądarki internetowej) oraz
									</li>
									<li>
										stałe (przechowywane przez określony czas, zdefiniowany przez parametry każdego
										pliku lub do czasu ręcznego usunięcia)
									</li>
								</ol>
							</td>
							<td>
								<b>Ze względu na cel ich stosowania:</b>
								<ol>
									<li>
										niezbędne (umożliwiające prawidłowe funkcjonowanie strony Serwisu
										Internetowego),
									</li>
									<li>
										funkcjonalne/preferencyjne (umożliwiające dostosowanie strony Serwisu
										Internetowego do preferencji osoby odwiedzającej stronę),
									</li>
									<li>
										analityczne i wydajnościowe (gromadzące informacje o sposobie korzystania ze
										strony Serwisu Internetowego),
									</li>
									<li>
										marketingowe, reklamowe i społecznościowe (zbierające informacje o osobie
										odwiedzającej stronę Serwisu Internetowego w celu wyświetlania tej osobie
										reklam, ich personalizacji i prowadzenia innych działań marketingowych w tym
										również na stronach internetowych odrębnych od strony Serwisu Internetowego,
										takich jak portale społecznościowe lub inne strony należące do tej samej sieci
										reklamowej co Serwis Internetowy)
									</li>
								</ol>
							</td>
						</tr>
					</table>
				</li>
				<li>
					Administrator może przetwarzać dane zawarte w plikach Cookies podczas korzystania przez
					odwiedzających ze strony Serwisu Internetowego w następujących konkretnych celach:
					<table>
						<tr>
							<th rowSpan={4} style={{ width: '20%' }}>
								Cele stosowanie plików Cookies w Serwisie Internetowym Administratora
							</th>
							<td>
								zapamiętywania danych z wypełnianych formularzy i ankiet (pliki Cookies niezbędne lub/i
								funkcjonalne/preferencyjne)
							</td>
						</tr>
						<tr>
							<td>
								dostosowywania zawartości strony Serwisu Internetowego do indywidualnych preferencji
								Użytkownika (np. dotyczących kolorów, rozmiaru czcionki, układu strony) oraz
								optymalizacji korzystania ze stron Serwisu Internetowego (pliki Cookies
								funkcjonalne/preferencyjne)
							</td>
						</tr>
						<tr>
							<td>
								prowadzenia anonimowych statystyk przedstawiających sposób korzystania ze strony Serwisu
								Internetowego (pliki Cookies analityczne i wydajnościowe)
							</td>
						</tr>
						<tr>
							<td>
								wyświetlania i renderowania reklam, ograniczania liczby wyświetleń reklam oraz
								ignorowania reklam, których dana osoba nie chce oglądać, mierzenia skuteczności reklam,
								a także personalizacji reklam, to jest badania cech zachowania osób odwiedzających
								Serwis Internetowy poprzez anonimową analizę ich działań (np. powtarzające się wizyty na
								określonych stronach, słowa kluczowe itp.) w celu stworzenia ich profilu i dostarczenia
								im reklam dopasowanych do ich przewidywanych zainteresowań, także wtedy kiedy odwiedzają
								oni inne strony internetowe w sieci reklamowej firmy Google Ireland Ltd. oraz Facebooka,
								tj. Meta Platforms Ireland Ltd. (pliki Cookies marketingowe, reklamowe i
								społecznościowe)
							</td>
						</tr>
					</table>
				</li>
				<li>
					Sprawdzenie w najpopularniejszych przeglądarkach internetowych, jakie pliki Cookies (w tym okres
					funkcjonowania plików Cookies oraz ich dostawca) są wysyłane w danej chwili przez stronę Serwisu
					Internetowego jest możliwe w następujący sposób:
					<table>
						<tr>
							<td>
								<b>W przeglądarce Chrome:</b>
								<ol>
									<li>w pasku adresu kliknij w ikonkę kłódki po lewej stronie,</li>
									<li>przejdź do zakładki "Pliki cookie".</li>
								</ol>
							</td>
							<td>
								<b>W przeglądarce Firefox::</b>
								<ol>
									<li>w pasku adresu kliknij w ikonkę tarczy po lewej stronie,</li>
									<li>przejdź do zakładki „Dopuszczone” lub „Zablokowane”,</li>
									<li>
										kliknij pole „Ciasteczka śledzące między witrynami”, „Elementy śledzące serwisów
										społecznościowych” lub „Treści z elementami śledzącymi”
									</li>
								</ol>
							</td>
							<td>
								<b>W przeglądarce Internet Explorer:</b>
								<ol>
									<li>kliknij menu „Narzędzia”,</li>
									<li>przejdź do zakładki „Opcje internetowe”,</li>
									<li>przejdź do zakładki „Ogólne”,</li>
									<li>przejdź do zakładki „Ustawienia”,</li>
									<li>kliknij pole „Wyświetl pliki”</li>
								</ol>
							</td>
						</tr>
						<tr>
							<td>
								<b>W przeglądarce Opera:</b>
								<ol>
									<li>w pasku adresu kliknij w ikonkę kłódki po lewej stronie,</li>
									<li>przejdź do zakładki "Pliki cookie".</li>
								</ol>
							</td>
							<td>
								<b>W przeglądarce Safari:</b>
								<ol>
									<li>kliknij menu „Preferencje”,</li>
									<li>przejdź do zakładki „Prywatność”,</li>
									<li>kliknij w pole „Zarządzaj danymi witryn”.</li>
								</ol>
							</td>
							<td>
								<b>Niezależnie od przeglądarki, za pomocą narzędzi dostępnych np. na stronach:</b>
								<ol>
									<li>
										<a href='https://www.cookiemetrix.com/'>https://www.cookiemetrix.com/</a>
									</li>
									<li>
										<a href='https://www.cookie-checker.com/'>https://www.cookie-checker.com/</a>
									</li>
								</ol>
							</td>
						</tr>
					</table>
				</li>
				<li>
					Standardowo większość przeglądarek internetowych dostępnych na rynku domyślnie akceptuje zapisywanie
					plików Cookies. Każdy ma możliwość określenia warunków korzystania z plików Cookies za pomocą
					ustawień własnej przeglądarki internetowej. Oznacza to, że można np. częściowo ograniczyć (np.
					czasowo) lub całkowicie wyłączyć możliwość zapisywania plików Cookies - w tym ostatnim wypadku
					jednak może to mieć wpływ na niektóre funkcjonalności Serwisu Internetowego.
				</li>
				<li>
					Ustawienia przeglądarki internetowej w zakresie plików Cookies są istotne z punktu widzenia zgody na
					korzystanie z plików Cookies przez nasz Serwis Internetowy - zgodnie z przepisami taka zgoda może
					być również wyrażona poprzez ustawienia przeglądarki internetowej. Szczegółowe informacje na temat
					zmiany ustawień dotyczących plików Cookies oraz ich samodzielnego usuwania w najpopularniejszych
					przeglądarkach internetowych dostępne są w dziale pomocy przeglądarki internetowej oraz na
					poniższych stronach (wystarczy kliknąć w dany link):
					<ul>
						<li>
							<a href='https://support.google.com/chrome/answer/95647?hl=pl'>w przeglądarce Chrome</a>
						</li>
						<li>
							<a href='https://support.mozilla.org/pl/kb/wzmocniona-ochrona-przed-sledzeniem-firefox-desktop?redirectlocale=pl&amp%3Bredirectslug=W%C5%82%C4%85czanie+i+wy%C5%82%C4%85czanie+obs%C5%82ugi+ciasteczek&redirectslug=wlaczanie-i-wylaczanie-ciasteczek-sledzacych'>
								w przeglądarce Firefox
							</a>
						</li>
						<li>
							<a href='https://support.microsoft.com/pl-pl/windows/usuwanie-plik%C3%B3w-cookie-i-zarz%C4%85dzanie-nimi-168dab11-0753-043d-7c16-ede5947fc64d'>
								w przeglądarce Internet Explorer
							</a>
						</li>
						<li>
							<a href='https://help.opera.com/pl/latest/web-preferences/'>w przeglądarce Opera</a>
						</li>
						<li>
							<a href='https://support.apple.com/pl-pl/guide/safari/sfri11471/11.0/mac/10.13'>
								w przeglądarce Safari
							</a>
						</li>
						<li>
							<a href='https://support.microsoft.com/pl-pl/windows/program-microsoft-edge-i-przegl%C4%85danie-danych-a-prywatno%C5%9B%C4%87-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd'>
								w przeglądarce Microsoft Edge
							</a>
						</li>
					</ul>
				</li>
				<li>
					Administrator może korzystać w Serwisie Internetowym z usług Google Analytics, Universal Analytics
					dostarczanych przez firmę Google Ireland Limited (Gordon House, Barrow Street, Dublin 4, Irlandia).
					Usługi te pomagają Administratorowi prowadzić statystyki i analizować ruch w Serwisie Internetowym.
					Gromadzone dane przetwarzane są w ramach powyższych usług do generowania statystyk pomocnych w
					administrowaniu Serwisem i analizie ruchu w Serwisie Internetowym. Dane te mają charakter zbiorczy.
					Administrator korzystając z powyższych usług w Serwisie Internetowym gromadzi takie dane jak źródła
					i medium pozyskania odwiedzających Serwis Internetowy oraz sposób ich zachowania na stronie Serwisu
					Internetowego, informacje na temat urządzeń i przeglądarek z których odwiedzają stronę, IP oraz
					domenę, dane geograficzne oraz dane demograficzne (wiek, płeć) i zainteresowania.
				</li>
				<li>
					Możliwe jest zablokowanie w łatwy sposób przez daną osobę udostępniania do Google Analytics
					informacji o jej aktywności na stronie Serwisu Internetowego - w tym celu można na przykład
					zainstalować dodatek do przeglądarki udostępniany przez firmę Google Ireland Ltd. dostępny tutaj:{' '}
					<a href='https://tools.google.com/dlpage/gaoptout?hl=pl'>
						https://tools.google.com/dlpage/gaoptout?hl=pl
					</a>
					.
				</li>
				<li>
					W związku z możliwością korzystania przez Administratora w Serwisie Internetowym z usług reklamowych
					i analitycznych dostarczanych przez Google Ireland Ltd., Administrator wskazuje, że pełna informacja
					o zasadach przetwarzania danych osób odwiedzających Serwis Internetowy (w tym informacji zapisanych
					w plikach Cookies) przez Google Ireland Ltd. znajduje się w polityce prywatności usług Google
					dostępnej pod adresem:{' '}
					<a href='https://policies.google.com/technologies/partner-sites'>
						https://policies.google.com/technologies/partner-sites
					</a>
					.
				</li>
			</ol>
			<h2>8) POSTANOWIENIA KOŃCOWE</h2>
			<p>
				Serwis Internetowy może zawierać odnośniki do innych stron internetowych. Administrator namawia, by po
				przejściu na inne strony, zapoznać się z polityką prywatności tam ustaloną. Niniejsza polityka
				prywatności dotyczy tylko Serwisu Internetowego Administratora.
			</p>
		</div>
	);
};
