import { FacebookOutlined, GlobalOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Avatar, Space } from 'antd';
import React from 'react'

import './MemberCard.less';

interface MemberCardProps {
    name: string;
    image: string;
    description: string;

    facebook?: string;
    instagram?: string;
    youtube?: string;
    website?: string;
}

export const MemberCard = ({ name, image, description, facebook, instagram, youtube, website }: MemberCardProps) => {
    return (
        <div className='member-card'>
            <div className='member-card__ppf'>
                <Avatar src={image} size={250} />
            </div>
            <div className='member-card__name'>
                <b>{name}</b>
            </div>
            <div className='member-card__description'>
                {description}
            </div>
            <Space className='member-card__social-media'>
                {facebook &&
                    <a href={facebook} target='blank'>
                        <FacebookOutlined className='member-card__social-media__icon' />
                    </a>
                }
                {instagram &&
                    <a href={instagram} target='blank'>
                        <InstagramOutlined className='member-card__social-media__icon' />
                    </a>
                }
                {youtube &&
                    <a href={youtube} target='blank'>
                        <YoutubeOutlined className='member-card__social-media__icon' />
                    </a>
                }
                {website &&
                    <a href={website} target='blank'>
                        <GlobalOutlined className='member-card__social-media__icon' />
                    </a>
                }
            </Space>
        </div>
    )
}
