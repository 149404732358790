import appConfig from "app.config";
import { requests } from "App/api/agent/agent";
import { HttpStatusCodeResponse } from "App/types/httpResponse";
import i18n from "i18n";
import { AdminCreateEventRequest, ConfirmEventRequest, CreateEventRequest, GetAdminEventsRequest, GetEventsRequest, GetPromotedEventsRequest, UpdateEventRequest, UpdateEventStatusRequest } from "./requests";
import { GetAdminEventResponse, GetAdminEventsResponse, GetEventResponse, GetEventsCountriesResponse, GetEventsResponse, GetPromotedEventsResponse, UpdateEventResponse } from "./responses";

const { baseUrlToIncludeInEmail, confirmEvent, event } = appConfig.urls.frontend;

export const EventsApi = {
    getAdminEvents: (body: GetAdminEventsRequest): Promise<GetAdminEventsResponse> => requests.post(`/events/admin/search`, body),

    getAdminEvent: (eventId: string): Promise<GetAdminEventResponse> => requests.get(`/events/admin/${eventId}`),

    adminCreateEvent: (body: AdminCreateEventRequest): Promise<HttpStatusCodeResponse> => {
        const formData = new FormData();
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

        for (const [key, value] of Object.entries(body)) {
            if(!value) continue;
            let val = value;

            if(key === 'priceEntry' || key === 'priceTicket' || key === 'latitude' || key === 'longitude') {
                if(!value) continue;
                val = value.toString().replace('.', ',');
            }

            if(key === 'dateStart' || key === 'dateEnd' || key === 'datePromotionStart')
                val = value.format("DD-MM-YYYY HH:mm:ss");

            if(key === 'categories' || key === 'surfaces' || key === 'tags') {
                value.forEach(v => {
                    formData.append(`${key}[]`, v);
                });
                continue;
            }

            formData.append(key, val);
        }

        return requests.post(`/events/admin`, formData, config);
    },

    getEventsCountries: (): Promise<GetEventsCountriesResponse> => requests.get(`/events/countries`),

    getEvents: (body: GetEventsRequest): Promise<GetEventsResponse> => requests.post(`/events/search`, body),

    getPromotedEvents: (body: GetPromotedEventsRequest): Promise<GetPromotedEventsResponse> => requests.post(`/events/promoted`, body),

    getEvent: (eventId: string): Promise<GetEventResponse> => requests.get(`/events/${eventId}`),

    createEvent: (body: CreateEventRequest): Promise<HttpStatusCodeResponse> => {
        const formData = new FormData();
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

        for (const [key, value] of Object.entries(body)) {
            if(!value) continue;
            let val = value;

            if(key === 'priceEntry' || key === 'priceTicket' || key === 'latitude' || key === 'longitude') {
                if(!value) continue;
                val = value.toString().replace('.', ',');
            }

            if(key === 'dateStart' || key === 'dateEnd' || key === 'datePromotionStart')
                val = value.format("DD-MM-YYYY HH:mm:ss");

            if(key === 'categories' || key === 'surfaces' || key === 'tags') {
                value.forEach(v => {
                    formData.append(`${key}[]`, v);
                });
                continue;
            }

            formData.append(key, val);
        }

        formData.append('urlToIncludeInEmail', `${baseUrlToIncludeInEmail}${confirmEvent}`);
        formData.append('emailNotificationLanguage', i18n.language);
        return requests.post(`/events`, formData, config);
    },

    confirmEvent: (params: ConfirmEventRequest): Promise<HttpStatusCodeResponse> => requests.get(`/events/confirm`, params),

    updateEvent: (eventId: string, body: UpdateEventRequest): Promise<UpdateEventResponse> => {
        const formData = new FormData();
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

        for (const [key, value] of Object.entries(body)) {
            if(!value) continue;
            let val = value;

            if(key === 'priceEntry' || key === 'priceTicket' || key === 'latitude' || key === 'longitude') {
                if(!value) continue;
                val = value.toString().replace('.', ',');
            }

            if(key === 'dateStart' || key === 'dateEnd' || key === 'datePromotionStart')
                val = value.format("DD-MM-YYYY HH:mm:ss");

            if(key === 'categories' || key === 'surfaces' || key === 'tags') {
                value.forEach(v => {
                    formData.append(`${key}[]`, v);
                });
                continue;
            }

            formData.append(key, val);
        }

        formData.append('urlToIncludeInEmail', `${baseUrlToIncludeInEmail}${event}`);

        return requests.put(`/events/${eventId}`, formData, config);
    }, 

    updateEventStatus: (eventId: string, body: UpdateEventStatusRequest): Promise<HttpStatusCodeResponse> => {
        const params = {
            ...body,
            urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${event}`
        }

        return requests.put(`/events/${eventId}/status`, params);
    },
}