import { createSlice } from "@reduxjs/toolkit";
import StatusType from "App/types/requestStatus";
import { contactInitialState, ContactState } from "./contact.global.state";

const { LOADING, SUCCESS, FAILED } = StatusType;

export const globalContactSlice = createSlice({
    name: 'global-contact',
    initialState: contactInitialState,
    reducers: {
        submitContactFormStart: (state: ContactState) => {
           state.status.submitContactForm = LOADING;
        },
        submitContactFormSuccess: (state: ContactState) => {
           state.status.submitContactForm = SUCCESS;
        },
        submitContactFormFailure: (state: ContactState) => {
           state.status.submitContactForm = FAILED;
        },
    }
});

export default globalContactSlice;

export const {
    submitContactFormStart,
    submitContactFormSuccess,
    submitContactFormFailure
} = globalContactSlice.actions;