import { Col, notification, PageHeader, Row } from 'antd';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { ForgotPasswordRequest } from 'App/api/endpoints/account/requests';
import ForgotPasswordForm from './forms/ForgotPasswordForm';
import { RootState } from 'App/globalState/root.reducer';
import { cleanUpAccountStatusStart } from '../../state/account.slice';
import { forgotPassword } from '../../state/account.thunk';

interface ForgotPasswordContainerProps extends RouteComponentProps {}

const ForgotPasswordContainer = ({ history }: ForgotPasswordContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const dispatch = useDispatch();
	const forgotPasswordStatus = useSelector((state: RootState) => state.pages.account.status.forgotPassword);
	const { t } = useTranslation('page');

	useEffect(() => {
		return () => {
			dispatch(cleanUpAccountStatusStart());
		};
	}, [dispatch]);

	const forgotPasswordHandler: FinishFormType = (values: ForgotPasswordRequest) => {
		let handleSuccess: () => void = () => {
			history.push('/');
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.ForgotPasswordEmailSuccess'),
			});
		};

		dispatch(forgotPassword(values, handleSuccess));
	};

	return (
		<div className='forgotPasswordContainer'>
			<Row align='middle' justify='center'>
				<Col xs={22} md={14} xl={10} xxl={8}>
					<br />
					<PageHeader title={t('AccountPages.ResetPasswordPageHeaderTitle')} />
					<ForgotPasswordForm
						className='forgotPassword-form'
						name='forgotPasswordForm'
						size='large'
						onFinish={forgotPasswordHandler}
						autoComplete='off'
						loading={forgotPasswordStatus === StatusType.LOADING}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default ForgotPasswordContainer;
