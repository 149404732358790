import { Col, Row, Typography } from 'antd';
import { GetAdminEventResponse } from 'App/api/endpoints/events/responses';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EventGeneralTabProps {
	event: GetAdminEventResponse;
}

const EventGeneralTab = ({ event }: EventGeneralTabProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);

	if (event) {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Title')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.title}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Organizer')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.organizer}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.OrganizerEmail')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.organizerEmail}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.DateStart')}:{' '}
							</Typography.Text>
							<Typography.Text>{dateTimeUtils.formatWholeDayDate(event.dateStart, event.isWholeDay)}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.DateEnd')}:{' '}
							</Typography.Text>
							<Typography.Text>{dateTimeUtils.formatWholeDayDate(event.dateEnd, event.isWholeDay)}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Timezone')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.timezone}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.PriceEntry')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.priceEntry ?? '-'} {event.priceCurrency}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.PriceTicket')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.priceTicket ?? '-'} {event.priceCurrency}</Typography.Text>
						</Typography>
					</Col>
				</Row>
			</>
		);
	} else {
		return <></>;
	}
};

export default EventGeneralTab;
