import { EventForGetEventsResponse } from 'App/api/endpoints/events/responses';
import React, { useEffect, useRef, useState } from 'react';
import moment, { Moment } from 'moment';
import i18n from 'i18n';
import { EventUtils } from '../../utils/EventUtils';
import { Spin } from 'antd';
import { EventPreview } from '../eventPreview/EventPreview';

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import './EventCalendar.less';

interface EventCalendarProps {
    events: EventForGetEventsResponse[];
    startDate?: Moment;
    endDate?: Moment;
    loading?: boolean;
    onChange?: (start: Moment, end: Moment) => void;
}

export const EventCalendar = ({ events, startDate, endDate, loading, onChange }: EventCalendarProps) => {
    const calendarRef = useRef(null);
    
    const [fcEvents, setFcEvents] = useState([]);
    const [previewEvent, setPreviewEvent] = useState<EventForGetEventsResponse>(null);

    useEffect(() => {
        const mappedEvents = events.map(event => {
            const color = EventUtils.categoryColor(event.categories[0]);
            return {
                id: event.id,
                title: event.title,
                start: moment(event.dateStart).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(event.dateEnd).format("YYYY-MM-DDTHH:mm:ss"),
                backgroundColor: color
            }
        });

        setFcEvents(mappedEvents);
    }, [events]);

    useEffect(() => {
        const dateBetween = getMidTimeBetweenTwoDate(startDate, endDate);
        const api = calendarRef.current.getApi();
        api.gotoDate(dateBetween.format("YYYY-MM-DD"));
    }, [startDate, endDate]);

    const getMidTimeBetweenTwoDate = (date1, date2): Moment => {
        let date1Moment = moment(date1);
        let date2Moment = moment(date2);
        let diff = date2Moment.diff(date1Moment, 'minutes');
        let midTime = date1Moment.add(diff/2, 'minutes');
        return moment(midTime.format("YYYY-MM-DD"));
    }

    const renderEventContent = (eventInfo) => {
        return (
            <div
                className='event-tile'
                style={{ 
                    backgroundColor: eventInfo.event.backgroundColor, 
                    padding: '2px 0px'
                }}
            >
                <i>{eventInfo.event.title}</i>
            </div>
        )
    }

    const onDatesSet = (params) => {
        if(!onChange)
            return;

        onChange(
            moment(params.startStr, "YYYY-MM-DD:HH:mm"), 
            moment(params.endStr, "YYYY-MM-DD:HH:mm")
        );
    }

    const onEventClick = (tile) => {
        setPreviewEvent(events.find(e => e.id === tile.event.id));
    }

    const onModalCancel = () => {
        setPreviewEvent(null);
    }

    return (
        <div className='event-calendar'>
            <Spin spinning={loading}>
                <FullCalendar
                    initialView="dayGridMonth"
                    headerToolbar={{
                        left: "prev",
                        center: "title",
                        right: "next"
                    }}
                    themeSystem="Simplex"
                    plugins={[dayGridPlugin]}
                    contentHeight="auto"
                    locale={i18n.language}
                    ref={calendarRef}
                    firstDay={1}
                    events={fcEvents}
                    eventContent={renderEventContent}
                    datesSet={onDatesSet}
                    eventClick={onEventClick}
                />
            </Spin>
			<EventPreview 
                event={previewEvent} 
                onCancel={onModalCancel} 
            />
        </div>
    )
}
