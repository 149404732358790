import agent from 'App/api/agent/agent';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import { AppThunk } from '../store';
import {
	clearAccountStart,
	getAccountDetailsFailure,
	getAccountDetailsStart,
	getAccountDetailsSuccess,
} from './account.global.slice';

export const getAccountDetails = (onSuccess?: () => void, onFailure?: () => void): AppThunk => async (dispatch) => {
	dispatch(getAccountDetailsStart());
	agent.Account.getAccountDetails()
		.then((accountDetailsResponse: GetAccountDetailsResponse) => {
			dispatch(getAccountDetailsSuccess(accountDetailsResponse));
			if (onSuccess) {
				onSuccess();
			}
		})
		.catch(() => {
			dispatch(getAccountDetailsFailure());
			if (onFailure) onFailure();
		});
};

export const clearAccountDetails = (): AppThunk => async (dispatch) => {
	dispatch(clearAccountStart());
};
