import { Row, Col, Pagination, Spin } from 'antd'
import { EventForGetEventsResponse } from 'App/api/endpoints/events/responses'
import { Default } from 'App/common/components/Responsive/Default'
import { Mobile } from 'App/common/components/Responsive/Mobile'
import { breakpoints } from 'App/common/components/Responsive/utils/breakpoints'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import EventCard from './eventCard/EventCard'
import EventCardMobile from './eventCardMobile/EventCardMobile'

import './EventList.less'

interface EventListProps {
    events: EventForGetEventsResponse[];
    pageNumber: number;
    pageSize: number;
    totalItems: number;
    loading?: boolean;
    onChange: (pageNumber, pageSize) => void;
}

export const EventList = ({ events, pageNumber, pageSize, totalItems, loading, onChange }: EventListProps) => {
	const { t } = useTranslation('page');

    const isMobile = useMediaQuery({ maxWidth: breakpoints.sm.max });
    
    const totalItemsFormat = () => {
        if(isMobile)
            return '';
            
        const firstItem = (pageNumber - 1) * pageSize + 1;
        const lastItem = firstItem + events.length - 1;
        const itemsString = firstItem === lastItem ? `${firstItem}` : `${firstItem}-${lastItem}`

        return `${itemsString} / ${totalItems}`;
    }

    return (
        <div className='event-list'>
            {events.length || loading ? (
                <Spin size='large' spinning={loading}>
                    <>
                        <Row>
                            <Col span={24}>
                                {events && events.map(event => (
                                    <>
                                        <Default>
                                            <EventCard event={event} />
                                        </Default>
                                        <Mobile>
                                            <EventCardMobile event={event} />
                                        </Mobile>
                                    </>
                                ))}
                            </Col>
                        </Row>
                        <Row className='event-list__pagination-row'>
                            <Pagination 
                                className='event-list__pagination-row__pagination'
                                showSizeChanger
                                showTotal={totalItemsFormat}
                                current={pageNumber} 
                                pageSize={pageSize}
                                total={totalItems} 
                                onChange={onChange}
                            />
                        </Row>
                    </>
                </Spin>
            ) : (
                <div>{t('EventPages.NoEventsFound')}</div>
            )}
        </div>
    )
}
