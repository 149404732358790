import { IFilterItem, IOrderByItem, IPagination } from 'App/types/pagination/pagination';

interface IPaginationUtils {
	defaultPaginationValues: IPagination;
	handleFiltersChange: (filters: any) => IFilterItem[];
	handleOrderByChange: (filters: any) => IOrderByItem[];
	insertSingleOrderBy: (el: any, newOrderBy: IOrderByItem[]) => void;
	calculatePageNumber: (pagination: any, extra: any) => number;
}

export const paginationUtils: IPaginationUtils = {
	defaultPaginationValues: {
		orderBy: [],
		filters: [],
		pageNumber: 1,
		pageSize: 10,
		query: '',
		totalItems: 0,
	},

	// funkcja generująca zestaw filtrów do requesta na podstawie tabeli Antd
	handleFiltersChange(filters: any): IFilterItem[] {
		let filterList: IFilterItem[] = [];
		const filtrationKeys = Object.keys(filters);

		filtrationKeys.forEach((filtrationKey) => {
			const filterValues = filters[filtrationKey]?.map((filter) => filter.toString());
			if (filterValues) {
				filterList.push({
					field: filtrationKey,
					values: filters[filtrationKey].map((filter) => filter.toString()),
				});
			}
		});

		return filterList;
	},

	// funkcja pomocnicza do ustawiania pojedynczego elementu sortowania
	insertSingleOrderBy(el: any, newOrderBy: IOrderByItem[]) {
		if (el.order && el.column && el.field) {
			const index = el.column.sorter.multiple || 1;
			const field = el.field;
			const order = el.order;

			if (order === 'ascend') {
				newOrderBy[index] = { field: field, direction: 'Ascending' };
			} else {
				newOrderBy[index] = { field: field, direction: 'Descending' };
			}
		}
	},

	// funkcja generująca zestaw orderBy do requesta na podstawie tabeli Antd
	handleOrderByChange(sorter: any) {
		let orderByList: IOrderByItem[] = [];
		if (Array.isArray(sorter)) {
			sorter.forEach((el) => {
				paginationUtils.insertSingleOrderBy(el, orderByList);
			});
		} else {
			paginationUtils.insertSingleOrderBy(sorter, orderByList);
		}
		return orderByList;
	},

	// funkcja wyliczająca pageNumber jaki ma być ustawiony w requeście
	// domyślnym zachowaniem jest resetowanie pageNumber w przypadku
	// wykrycia zmiany filtracji lub sortowania na podstawie tabeli Antd
	calculatePageNumber(pagination: any, extra: any) {
		let pageNumberToSet = pagination.current || paginationUtils.defaultPaginationValues.pageNumber;
		// jeśli zmieniamy sortowanie lub filtrację to paginujemy od strony 1
		if (extra.action === 'sort' || extra.action === 'filter') {
			pageNumberToSet = paginationUtils.defaultPaginationValues.pageNumber;
		}
		return pageNumberToSet;
	},
};
