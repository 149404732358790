import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, notification, PageHeader, Result, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import LoadingScreen from 'App/common/components/LoadingScreen';

import UpdateUserForm from './forms/updateUserForm/UpdateUserForm';
import { StatusType } from 'App/types/requestStatus';
import { cleanUpSelectedUser, cleanUpUserStatus } from 'App/pages/adminPages/usersPages/state/users.slice';
import { UpdateUserRequest } from 'App/api/endpoints/users/requests';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { getUser, updateUser } from '../../state/users.thunk';

interface RouteParams {
	userId: string;
}

interface UpdateUserContainerProps extends RouteComponentProps<RouteParams> {}

const { LOADING } = StatusType;

const UpdateUserContainer = ({ match }: UpdateUserContainerProps) => {
	const userId = match.params.userId;
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector((state: RootState) => state.pages.admin.users.selectedUser);
	const { t } = useTranslation(['page', 'common']);

	const usersStatus = useSelector((state: RootState) => state.pages.admin.users.status);
	useEffect(() => {
		if (!user) {
			dispatch(getUser(userId));
		}
	}, [dispatch, user, userId]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpUserStatus());
			dispatch(cleanUpSelectedUser());
		};
	}, [dispatch]);

	const handleFormSubmit = (values: UpdateUserRequest) => {
		if (user) {
			const onSuccess = () => {
				notification.success({
					message: t('common:Success.Success'),
					description: t('AdminPages.UsersPages.SuccessUpdateDescription'),
				});
			};
			dispatch(updateUser(user.id, values, onSuccess));
		}
	};

	return usersStatus.getUser === LOADING ? (
		<LoadingScreen container='screen' />
	) : user ? (
		<React.Fragment>
			<Button
				style={{ marginLeft: 16 }}
				onClick={() => history.push('/admin/users')}
				icon={<ArrowLeftOutlined />}
			>
				{t('common:Actions.GoBack')}
			</Button>
			<Row align='middle' justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.UsersPages.UpdatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<UpdateUserForm
								initialValues={{
									email: user.email,
									firstName: user.firstName,
									lastName: user.lastName,
									roles: user.roles,
									isDeleted: user.isDeleted,
								}}
								onFinish={handleFormSubmit}
								loading={usersStatus.updateUser === LOADING}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('page:AdminPages.UsersPages.UserNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default UpdateUserContainer;
