import { Button, Col, Result, Row, Image, Tag } from 'antd';
import { serverURL } from 'App/api/agent/axios/configuration';
import LoadingScreen from 'App/common/components/LoadingScreen';
import PageLabel from 'App/common/components/PageLabel/PageLabel';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import { cleanUpEvent } from 'App/globalState/events/events.global.slice';
import { getEvent } from 'App/globalState/events/events.global.thunk';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import { Icon } from 'leaflet';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import { atcb_action } from 'add-to-calendar-button';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import moment from 'moment';
import 'add-to-calendar-button/assets/css/atcb.css';
import { getAdverts } from 'App/globalState/adverts/adverts.global.thunk';
import AdvertsColumn from '../../common/advertsColumn/AdvertsColumn';
import countries from 'i18n-iso-countries';
import i18n from 'i18n';
import { Default } from 'App/common/components/Responsive/Default';
import { Mobile } from 'App/common/components/Responsive/Mobile';
import { EventUtils } from '../home/utils/EventUtils';
import { Helmet } from "react-helmet-async";

import Blank from 'App/common/assets/blank.webp';

import './EventContainer.less';

interface RouteParams {
	eventId: string;
}

interface EventContainerProps extends RouteComponentProps<RouteParams> {}

const EventContainer = ({ match }: EventContainerProps) => {
	const { t } = useTranslation(['page', 'models']);
    const { eventId } = match.params;

    const dispatch = useDispatch();
	const history = useHistory();

    const event = useSelector((state: RootState) => state.global.events.event);
	const status = useSelector((state: RootState) => state.global.events.status.getEvent);

    const adverts = useSelector((state: RootState) => state.global.adverts.adverts);
    const advertsParams = useSelector((state: RootState) => state.global.adverts.getAdvertsParams);

    useEffect(() => {
        dispatch(getEvent(eventId));
		dispatch(getAdverts({ ...advertsParams }));
    
        return () => {
            dispatch(cleanUpEvent());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId, dispatch]);

    const bannerUrl = (): string => event?.urlBanner ? (serverURL + event.urlBanner).replace('\\', '/') : Blank;
    const posterUrl = (): string => event?.urlPoster ? (serverURL + event.urlPoster).replace('\\', '/') : Blank;
    
    const tableRow = (label: string, value: any, link?: string) => (
        <tr>
            <td className='event-container__label'>
                {label}
            </td>
            <td className='event-container__value'>
                {link 
                ? 
                <a href={link} target='blank'>
                    {value}
                </a> 
                : 
                value}
            </td>
        </tr>
    );

    const locationString = (): string => {
        let location = "";

        if(event.locationName) location += `${event.locationName}, `;
        if(event.street) location += `${event.street}, `;
        if(event.city) location += `${event.city}, `;
        if(event.state) location += `${event.state}, `;
        if(event.country) location += `${event.country}, `;

        if(event.latitude && event.longitude)
            location += `${event.latitude}, ${event.longitude}`;

        return location;
    }

    const showRoute = () => {
        //const query = `${event.locationName} ${event.street} ${event.city} ${event.state} ${event.country}`
        //window.open(`http://maps.google.com/dir/?q=${query},${event.latitude},${event.longitude}`)
        const query = `${event.latitude},${event.longitude}`;
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${query}`);
    }

    const addToCalendar = () => {
        atcb_action({
            name: event.title,
            description: event.organizer,
            startDate: moment(event.dateStart).format('YYYY-MM-DD'),
            startTime: moment(event.dateStart).format("HH:mm"),
            endDate: moment(event.dateEnd).format('YYYY-MM-DD'),
            endTime: moment(event.dateEnd).format("HH:mm"),
            timeZone: event.timezone,
            options: [ 'Google', 'Apple', 'Yahoo', 'iCal' ],
            location: locationString()
        })
    }
    
    return <>
    {status === StatusType.LOADING ? (
        <LoadingScreen container='fill' />
    ) : event ? (
        <div className='event-container'>
            <Helmet>
                <title id={`event-${event?.id}`}>
                    {`${event?.title} - Wydarzenia Motoryzacyjne`}
                </title>
                
                <meta id={`event-${event?.id}`} property="og:title" content={`${event?.title} - Wydarzenia Motoryzacyjne`} />
                <meta id={`event-${event?.id}`} property="og:image" content={bannerUrl()} />
            </Helmet>
            <Row>
                <Col>
                    <div 
                        className='event-container__banner' 
                        style={{ 'backgroundImage': `url(${bannerUrl()})` }}
                    >
                    </div>
                </Col>
            </Row>

            <Default>
                <PageLabel message={event.title} messageRight={t('HomePages.SupportedBy')} />
            </Default>
            <Mobile>
                <PageLabel message={event.title} />
            </Mobile>

            <div className='event-container__content'>
                <Row>
                    <Col xs={24} md={18}>
                        <table>
                            <tbody>
                                {tableRow(t('models:Event.Labels.Organizer'), event.organizer)}
                                {tableRow(t('models:Event.Labels.DateStart'), dateTimeUtils.formatWholeDayDate(event.dateStart, event.isWholeDay))}
                                {tableRow(t('models:Event.Labels.DateEnd'), dateTimeUtils.formatWholeDayDate(event.dateEnd, event.isWholeDay))}

                                {event.priceTicket != null &&
                                    tableRow(t('models:Event.Labels.PriceTicket'), `${event.priceTicket.toFixed(2)} ${event.priceCurrency}`)
                                }
                                {event.priceEntry != null &&
                                    tableRow(t('models:Event.Labels.PriceEntry'), `${event.priceEntry.toFixed(2)} ${event.priceCurrency}`)
                                }

                                {event.externalLink?.length > 0 &&
                                    tableRow(t('models:Event.Labels.ExternalLink'), event.externalLink, event.externalLink)
                                }
                                {event.safetyCageRequired != null &&
                                    tableRow(t('models:Event.Labels.SafetyCageRequired'), event.safetyCageRequired ? t('common:Actions.Yes') : t('common:Actions.No'))
                                }
                                {tableRow(t('models:Event.Labels.Category'), 
                                    event.categories.map(category => 
                                        <Tag color={EventUtils.categoryColor(category)}>
                                            {t(`models:Event.Category.${category}`)}
                                        </Tag>
                                    ))
                                }

                                {event.surfaces?.length > 0 && 
                                    tableRow(t('models:Event.Labels.Surface'), 
                                        event.surfaces.map(surface => t(`models:Event.Surface.${surface}`)).join(', '))
                                }
                                    
                                {event.locationName?.length > 0 &&
                                    tableRow(t('models:Event.Labels.LocationName'), event.locationName)
                                }
                                {event.street?.length > 0 &&
                                    tableRow(t('models:Event.Labels.Street'), event.street)
                                }
                                {tableRow(t('models:Event.Labels.City'), event.city)}
                                {tableRow(t('models:Event.Labels.State'), event.state)}
                                {tableRow(t('models:Event.Labels.Country'),
                                    countries.getName(event.country, i18n.language))}
                                {tableRow(t('models:Event.Labels.Location'), `${event.latitude}, ${event.longitude}`)}

                                {event.tags?.length > 0 &&
                                    tableRow(t('models:Event.Labels.Tags'), 
                                        event.tags.map(tag => 
                                            <Tag>
                                                {tag}
                                            </Tag>
                                        ))
                                }
                            </tbody>
                        </table>

                        <div className='map-container'>
                            <MapContainer 
                                center={[event.latitude, event.longitude]} 
                                zoom={14}
                                scrollWheelZoom={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker
                                    position={[event.latitude, event.longitude]} 
                                    icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                                >
                                    <Popup>
                                        {event.locationName}
                                    </Popup>
                                </Marker>
                            </MapContainer>
                        </div>

                        <Row className='event-container__buttons'>
                            <Default>
                                <Col span={12}>
                                    <Button type='primary' size='large' onClick={showRoute}>
                                        {t('page:EventPages.ShowDirection')}
                                    </Button> <br/> <br/>
                                    <Button type='primary' size='large' onClick={addToCalendar}>
                                        {t('page:EventPages.AddToCalendar')}
                                    </Button>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Default>
                            <Mobile>
                                <Col span={24}>
                                    <Button type='primary' size='large' onClick={showRoute}>
                                        {t('page:EventPages.ShowDirection')}
                                    </Button> <br/> <br/>
                                    <Button type='primary' size='large' onClick={addToCalendar}>
                                        {t('page:EventPages.AddToCalendar')}
                                    </Button>
                                </Col>
                            </Mobile>
                        </Row>

                        {event.urlPoster &&
                            <Image src={posterUrl()} alt={event.title} />
                        }
                    </Col>
                    <Default>
                        <Col xs={24} md={6}>
                            <AdvertsColumn adverts={adverts} />
                        </Col>
                    </Default>
                </Row>
            </div>
            <Mobile>
                <PageLabel messageRight={t('HomePages.SupportedBy')} />
                <AdvertsColumn adverts={adverts} />
            </Mobile>
        </div>
    ) : (
        <Result
			status='warning'
			title={t('common:Errors.AnErrorOccured')}
			extra={
			    <Button type='primary' onClick={() => history.push('/')}>
			    	{t('common:Actions.BackToHome')}
		    	</Button>
		    }
		/>
    )}
    </>
}

export default EventContainer;