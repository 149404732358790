import i18n from 'i18n';
import moment from 'moment';

// Add languages
import 'moment/locale/pl';

export const i18nToMomentLocaleMapper = () => {
    moment.locale(i18n.language, {
        week: {
            dow: 1
        }
    })
};