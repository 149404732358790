import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Typography } from 'antd';

import './styles/ConfirmEmailContainer.less';
import { Link } from 'react-router-dom';
import { RootState } from 'App/globalState/root.reducer';
import { cleanUpAccountStatusStart } from '../../state/account.slice';
import { confirmEmail } from '../../state/account.thunk';

const { Title } = Typography;
interface RouteParams {
	userId: string;
	confirmationCode: string;
}

interface ConfirmEmailContainerProps extends RouteComponentProps<RouteParams> {}

const ConfirmEmailContainer = ({ match }: ConfirmEmailContainerProps) => {
	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.pages.account.status.confirmEmail);
	const { t } = useTranslation('page');

	useEffect(() => {
		if (status === StatusType.INITIAL) {
			let handleSuccess: () => void = () => {
				notification.success({
					message: t('common:Success.Success'),
					description: t('AccountPages.ConfirmSuccess'),
				});
			};

			const { userId, confirmationCode } = match.params;
			dispatch(confirmEmail({ userId, confirmationCode }, handleSuccess));
		}
		return () => {
			cleanUpAccountStatusStart();
		};
	});

	return status === StatusType.LOADING || status === StatusType.INITIAL ? (
		<LoadingScreen container='screen' />
	) : (
		<>
			{status === StatusType.SUCCESS && (
				<Col className='confirmEmail'>
					<Row justify='center' align='middle'>
						<CheckOutlined className='confirmIconSuccess' />
					</Row>
					<Row justify='center' align='middle'>
						<Title className='text-center' level={4}>
							{t('AccountPages.EmailConfirmed')}
						</Title>
					</Row>
					<Row justify='center' align='middle'>
						<Link to='/sign-in'>
							<Button size='large' block type='primary'>
								{t('AccountPages.Login')}
							</Button>
						</Link>
					</Row>
				</Col>
			)}
			{status === StatusType.FAILED && (
				<Col className='confirmEmail'>
					<Row justify='center' align='middle'>
						<ExclamationOutlined className='confirmIconError' />
					</Row>
					<Row justify='center' align='middle'>
						<Title className='text-center' level={4}>
							{t('AccountPage.ConfirmEmailContainer.ConfirmError')}
						</Title>
					</Row>
				</Col>
			)}
		</>
	);
};

export default ConfirmEmailContainer;
