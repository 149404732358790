import { requests } from "App/api/agent/agent";
import { HttpStatusCodeResponse } from "App/types/httpResponse";
import { AdminGetAdvertsRequest, CreateAdvertRequest, GetAdvertsRequest, UpdateAdvertRequest } from "./requests";
import { AdminGetAdvertResponse, AdminGetAdvertsResponse, GetAdvertsResponse } from "./responses";

export const AdvertsApi = {
    adminGetAdverts: (body: AdminGetAdvertsRequest): Promise<AdminGetAdvertsResponse> => requests.post(`/adverts/admin/search`, body),

    adminGetAdvert: (advertId: string): Promise<AdminGetAdvertResponse> => requests.get(`/adverts/admin/${advertId}`),

    createAdvert: (body: CreateAdvertRequest): Promise<HttpStatusCodeResponse> => {
        const formData = new FormData();
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

        for (const [key, value] of Object.entries(body)) {
            if(!value) 
                continue;
            formData.append(key, value);
        }

        return requests.post(`/adverts/admin`, formData, config);
    },

    updateAdvert: (advertId: string, body: UpdateAdvertRequest): Promise<HttpStatusCodeResponse> => {
        const formData = new FormData();
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

        for (const [key, value] of Object.entries(body)) {
            if(!value) 
                continue;
            formData.append(key, value);
        }

        return requests.put(`/adverts/admin/${advertId}`, formData, config);
    },

    deleteAdvert: (advertId: string): Promise<HttpStatusCodeResponse> => requests.delete(`/adverts/admin/${advertId}`),

    getAdverts: (body: GetAdvertsRequest): Promise<GetAdvertsResponse> => requests.post(`/adverts/search`, body)
}