import { CopyrightOutlined, FacebookFilled, InstagramFilled } from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Logo from '../assets/logo.webp';

import './FooterContainer.less';

const FooterContainer = () => {
	const { t } = useTranslation('page');
	
	return (
		<Row gutter={[40, 32]} justify='center' className='footer-main'>
			<Col xs={24} md={12} xl={8}>
				<img src={Logo} alt={'Logo'} style={{ maxWidth: 'min(250px, 100%)' }} /> <br/> <br/>
				KALENDARZ MOTORYZACYJNY <br/>
				"Agniess.pl" Agnieszka Kujawa <br/>
				kalendarzmotoryzacyjny@gmail.com
            </Col>
			<Col xs={24} md={12} xl={8} className='footer-main__left-divider'>
                <CopyrightOutlined /> {new Date().getFullYear()} - Kalendarz-Motoryzacyjny.pl<br/>
				<a href='/terms-of-service'>{t('Footer.TermsOfService').toUpperCase()}</a><br/>
				<a href='/privacy-policy'>{t('Footer.PrivacyPolicy').toUpperCase()}</a><br/>
				<a href='/contact'>{t('Navbar.Contact').toUpperCase()}</a>
			</Col>
			<Col xs={24} md={24} xl={8}>
				<Row>
					{t('Footer.FollowUs')}!
				</Row>
				<Space>
					<a href='https://www.facebook.com/kalendarzmotoryzacyjnypl' target='blank'>
						<FacebookFilled className='footer-main__sm-icon' />
					</a>
					<a href='https://www.instagram.com/kalendarz_motoryzacyjny.pl' target='blank'>
						<InstagramFilled className='footer-main__sm-icon' />
					</a>
				</Space>
			</Col>
		</Row>
	);
};

export default FooterContainer;
