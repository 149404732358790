import { GetMessagesRequest } from 'App/api/endpoints/maintenanceMessages/requests/getMessagesRequest';
import { GetMessageResponse } from 'App/api/endpoints/maintenanceMessages/responses';
import { MessageForGetMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses/getMessagesResponse';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { StatusType } from 'App/types/requestStatus';

const { INITIAL } = StatusType;
const { defaultPaginationValues } = paginationUtils;

export interface AdminMaintenanceMessagesState {
	status: {
		getMessages: StatusType;
		getMessage: StatusType;
		deleteMessage: StatusType;
		createMessage: StatusType;
		updateMessage: StatusType;
	};
	messages: MessageForGetMessagesResponse[];
	getMessagesParams: GetMessagesRequest;
	selectedMessage: GetMessageResponse | null;
}

export const adminMaintenanceMessagesInitialState: AdminMaintenanceMessagesState = {
	status: {
		getMessages: INITIAL,
		getMessage: INITIAL,
		deleteMessage: INITIAL,
		createMessage: INITIAL,
		updateMessage: INITIAL,
	},
	messages: [],
	getMessagesParams: { ...defaultPaginationValues, messagesType: 'All' },
	selectedMessage: null,
};
