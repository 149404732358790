import React, { useEffect, useMemo, useState } from 'react';
import { Form, Input, Select, Button, DatePicker, InputNumber, Upload, Row, Switch, Col, Cascader } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { useTranslation } from 'react-i18next';
import { formRules } from 'App/common/formRules/formRules';
import { AdminCreateEventRequest } from 'App/api/endpoints/events/requests/adminCreateEventRequest';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { DraggerProps } from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { EventCurrency, EventStatus, EventSurface } from 'App/common/enums';
import { allCountries } from 'country-region-data';
import { Icon } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import TimezoneSelect, { allTimezones } from 'react-timezone-select/dist/esm/index';
import type { ITimezone } from 'react-timezone-select/dist/esm/dist/index';
import moment from 'moment-timezone';
import { RootState } from 'App/globalState/root.reducer';
import { useSelector } from 'react-redux';
import countries from 'i18n-iso-countries';
import i18n from 'i18n';
import { EventCategoryGrouping } from 'App/common/utils/eventCategoryGrouping.utils';

import './CreateEventForm.less';
import 'leaflet/dist/leaflet.css';

export interface MapLocation {
    address: string;
    lat: number;
    lng: number;
}

interface CreateEventFormProps {
	onFinish: (values: AdminCreateEventRequest) => void;
	initialValues?: AdminCreateEventRequest;
	loading: boolean;

    bannerUploadProps: DraggerProps;
    posterUploadProps: DraggerProps;
}

const RecenterAutomatically = ({ lat, lng }) => {
	const map = useMap();
	
	useEffect(() => {
		map.setView([lat, lng]);
	}, [lat, lng, map]);
	
	return null;
}

const CreateEventForm = ({ initialValues, loading, onFinish, bannerUploadProps, posterUploadProps }: CreateEventFormProps) => {
	const { t } = useTranslation(['form', 'common', 'page']);
	const { title, organizer, organizerEmail, dates, category, tags, city, state, country } = formRules.event;
    
	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);

    const [form] = Form.useForm();
    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(moment.tz.guess());
    const [isWholeDay, setIsWholeDay] = useState<boolean>(false);

    const [street, setStreet] = useState<string>("");
    const [eCity, setECity] = useState<string>("");
    const [selectedState, setSelectedState] = useState(null);
    const [eCountry, setECountry] = useState<string>("PL");

    const [availableStates, setAvailableStates] = useState([]);
	const [position, setPosition] = useState<MapLocation>({ address: "", lat: 0, lng: 0 });

    const [provider] = useState(new OpenStreetMapProvider());

    const onDateSet = (value: RangePickerProps['value']) => {
        form.setFieldsValue({
            'dateStart': value[0].startOf('minute'),
            'dateEnd': value[1].startOf('minute')
        });
    }

    useEffect(() => {
        const regions = (allCountries.find(c => c[1] === eCountry)[2]).map(region => region[0]);
        setAvailableStates(regions);
        setSelectedState("");
        setECity("");
        setStreet("");

        form.setFieldsValue({
            'country': eCountry,
            'state': null,
            'city': "",
            'street': "",
        });
    }, [eCountry, form]);

    useEffect(() => {
        const query = `${street} ${eCity} ${selectedState} ${eCountry}`;
        
        provider.search({ query }).then((results) => {
            if(!results.length) return;

            const location = results[0];
			setPosition({ lat: location.y, lng: location.x, address: location.label });

            form.setFieldsValue({
                'latitude': location.y,
                'longitude': location.x
            });
		});
    }, [street, eCity, selectedState, eCountry, provider, form]);

    useEffect(() => {
        form.setFieldsValue({
            'latitude': position.lat,
            'longitude': position.lng
        });
    }, [position, form]);

    useEffect(() => {
        form.setFieldsValue({
            'organizerEmail': accountDetails.email
        });
    }, [accountDetails, form]);

    useEffect(() => {
        if(initialValues) {
            form.setFieldsValue({
                ...initialValues,
                dateStart: moment(initialValues.dateStart),
                dateEnd: moment(initialValues.dateEnd),
                datePromotionStart: initialValues.datePromotionStart ? moment(initialValues.datePromotionStart) : null,
                dates: [moment(initialValues.dateStart), moment(initialValues.dateEnd)],
                categories: initialValues.categories.map(cat => EventCategoryGrouping.categoryToPath(t, cat)),
                safetyCageRequired: initialValues.safetyCageRequired ? true : (initialValues.safetyCageRequired === false ? false : null),
            });
            setStreet(initialValues.street);
            setECity(initialValues.city);
            setSelectedState(initialValues.state);
            setECountry(initialValues.country);
            setPosition({
                address: "",
                lat: initialValues.latitude,
                lng: initialValues.longitude
            })
        }
    }, [initialValues, form, t]);

    const markerEvents = useMemo(() => ({
        dragend(e) {
            const { lat, lng } = e.target.getLatLng();
            setPosition({ 
                ...position,
                lat,
                lng
            });
        },
    }), [position]);

    const parseForm = (params: AdminCreateEventRequest) => {
        params.timezone = selectedTimezone.toString();
        params.isWholeDay = isWholeDay;
		params.categories = params.categories.map((c: any) => c[c.length - 1]);

        onFinish(params);
    }

    const parseTimezone = (tz) => {
        setSelectedTimezone(tz.value);
    }

    const countryList = countries.getNames(i18n.language, { select: 'official' });
    const countryOptions = allCountries.map(c => (
        {
            value: c[1],
            label: countryList[c[1]]
        }
    ));

	return (
		<Form 
            layout='vertical' 
            labelAlign='right' 
            className='px-3 create-event-form' 
            initialValues={initialValues} 
            onFinish={parseForm} 
            form={form}
        >

            <h3><b>{`${t('page:EventPages.SubmitEventForm.General')}:`}</b></h3>
            <hr/>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Title') }}
				label={t('models:Event.Labels.Title')}
				name='title'
				rules={title()}
			>
				<Input placeholder={t('models:Event.Placeholders.Title')} />
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Organizer') }}
				label={t('models:Event.Labels.Organizer')}
				name='organizer'
				rules={organizer()}
			>
				<Input placeholder={t('models:Event.Placeholders.Organizer')} />
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.OrganizerEmail') }}
				label={t('models:Event.Labels.OrganizerEmail')}
				name='organizerEmail'
				rules={organizerEmail()}
			>
				<Input placeholder={t('models:Event.Placeholders.OrganizerEmail')} />
			</Form.Item>

            <Row>
                <Col xs={24} md={17}>
                    <Form.Item
                        messageVariables={{ arg: `${t('models:Event.Labels.DateStart')} & ${t('models:Event.Labels.DateEnd')}` }}
                        label={`${t('models:Event.Labels.DateStart')} & ${t('models:Event.Labels.DateEnd').toLowerCase()}`}
                        name='dates'
                        rules={dates()}
                    >
                        <DatePicker.RangePicker
                            showTime={isWholeDay ? false : { 
                                format: 'HH:mm', 
                                //defaultValue: [moment().startOf('day'), moment().startOf('day')] 
                            }}
                            format={isWholeDay ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"}
                            onChange={onDateSet}
                        />
                    </Form.Item>
                </Col>
                <Col md={1}></Col>
                <Col xs={24} md={6}>
                    <Form.Item
                        messageVariables={{ arg: `${t('models:Event.Labels.IsWholeDay')}` }}
                        label={t('models:Event.Labels.IsWholeDay')}
                        name='isWholeDay'
                        valuePropName='checked'
                    >
                        <Switch checked={isWholeDay} onChange={setIsWholeDay}/>
                    </Form.Item>
                </Col>
            </Row>
        
            <Form.Item
				messageVariables={{ arg: `${t('models:Event.Labels.DatePromotion')}` }}
				label={`${t('models:Event.Labels.DatePromotion')}`}
				name='datePromotionStart'
			>
                <DatePicker
                    showTime={{ 
                        format: 'HH:mm', 
                        //defaultValue: moment().startOf('day') 
                    }}
                    format="YYYY-MM-DD HH:mm"
                    allowClear
                />
			</Form.Item>

            <Row className='ant-form-item'>
                <Col span={24}>
                    <label>{`${t('models:Event.Labels.Timezone')}:`}</label>
                </Col>
                <Col span={24}>
                    <TimezoneSelect
                        value={selectedTimezone}
                        onChange={parseTimezone}
                        labelStyle='original'
                        timezones={allTimezones}
                    />
                </Col>
            </Row>

            <Row>
                <Col span={17}>
                    <Form.Item
                        messageVariables={{ arg: t('models:Event.Labels.PriceEntry') }}
                        label={`${t('models:Event.Labels.PriceEntry')}:`}
                        name='priceEntry'
                        tooltip={t('models:Event.Tooltips.PriceEntry')}
                        //rules={organizer()}
                    >
                        <InputNumber placeholder={t('models:Event.Placeholders.PriceEntry')} />
                    </Form.Item>

                    <Form.Item
                        messageVariables={{ arg: t('models:Event.Labels.PriceTicket') }}
                        label={`${t('models:Event.Labels.PriceTicket')}:`}
                        name='priceTicket'
                        tooltip={t('models:Event.Tooltips.PriceTicket')}
                        //rules={organizer()}
                    >
                        <InputNumber placeholder={t('models:Event.Placeholders.PriceTicket')} />
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={6}>
                    <Form.Item
                        messageVariables={{ arg: t('models:Event.Labels.PriceCurrency') }}
                        label={`${t('models:Event.Labels.PriceCurrency')}:`}
                        name='priceCurrency'
                        //rules={surface()}
                    >
                        <Select defaultValue={EventCurrency[EventCurrency.PLN]}>
                            {(Object.keys(EventCurrency) as Array<keyof typeof EventCurrency>).map((key) => {
                                if(isNaN(Number(key)))
                                    return <Select.Option key={key} value={key}>{key}</Select.Option>
                                else
                                    return null;
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <h3><b>{`${t('page:EventPages.SubmitEventForm.Graphics')}:`}</b></h3>
            <hr/>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Banner') }}
				label={t('models:Event.Labels.Banner')}
				name='banner'
				//rules={organizer()}
			>
				<Upload.Dragger {...bannerUploadProps} multiple={false}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('page:AdminPages.EventsPages.FileSubmitText')}</p>
                    <p className="ant-upload-hint">{t('page:AdminPages.EventsPages.FileSubmitHint')}</p>
                </Upload.Dragger>
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Poster') }}
				label={t('models:Event.Labels.Poster')}
				name='poster'
				//rules={organizer()}
			>
				<Upload.Dragger {...posterUploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('page:AdminPages.EventsPages.FileSubmitText')}</p>
                    <p className="ant-upload-hint">{t('page:AdminPages.EventsPages.FileSubmitHint')}</p>
                </Upload.Dragger>
			</Form.Item>

            <h3><b>{`${t('page:EventPages.SubmitEventForm.Details')}:`}</b></h3>
            <hr/>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.ExternalLink') }}
				label={t('models:Event.Labels.ExternalLink')}
				name='externalLink'
				//rules={organizer()}
			>
				<Input placeholder={t('models:Event.Placeholders.ExternalLink')} />
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.SafetyCageRequired') }}
				label={t('models:Event.Labels.SafetyCageRequired')}
				name='safetyCageRequired'
			>
				<Select placeholder={t('models:Event.Placeholders.SafetyCageRequired')} allowClear>
					<Select.Option value={null}>{t('common:Actions.NotApply')}</Select.Option>
					<Select.Option value={'true'}>{t('common:Actions.Yes')}</Select.Option>
					<Select.Option value={'false'}>{t('common:Actions.No')}</Select.Option>
				</Select>
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Category') }}
				label={t('models:Event.Labels.Category')}
				name='categories'
				rules={category()}
			>
                <Cascader
                    placeholder={t('models:Event.Placeholders.Category')}
                    options={EventCategoryGrouping.toOptions(t)}
                    multiple
                    maxTagCount={'responsive'}
                    showCheckedStrategy={'SHOW_CHILD'}
                    expandTrigger={'hover'}
                />
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Status') }}
				label={t('models:Event.Labels.Status')}
				name='status'
				//rules={category()}
			>
				<Select placeholder={t('models:Event.Placeholders.Status')}>
                    {(Object.keys(EventStatus) as Array<keyof typeof EventStatus>).map((key) => {
                        if(isNaN(Number(key)))
                            return <Select.Option key={key} value={key}>{t(`models:Event.Status.${key}`)}</Select.Option>
                        else
                            return null;
                    })}
				</Select>
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Surface') }}
				label={t('models:Event.Labels.Surface')}
				name='surfaces'
				//rules={surface()}
			>
				<Select placeholder={t('models:Event.Placeholders.Surface')} mode='multiple' allowClear>
                    {(Object.keys(EventSurface) as Array<keyof typeof EventSurface>).map((key) => {
                        if(isNaN(Number(key)))
                            return <Select.Option key={key} value={key}>{t(`models:Event.Surface.${key}`)}</Select.Option>
                        else
                            return null;
                    })}
				</Select>
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.Tags') }}
				label={t('models:Event.Labels.Tags')}
				name='tags'
				rules={tags()}
			>
                <Select
                    mode="tags"
                    placeholder={t('models:Event.Placeholders.Tags')}
                    allowClear
                    dropdownStyle={{ 'display': 'none' }}
                />
			</Form.Item>

            <h3><b>{`${t('page:EventPages.SubmitEventForm.Location')}:`}</b></h3>
            <hr/>

            <Form.Item
                messageVariables={{ arg: t('models:Event.Labels.Country') }}
                label={t('models:Event.Labels.Country')}
                name='country'
                rules={country()}
            >
                <Select 
                    placeholder={t('models:Event.Placeholders.Country')}
                    value={eCountry}
                    onChange={(val) => setECountry(val)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => 
                        (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    options={countryOptions.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))}
                />
            </Form.Item>

            <Form.Item
                messageVariables={{ arg: t('models:Event.Labels.State') }}
                label={t('models:Event.Labels.State')}
                name='state'
                rules={state()}
            >
                <Select 
                    placeholder={t('models:Event.Placeholders.State')}
                    onChange={(val) => setSelectedState(val)}
                    value={selectedState}
                >
                    {availableStates.map(s => {
                        return <Select.Option key={s} value={s}>{s}</Select.Option>
                    })}
				</Select>
            </Form.Item>

            <Form.Item
                messageVariables={{ arg: t('models:Event.Labels.City') }}
                label={t('models:Event.Labels.City')}
                name='city'
                rules={city()}
            >
                <Input placeholder={t('models:Event.Placeholders.City')} onBlur={(val) => setECity(val.target.value)} value={eCity} />
            </Form.Item>

            <Form.Item
                messageVariables={{ arg: t('models:Event.Labels.Street') }}
                label={t('models:Event.Labels.Street')}
                name='street'
                //rules={street()}
            >
                <Input placeholder={t('models:Event.Placeholders.Street')} onBlur={(val) => setStreet(val.target.value)} />
            </Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Event.Labels.LocationName') }}
				label={t('models:Event.Labels.LocationName')}
				name='locationName'
				//rules={organizer()}
			>
				<Input placeholder={t('models:Event.Placeholders.LocationName')} />
			</Form.Item>
            
            <p style={{ 'textAlign': 'justify' }}>
                {t('page:AdminPages.EventsPages.PleaseVerify')}
            </p>

            <div className='map-container'>
                <MapContainer 
                    center={[position.lat, position.lng]} 
                    zoom={14}
                    scrollWheelZoom={false}
                    style={{ 'height': '300px' }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker
                        draggable
                        eventHandlers={markerEvents}
                        position={[position.lat, position.lng]} 
                        icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
                    >
                        <Popup>
                            {position.address}
                        </Popup>
                    </Marker>
                    <RecenterAutomatically lat={position.lat} lng={position.lng} />
                </MapContainer>
            </div>

            <Form.Item className='hidden' name='dateStart'>
                <DatePicker />
            </Form.Item>

            <Form.Item className='hidden' name='dateEnd'>
                <DatePicker />
            </Form.Item>

            <Form.Item name='latitude'>
				<InputNumber disabled />
			</Form.Item>

            <Form.Item name='longitude'>
				<InputNumber disabled />
			</Form.Item>

			<Form.Item>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Create')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default CreateEventForm;
