import { requests } from '../../agent/agent';
import {
	UpdateAccountDetailsRequest,
	ChangePasswordRequest,
	ForgotPasswordRequest,
	ResetPasswordRequest,
	ConfirmEmailRequest,
	ResendConfirmationEmailRequest,
} from './requests';
import { GetAccountDetailsResponse, UpdateAccountDetailsResponse } from './responses';

import appConfig from 'app.config';
import { HttpStatusCodeResponse } from 'App/types/httpResponse.d';

const { baseUrlToIncludeInEmail, resetPassword, confirmEmail } = appConfig.urls.frontend;

export const AccountApi = {
	getAccountDetails: (): Promise<GetAccountDetailsResponse> => requests.get(`/account/details`),

	updateAccountDetails: (body: UpdateAccountDetailsRequest): Promise<UpdateAccountDetailsResponse> =>
		requests.put(`/account/details`, body),

	changePassword: (body: ChangePasswordRequest): Promise<HttpStatusCodeResponse> =>
		requests.post(`/account/change-password`, body),

	forgotPassword: (body: ForgotPasswordRequest): Promise<HttpStatusCodeResponse> =>
		requests.post(`/account/forgot-password`, {
			...body,
			urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${resetPassword}`,
		}),

	resetPassword: (body: ResetPasswordRequest): Promise<HttpStatusCodeResponse> =>
		requests.post(`/account/reset-password`, body),

	confirmEmail: (params: ConfirmEmailRequest): Promise<HttpStatusCodeResponse> =>
		requests.get('/account/confirm-email', params),

	resendConfirmationEmail: (body: ResendConfirmationEmailRequest): Promise<HttpStatusCodeResponse> =>
		requests.post('/account/resend-confirmation-email', {
			...body,
			urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${confirmEmail}`,
		}),
};
