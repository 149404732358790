import agent from "App/api/agent/agent";
import { AppThunk } from "../store";
import {
    AdminCreateEventRequest,
    ConfirmEventRequest,
    CreateEventRequest, 
    GetAdminEventsRequest, 
    GetEventsRequest, 
    GetPromotedEventsRequest, 
    UpdateEventRequest,
    UpdateEventStatusRequest
} from "App/api/endpoints/events/requests";
import {
    adminCreateEventFailure,
    adminCreateEventStart,
    adminCreateEventSuccess,
    confirmEventFailure,
    confirmEventStart,
    confirmEventSuccess,
    createEventFailure,
    createEventStart,
    createEventSuccess,
    getAdminEventFailure,
    getAdminEventsFailure,
    getAdminEventsStart,
    getAdminEventsSuccess,
    getAdminEventStart,
    getAdminEventSuccess,
    getEventFailure,
    getEventsCountriesFailure,
    getEventsCountriesStart,
    getEventsCountriesSuccess,
    getEventsFailure, 
    getEventsStart, 
    getEventsSuccess,
    getEventStart,
    getEventSuccess,
    getPromotedEventsFailure,
    getPromotedEventsStart,
    getPromotedEventsSuccess,
    getRecentlyAddedEventsFailure,
    getRecentlyAddedEventsStart,
    getRecentlyAddedEventsSuccess,
    updateEventFailure,
    updateEventStart,
    updateEventStatusFailure,
    updateEventStatusStart,
    updateEventStatusSuccess,
    updateEventSuccess
} from "./events.global.slice";

export const getAdminEvents = (params: GetAdminEventsRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getAdminEventsStart());
    agent.Events.getAdminEvents(params)
        .then((res) => {
            dispatch(getAdminEventsSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getAdminEventsFailure(err)));
};

export const getAdminEvent = (eventId: string, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getAdminEventStart());
    agent.Events.getAdminEvent(eventId)
        .then((res) => {
            dispatch(getAdminEventSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getAdminEventFailure(err)));
};

export const adminCreateEvent = (request: AdminCreateEventRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(adminCreateEventStart());
    agent.Events.adminCreateEvent(request)
        .then((res) => {
            dispatch(adminCreateEventSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(adminCreateEventFailure(err)));
};

export const getEvents = (params: GetEventsRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getEventsStart());
    agent.Events.getEvents(params)
        .then((res) => {
            dispatch(getEventsSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getEventsFailure(err)));
};

export const getRecentlyAddedEvents = (params: GetEventsRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getRecentlyAddedEventsStart());
    agent.Events.getEvents(params)
        .then((res) => {
            dispatch(getRecentlyAddedEventsSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getRecentlyAddedEventsFailure(err)));
};

export const getPromotedEvents = (params: GetPromotedEventsRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getPromotedEventsStart());
    agent.Events.getPromotedEvents(params)
        .then((res) => {
            dispatch(getPromotedEventsSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getPromotedEventsFailure(err)));
};

export const getEventsCountries = (onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getEventsCountriesStart());
    agent.Events.getEventsCountries()
        .then((res) => {
            dispatch(getEventsCountriesSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getEventsCountriesFailure(err)));
};

export const getEvent = (eventId: string, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getEventStart());
    agent.Events.getEvent(eventId)
        .then((res) => {
            dispatch(getEventSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getEventFailure(err)));
};

export const createEvent = (event: CreateEventRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(createEventStart());
    agent.Events.createEvent(event)
        .then((res) => {
            dispatch(createEventSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(createEventFailure(err)));
};

export const confirmEvent = (params: ConfirmEventRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(confirmEventStart());
    agent.Events.confirmEvent(params)
        .then(() => {
            dispatch(confirmEventSuccess());
                if(onSuccess)
                    onSuccess();
        })
       .catch(() => dispatch(confirmEventFailure()));
};

export const updateEvent = (eventId: string, body: UpdateEventRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(updateEventStart());
    agent.Events.updateEvent(eventId, body)
        .then((res) => {
            dispatch(updateEventSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(updateEventFailure(err)));
};

export const updateEventStatus = (eventId: string, body: UpdateEventStatusRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(updateEventStatusStart());
    agent.Events.updateEventStatus(eventId, body)
        .then((res) => {
            dispatch(updateEventStatusSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(updateEventStatusFailure(err)));
};