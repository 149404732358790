import { Menu } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../../assets/logo.webp';
const HomeMenuItem = (props) => {
	const { t } = useTranslation('page');

	// uwaga, powiększenie logo może spowodować wyświetlanie go jako collapsed (czyli ...)
	const logoHeight = 30;

	return (
		<Menu.Item key='/' {...props}>
			<Link to='/' onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>
				<img src={logo} height={logoHeight} alt={t('Navbar.Home')} />
			</Link>
		</Menu.Item>
	);
};

export default HomeMenuItem;
