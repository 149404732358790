import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Row, Col, notification, PageHeader } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import CreateUserForm from './forms/createUserForm/CreateUserForm';
import { StatusType } from 'App/types/requestStatus';
import { cleanUpUserStatus } from 'App/pages/adminPages/usersPages/state/users.slice';
import { CreateUserRequest } from 'App/api/endpoints/users/requests';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { createUser } from '../../state/users.thunk';

const { LOADING } = StatusType;

export const CreateUserContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation(['page', 'common']);

	let usersStatus = useSelector((state: RootState) => state.pages.admin.users.status);

	const handleFormSubmit = (values: CreateUserRequest) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.UsersPages.SuccessCreateDescription'),
			});
		};
		dispatch(createUser(values, onSuccess));
	};

	useEffect(() => {
		return () => {
			dispatch(cleanUpUserStatus());
		};
	}, [dispatch]);

	return (
		<React.Fragment>
			<Row className='mb-5'>
				<Col>
					<Button
						style={{ marginLeft: 16 }}
						block
						onClick={() => history.push('/admin/users')}
						icon={<ArrowLeftOutlined />}
					>
						{t('common:Actions.GoBack')}
					</Button>
				</Col>
			</Row>
			<Row justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.UsersPages.CreatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<CreateUserForm loading={usersStatus.createUser === LOADING} onFinish={handleFormSubmit} />
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default CreateUserContainer;
