import { Checkbox, Form } from 'antd'
import { formRules } from 'App/common/formRules/formRules'
import React from 'react'
import { useTranslation } from 'react-i18next';

import './FormChecks.less';

export const FormChecks = () => {
	const { t } = useTranslation('models');
    const { tos, privacy } = formRules.event;

    return (
        <div className='form-checks'>
            <Form.Item 
                name='tos-agree' 
                valuePropName='checked' 
                rules={tos()}
            >
                <Checkbox className='form-check'>
                    {t("models:FormCheck.TOS.Message")} <a href='/terms-of-service' target='_blank'>{t("models:FormCheck.TOS.TOS")}</a>.
                </Checkbox>
            </Form.Item>

            <Form.Item 
                name='privacy-agree' 
                valuePropName='checked' 
                rules={privacy()}
            >
                <Checkbox className='form-check'>
                    {t("models:FormCheck.PrivacyPolicy.Message")} <a href='/privacy-policy' target='_blank'>{t("models:FormCheck.PrivacyPolicy.PrivacyPolicy")}</a>.
                </Checkbox>
            </Form.Item>
        </div>
    )
}
