import { SettingFilled } from '@ant-design/icons';
import { Tooltip, Dropdown, Button, Menu } from 'antd';
import Column from 'antd/lib/table/Column';
import { GetMessagesRequest } from 'App/api/endpoints/maintenanceMessages/requests';
import { MessageForGetMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses';
import ExtendedTable from 'App/common/components/extendedTable/ExtendedTable';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import { tableUtils } from 'App/common/utils/table.utils';
import { AppThunk } from 'App/globalState/store';
import { IPagination } from 'App/types/pagination/pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface MessagesTableProps {
	dataSource: MessageForGetMessagesResponse[];
	loading: boolean;
	requestPagination: IPagination;
	onDeleteMessageClick: (messageToDelete: MessageForGetMessagesResponse) => void;
	getMessages: (params: GetMessagesRequest) => AppThunk;
	onUpdateMessageClick: (message: MessageForGetMessagesResponse) => void;
}

const { manageTableOrderBy, requestOrderByToAntdOrderBy } = tableUtils;
const { getLocalDateTimeString } = dateTimeUtils;

const MessagesTable = ({
	dataSource,
	loading,
	requestPagination,
	onDeleteMessageClick,
	getMessages,
	onUpdateMessageClick,
}: MessagesTableProps) => {
	const translatedOrderBy = requestOrderByToAntdOrderBy(requestPagination.orderBy);
	const { t } = useTranslation();

	const menuForActionDropdown = (record: MessageForGetMessagesResponse) => (
		<Menu>
			<Menu.Item key='edit-message'>
				<Button type='link' onClick={() => onUpdateMessageClick(record)}>
					{t('common:Actions.Edit')}
				</Button>
			</Menu.Item>
			<Menu.Item key='delete-message'>
				<Button type='link' onClick={() => onDeleteMessageClick(record)}>
					{t('common:Actions.Remove')}
				</Button>
			</Menu.Item>
		</Menu>
	);

	return (
		<ExtendedTable
			searchable
			requestPagination={requestPagination}
			fetchDataAction={getMessages}
			loading={loading}
			showHeader={!loading}
			dataSource={dataSource}
		>
			<Column
				key='startDateInUtc'
				dataIndex='startDateInUtc'
				title={t('models:MaintenanceMessage.Labels.StartDate')}
				sorter={{
					multiple: 3,
				}}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'startDateInUtc')}
				render={(startDate: string) => <>{getLocalDateTimeString(startDate)}</>}
			/>
			<Column
				key='endDateInUtc'
				dataIndex='endDateInUtc'
				title={t('models:MaintenanceMessage.Labels.EndDate')}
				sorter={{
					multiple: 2,
				}}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'endDateInUtc')}
				render={(endDate: string) => <>{getLocalDateTimeString(endDate)}</>}
			/>
			<Column
				key='description'
				dataIndex='description'
				title={t('models:MaintenanceMessage.Labels.Description')}
				sorter={{
					multiple: 1,
				}}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'description')}
				width='30%'
				onCell={() => {
					return {
						style: {
							whiteSpace: 'nowrap',
							maxWidth: 150,
						},
					};
				}}
				render={(description) => (
					<Tooltip title={description}>
						<div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{description}</div>
					</Tooltip>
				)}
			/>
			<Column
				title={t('common:Actions.Actions')}
				render={(record: MessageForGetMessagesResponse) => (
					<Dropdown overlay={menuForActionDropdown(record)} trigger={['click']} placement='bottomCenter'>
						<Button type='link'>
							<SettingFilled />
						</Button>
					</Dropdown>
				)}
			/>
		</ExtendedTable>
	);
};

export default MessagesTable;
