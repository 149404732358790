import React from 'react';

import './PageLabel.less';

interface PageLabelProps {
    message?: string;
    messageRight?: string;
}

const PageLabel = ({ message, messageRight }: PageLabelProps) => {

    return (
        <div className='page-label'>
            {message &&
                <div className='page-label__content'>
                    <div className='page-label__message'>
                        {message.toUpperCase()}
                    </div>
                </div>
            }
            <div className='page-label__clear'></div>
            {messageRight &&
                <div className='page-label__content-right'>
                    <div className='page-label__message-right'>
                        {messageRight.toUpperCase()}
                    </div>
                </div>
            }
        </div>
    )
}

export default PageLabel;