import { Col, Row, Tag, Typography } from 'antd';
import { GetAdminEventResponse } from 'App/api/endpoints/events/responses';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';
import { tableUtils } from 'App/common/utils/table.utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EventSystemTabProps {
	event: GetAdminEventResponse;
}

const EventSystemTab = ({ event }: EventSystemTabProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);

	if (event) {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Status')}:{' '}
							</Typography.Text>
							<Typography.Text>
								<Tag color={tableUtils.statusToColor(event.status)}>
									{t(`models:Event.Status.${event.status}`)}
								</Tag>
							</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.DateCreated')}:{' '}
							</Typography.Text>
							<Typography.Text>
								{dateTimeUtils.getUtcTimeString(event.dateCreated?.toString())}
							</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.ExternalLink')}:{' '}
							</Typography.Text>
							<Typography.Text>
								<a href={event.externalLink} target='blank'>
									{event.externalLink}
								</a>
							</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.SafetyCageRequired')}:{' '}
							</Typography.Text>
							<Typography.Text>
								{
									event.safetyCageRequired 
									? t('common:Actions.Yes') 
									: (event.safetyCageRequired === false ? t('common:Actions.No') : t('common:Actions.NotApply'))
								}
							</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Tags')}:{' '}
							</Typography.Text>
							<Typography.Text>
								{event.tags?.length ? event.tags.join(', ') : '-'}
							</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Category')}:{' '}
							</Typography.Text>
							<Typography.Text>
								{event.categories.map(c => t(`models:Event.Category.${c}`)).join(', ')}
							</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Surface')}:{' '}
							</Typography.Text>
							<Typography.Text>
								{event.surfaces?.length ? event.surfaces.map(s => t(`models:Event.Surface.${s}`)).join(', ') : '-'}
							</Typography.Text>
						</Typography>
					</Col>
				</Row>
			</>
		);
	} else {
		return <></>;
	}
};

export default EventSystemTab;
