import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, notification } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StatusType } from 'App/types/requestStatus';
import { cleanUpUsersTable } from 'App/pages/adminPages/usersPages/state/users.slice';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { deleteUser, getUsers } from '../../state/users.thunk';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { UserForGetUsersResponse } from 'App/api/endpoints/users/responses/getUsersResponse';
import UsersTable from './components/UsersTable';

const { LOADING } = StatusType;
const { defaultPaginationValues } = paginationUtils;
const UsersTableContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const users = useSelector((state: RootState) => state.pages.admin.users.users);
	const usersStatus = useSelector((state: RootState) => state.pages.admin.users.status);
	const getUserParams = useSelector((state: RootState) => state.pages.admin.users.getUsersParams);

	useEffect(() => {
		// pierwsze uruchomienie z domyślnie ustawionymi filtrami i orderby
		dispatch(
			getUsers({
				...defaultPaginationValues,
				filters: [{ field: 'isDeleted', values: ['false'] }],
				orderBy: [
					{
						field: 'lastName',
						direction: 'Ascending',
					},
				],
			})
		);

		return () => {
			dispatch(cleanUpUsersTable());
		};
	}, [dispatch]);

	const onDeleteUser = (userToDelete: UserForGetUsersResponse) => {
		Modal.confirm({
			title: `${t('AdminPages.UsersPages.ConfirmUserDeletionTitle')} ${userToDelete?.firstName} ${userToDelete?.lastName}?`,
			icon: <ExclamationCircleOutlined />,
			content: t('common:Warnings.ActionWillBeIrreversible'),
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.UsersPages.SuccessDeleteDescription'),
					});
				};
				dispatch(deleteUser(userToDelete.id, onSucces));
			},
		});
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Link to='/admin/users/create'>
						<Button icon={<PlusOutlined />}>{t('AdminPages.UsersPages.NewUserButton')}</Button>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<UsersTable
						dataSource={users}
						loading={usersStatus.getUsers === LOADING}
						requestPagination={getUserParams}
						deleteUser={onDeleteUser}
						getUsers={getUsers}
					/>
				</Col>
			</Row>
		</>
	);
};

export default UsersTableContainer;
