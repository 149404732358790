import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, notification } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { adminGetAdverts, deleteAdvert } from 'App/globalState/adverts/adverts.global.thunk';
import { cleanUpAdminAdvertsTable } from 'App/globalState/adverts/adverts.global.slice';
import { AdvertForAdminGetAdvertsResponse } from 'App/api/endpoints/adverts/responses';
import AdvertsTable from './components/AdvertsTable';

const { LOADING } = StatusType;
const { defaultPaginationValues } = paginationUtils;

const AdvertsTableContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

    const adminAdverts = useSelector((state: RootState) => state.global.adverts.adminAdverts);
    const advertsStatus = useSelector((state: RootState) => state.global.adverts.status);
    const getAdminAdvertsParams = useSelector((state: RootState) => state.global.adverts.adminGetAdvertsParams);

	useEffect(() => {
		// pierwsze uruchomienie z domyślnie ustawionymi filtrami i orderby
		dispatch(
			adminGetAdverts({
				...defaultPaginationValues,
				orderBy: [
					{
						field: 'latestChange',
						direction: 'Ascending',
					},
				]
			})
		);

		return () => {
			dispatch(cleanUpAdminAdvertsTable());
		};
	}, [dispatch]);

	const onDeleteAdvert = (advertToDelete: AdvertForAdminGetAdvertsResponse) => {
		Modal.confirm({
			title: `${t('AdminPages.AdvertsPages.ConfirmAdvertDeletionTitle')} ${advertToDelete?.title}?`,
			icon: <ExclamationCircleOutlined />,
			content: t('common:Warnings.ActionWillBeIrreversible'),
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.AdvertsPages.SuccessDeleteDescription'),
					});
					dispatch(adminGetAdverts(getAdminAdvertsParams));
				};
				dispatch(deleteAdvert(advertToDelete.id, onSucces));
			},
		});
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Link to='/admin/adverts/create'>
						<Button icon={<PlusOutlined />}>{t('AdminPages.AdvertsPages.NewAdvertButton')}</Button>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<AdvertsTable
						dataSource={adminAdverts}
						loading={advertsStatus.adminGetAdverts === LOADING}
						requestPagination={getAdminAdvertsParams}
						deleteAdvert={onDeleteAdvert}
						getAdverts={adminGetAdverts}
					/>
				</Col>
			</Row>
		</>
	);
};

export default AdvertsTableContainer;
