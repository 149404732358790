import { Col, Row } from 'antd';
import { AdvertForGetAdvertsResponse } from 'App/api/endpoints/adverts/responses';
import React from 'react'
import AdvertTile from './components/AdvertTile';

import './AdvertsColumn.less';

interface AdvertsColumnParams {
    adverts: AdvertForGetAdvertsResponse[];
}

const AdvertsColumn = ({ adverts }: AdvertsColumnParams) => {
    
  return (
    <div className='parts-column'>
      {adverts.map(ad => (
        <Row style={{ width: '100%' }} key={ad.id}>
          <Col style={{ width: '100%' }}>
            <AdvertTile advert={ad} />
          </Col>
        </Row>
      ))}        
    </div>
  )
}

export default AdvertsColumn;