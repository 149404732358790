import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCurrentMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses';
import StatusType from 'App/types/requestStatus';
import { maintenanceMessagesInitialState, MaintenanceMessagesState } from './maintenanceMessages.global.state';

const { LOADING, SUCCESS, FAILED } = StatusType;

export const globalMaintenanceMessagesSlice = createSlice({
	name: 'global-maintenance-messages',
	initialState: maintenanceMessagesInitialState,
	reducers: {
		getCurrentMessagesStart: (state: MaintenanceMessagesState) => {
			state.status.getCurrentMessages = LOADING;
		},
		getCurrentMessagesSuccess(state: MaintenanceMessagesState, action: PayloadAction<GetCurrentMessagesResponse>) {
			state.currentMessages = action.payload.messages;
			state.status.getCurrentMessages = SUCCESS;
		},
		getCurrentMessagesFailure(state: MaintenanceMessagesState) {
			state.status.getCurrentMessages = FAILED;
		},
	},
});

export default globalMaintenanceMessagesSlice;

export const {
	getCurrentMessagesStart,
	getCurrentMessagesSuccess,
	getCurrentMessagesFailure,
} = globalMaintenanceMessagesSlice.actions;
