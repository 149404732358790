import { MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Mobile } from '../Responsive/Mobile';
import { Default } from '../Responsive/Default';
import AccountSubMenu from './AccountSubMenu';
import { useTranslation } from 'react-i18next';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import HomeMenuItem from './HomeMenuItem';
import { EventCategory } from 'App/common/enums';
import { EventCategoryGrouping } from 'App/common/utils/eventCategoryGrouping.utils';

interface AdminNavbarProps {
	accountDetails: GetAccountDetailsResponse;
}

const AdminNavbar = ({ accountDetails }: AdminNavbarProps) => {
	const location = useLocation();
	const history = useHistory();
	const { t } = useTranslation('page');
	const [openKeys, setOpenKeys] = useState([]);

	const adminUsersMenuItem = (
		<Menu.Item key='/admin/events'>
			<Link to='/admin/events'>{t('Navbar.Admin')}</Link>
		</Menu.Item>
	);

	const clearMenu = () => {
		setOpenKeys([]);
	}

	const navigateToSearch = () => {
		clearMenu();
		history.push('/#szukaj');
	}
	const searchEventsItem = () => (	
		<Menu.Item
			key={'/#szukaj'}
			onClick={navigateToSearch}
		>
			{t('Navbar.SearchEvent').toUpperCase()}
		</Menu.Item>
	);

	const navigateToCategory = (category: EventCategory) => {
		clearMenu();
		history.push(`/?categories=${category}`);
	}
	const categoriesItem = (isMobile: boolean) => {
		const categories = EventCategoryGrouping.toOptions(t);
		
		return <Menu.SubMenu
			key='categories'
			title={t('Navbar.Categories').toUpperCase()}
		>
			{categories.map(category => category.children
				? (
					<Menu.SubMenu
						key={category.value}
						title={category.label}
					>
						{category.children.map(sub => (
							<Menu.Item
								className='category-option'
								key={sub.value}
								onClick={() => navigateToCategory(sub.value as any)}
							>
								{sub.label}
							</Menu.Item>
						))}
					</Menu.SubMenu>
				) : (
					<Menu.Item
						className='category-option'
						key={category.value}
						onClick={() => navigateToCategory(category.value as any)}
					>
						{category.label}
					</Menu.Item>
				))
			}
		</Menu.SubMenu>
	};

	const navigateToSubmitEvent = () => {
		clearMenu();
		history.push('/dodaj-wydarzenie');
	}
	const submitEventItem = () => (
		<Menu.Item
			key={'/dodaj-wydarzenie'}
			onClick={navigateToSubmitEvent}
		>	
			{t('Navbar.AddEvent').toUpperCase()}
		</Menu.Item>
	)

	const navigateToContact = () => {
		clearMenu();
		history.push('/kontakt');
	}
	const contactItem = () => (
		<Menu.Item
			key={'/kontakt'}
			onClick={navigateToContact}
		>
			{t('Navbar.Contact').toUpperCase()}
		</Menu.Item>
		
	)

	const onOpenChange = _openKeys => {
		setOpenKeys([..._openKeys]);
	}

	const onClickMenu = () => {
		setOpenKeys(['admin-mobile']);
	}

	return (
		<>
			<Mobile>
				<Row>
					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-mobile' />
						</Menu>
					</Col>
					<Col span={8}>
						<Menu 
							mode='horizontal' 
							selectedKeys={[location.pathname]} 
							className='justify-content-end' 
							openKeys={openKeys} 
							onOpenChange={onOpenChange}
						>
							<Menu.SubMenu
								key='admin-mobile'
								className='float-right to-close'
								icon={<MenuOutlined className='mr-0' />}
								onTitleClick={onClickMenu}
							>
								<>
									{adminUsersMenuItem}
									<Menu.Divider />
									{searchEventsItem()}
									{categoriesItem(true)}
									{submitEventItem()}
									{contactItem()}
									<Menu.Divider />
									<AccountSubMenu
										key='accountsubmenu-mobile'
										isMobile={true}
										accountDetails={accountDetails}
									/>
								</>
							</Menu.SubMenu>
						</Menu>
					</Col>
				</Row>
			</Mobile>

			<Default>
				<Row justify='space-between'>
					<Col span={8}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-default' />
							{adminUsersMenuItem}
						</Menu>
					</Col>

					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							{searchEventsItem()}
							{categoriesItem(true)}
							{submitEventItem()}
							{contactItem()}
							<AccountSubMenu
								key='accountsubmenu-default'
								isMobile={false}
								accountDetails={accountDetails}
							/>
						</Menu>
					</Col>
				</Row>
			</Default>
		</>
	);
};

export default AdminNavbar;
