import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { formRules } from 'App/common/formRules/formRules';

interface LoginFormProps extends FormProps {
	loading: boolean;
}

const ForgotPasswordForm = (props: LoginFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	const { loading, ...rest } = props;
	const { email } = formRules.user;

	return (
		<Form layout='vertical' {...rest}>
			<Form.Item name='email' messageVariables={{ arg: t('models:User.Labels.Email') }} rules={email()}>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder={t('models:User.Placeholders.Email')}
				/>
			</Form.Item>
			<Form.Item name='language' initialValue={i18n.language} hidden>
				<Input hidden />
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit' size='large'>
					{t('common:Actions.Submit')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default ForgotPasswordForm;
