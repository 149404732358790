import { serverURL } from "App/api/agent/axios/configuration";

const localStorageKey = `${serverURL}_KalMot_v1`;

const lsKeyCal = "CalView";
const lsKeyCookies = "AccCook";
const lsKeyLang = "SetLng";

export const LocalStorage = {
    calendarView: {
        save: (view: 'list' | 'calendar') => localStorage.setItem(`${localStorageKey}_${lsKeyCal}`, view),
        get: (): 'list' | 'calendar' => localStorage.getItem(`${localStorageKey}_${lsKeyCal}`) === 'calendar' ? 'calendar' : 'list',
    },

    acceptedCookiesInfo: {
        save: (accepted: boolean) => localStorage.setItem(`${localStorageKey}_${lsKeyCookies}`, accepted ? '1' : '0'),
        get: (): boolean => localStorage.getItem(`${localStorageKey}_${lsKeyCookies}`) === '1',
    },

    language: {
        save: (lang: string) => localStorage.setItem(`${localStorageKey}_${lsKeyLang}`, lang),
        get: (): string => localStorage.getItem(`${localStorageKey}_${lsKeyLang}`),
    }
}