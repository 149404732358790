import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, notification, PageHeader, Result, Row, UploadProps } from 'antd';
import { UpdateEventRequest } from 'App/api/endpoints/events/requests';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { cleanUpAdminEvent, cleanUpAdminEventsTable, cleanUpEventsStatus } from 'App/globalState/events/events.global.slice';
import { getAdminEvent, updateEvent } from 'App/globalState/events/events.global.thunk';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import UpdateEventForm from './forms/updateEventForm/UpdateEventForm';
import { UploadFile } from 'antd/lib/upload/interface';

interface RouteParams {
	eventId: string;
}

interface UpdateEventContainerProps extends RouteComponentProps<RouteParams> {}

const { LOADING } = StatusType;

const UpdateEventContainer = ({ match }: UpdateEventContainerProps) => {
	const { t } = useTranslation(['page', 'common']);
	const history = useHistory();
	const dispatch = useDispatch();

    const eventId = match.params.eventId;
    const adEvent = useSelector((state: RootState) => state.global.events.adminEvent);
    const eventStatus = useSelector((state: RootState) => state.global.events.status);

	const [bannerFile, setBannerFile] = useState<UploadFile>(null);
    const [posterFile, setPosterFile] = useState<UploadFile>(null);

    const isFileImage = (file: UploadFile): boolean => {
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
        
        if (!isImage) {
            notification.error({
				message: t('common:Errors.Error'),
				description: t('AdminPages.EventsPages.FileIsNotImage'),
			});
        }

        return isImage;
    }

    const uploadBannerProps: UploadProps = {
        name: 'banner',
        multiple: false,
        maxCount: 1,
        showUploadList: bannerFile != null,
        accept: "image/png, image/jpeg",
		fileList: bannerFile ? [ bannerFile ] : [],
        onRemove: (file) => {
            setBannerFile(null);
		},
		beforeUpload: (file : UploadFile) => {
            if(isFileImage(file))
                setBannerFile(file);
            else
                setBannerFile(null);

			return false;
		}
    };

    const uploadPosterProps: UploadProps = {
        name: 'poster',
        multiple: false,
        maxCount: 1,
        showUploadList: bannerFile != null,
        accept: "image/png, image/jpeg",
		fileList: posterFile ? [ posterFile ] : [],
        onRemove: (file) => {
            setPosterFile(null);
		},
		beforeUpload: (file : UploadFile) => {
            if(isFileImage(file))
                setPosterFile(file);
            else
                setPosterFile(null);

			return false;
		}
    };

	useEffect(() => {
		if (!adEvent) {
			dispatch(getAdminEvent(eventId));
		}
	}, [dispatch, adEvent, eventId]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpEventsStatus());
			dispatch(cleanUpAdminEvent());
			dispatch(cleanUpAdminEventsTable());
		};
	}, [dispatch]);

	const handleFormSubmit = (values: UpdateEventRequest) => {
		if (adEvent) {
			values.banner = bannerFile;
	        values.poster = posterFile;

			const onSuccess = () => {
				notification.success({
					message: t('common:Success.Success'),
					description: t('AdminPages.EventsPages.SuccessUpdateDescription'),
				});
				history.push(`/admin/events/${adEvent.id}`);
			};
			dispatch(updateEvent(adEvent.id, values, onSuccess));
		}
	};

	return eventStatus.getAdminEvent === LOADING ? (
		<LoadingScreen container='screen' />
	) : adEvent ? (
		<React.Fragment>
			<Button
				style={{ marginLeft: 16 }}
				onClick={() => history.push('/admin/events')}
				icon={<ArrowLeftOutlined />}
			>
				{t('common:Actions.GoBack')}
			</Button>
			<Row align='middle' justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.EventsPages.UpdatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<UpdateEventForm
								initialValues={adEvent}
								onFinish={handleFormSubmit}
								loading={eventStatus.updateEvent === LOADING}
								bannerUploadProps={uploadBannerProps}
								posterUploadProps={uploadPosterProps}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('page:AdminPages.UsersPages.UserNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default UpdateEventContainer;