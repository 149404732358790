// react
import React, { useEffect } from 'react';
import { Router } from 'react-router';

// third-party
import { ConfigProvider, Layout, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from "react-helmet-async";

// local direct
import Routes from './routes';
import './common/styles/bootstrap-utilities.less';
import NavbarContainer from './common/containers/NavbarContainer';
import FooterContainer from './common/containers/FooterContainer';
import history from '../app.history';

// styles
import './App.less';
import './common/styles/bootstrap-utilities.less';

// languages
import { validationMessages } from './common/utils/i18nDefaultAntDesignFormMessages';
import { i18nToAntdLocaleMapper } from './common/utils/i18nToAntdLocaleMapper';
import { useDispatch, useSelector } from 'react-redux';
import MaintenanceMessages from './common/components/MaintenanceMessages/MaintenanceMessages';
import { getAccountDetails } from './globalState/account/account.global.thunk';
import { appStarted } from './globalState/auth/auth.global.slice';
import { RootState } from './globalState/root.reducer';
import { i18nToMomentLocaleMapper } from './common/utils/i18nToMomentLocaleMapper';
import { i18nCountryNames } from './common/utils/i18nCountryNames.utils';
import i18n from 'i18n';
import { LocalStorage } from './common/utils/localStorage.utils';
import { CookiesInfo } from './common/components/cookiesInfo/CookiesInfo';

const { Content, Header, Footer } = Layout;
const App = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const hideGlobalNavbar = useSelector((state: RootState) => state.global.alterLayout.hideGlobalNavbar);

	useEffect(() => {
		const onFinished = () => dispatch(appStarted());
		dispatch(getAccountDetails(onFinished, onFinished));

		i18nLoadLanguage();
	}, [dispatch]);

	const i18nLoadLanguage = () => {
		const lang = LocalStorage.language.get();
		if(lang)
			i18n.changeLanguage(lang);
	}

	notification.config({
		placement: 'bottomRight',
		bottom: 0,
		duration: 5,
	});

	i18nToMomentLocaleMapper();
	i18nCountryNames();
	
	return (
		<ConfigProvider locale={i18nToAntdLocaleMapper()} form={{ validateMessages: validationMessages(t) }}>
			<GoogleReCaptchaProvider reCaptchaKey='6LfAOgokAAAAAHm_JgLfG2gJ9eKl33s_brdsQmH_'>
				<Helmet>
					{/* META Props */}
					<meta name="title" content="Kalendarz Motoryzacyjny" />
					<meta name="description" content="Baza wydarzeń samochodowych" />

					{/* OG Props */}
					<meta property="og:type" content="article" />
					<meta property="og:image" content="https://kalendarz-motoryzacyjny.pl/static/media/ogbanner.b40983fc.jpg" />
					<meta property="og:site_name" content="Kalendarz Motoryzacyjny" />
					<meta property="og:title" content="Baza wydarzeń" />
					<meta property="og:description" content="Baza wydarzeń samochodowych" />
					<meta property="og:url" content="https://kalendarz-motoryzacyjny.pl/" />
				</Helmet>
				<Router history={history}>
					<Layout className='layout'>
						{!hideGlobalNavbar && 
							<Header className='fixed-header bg-site'>
								<NavbarContainer />
							</Header>
						}
						<Content className='content-layout' style={hideGlobalNavbar ? {} : { marginTop: '64px' }}>
							<MaintenanceMessages />
							<Routes />
						</Content>
						<Footer>
							<FooterContainer />
						</Footer>
						<CookiesInfo />
					</Layout>
				</Router>
			</GoogleReCaptchaProvider>
		</ConfigProvider>
	);
};

export default App;
