import Icon from '@ant-design/icons';
import { Carousel, Col, Row, Spin } from 'antd';
import { EventForGetEventsResponse, EventForGetPromotedEventsResponse } from 'App/api/endpoints/events/responses';
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { EventCarouselCard } from './eventCarouselCard/EventCarouselCard';

import { ReactComponent as ArrowLeft } from 'App/common/assets/arrow_left.svg';
import { ReactComponent as ArrowRight } from 'App/common/assets/arrow_right.svg';

import './LatestEventsCarousel.less';
import { Default } from 'App/common/components/Responsive/Default';
import { Mobile } from 'App/common/components/Responsive/Mobile';

interface LatestEventsCarouselParams {
    events: EventForGetEventsResponse[] | EventForGetPromotedEventsResponse[];
    visibleSlides: number;
    loading?: boolean;
}

const LatestEventsCarousel = ({ events, visibleSlides, loading }: LatestEventsCarouselParams) => {
	const { t } = useTranslation('page');
    const carouselRef = useRef(null);

    const [highlighted, setHighlighted] = useState([1, 2, 3]);

    const nextSlide = () => carouselRef.current.next();
    const prevSlide = () => carouselRef.current.prev();

    const updateStyles = (current, next) => {
        let newSet = [];
        for (let i = 0; i < visibleSlides; i++)
            newSet.push(next + i + 1);

        newSet = newSet.map(h => {
            if(h >= events.length)
                return h - events.length;
            if(h < 0)
                return h + events.length;
            return h;
        });

        setHighlighted(newSet);
    }

    const checkClass = (index: number): boolean => {
        return highlighted.findIndex(h => h === index) >= 0;
    }
    
    return loading 
    ? (
        <div className='latest-loading'>
            <Spin size='large' />
        </div>
    ) 
    : (
        <div className='latest-events'>
            {events?.length > 0 
            ? (
                <>
                <Default>
                    {events.length > 5 ? (
                        <>
                            <Icon component={ArrowLeft} onClick={prevSlide} className='latest-events__arrow latest-events__arrow__prev' />
                            <Carousel 
                                autoplay
                                pauseOnHover
                                dots={false}
                                autoplaySpeed={5000}
                                slidesToShow={visibleSlides + 2}
                                slidesToScroll={1}
                                ref={carouselRef}
                                beforeChange={updateStyles}
                            >
                                {events.map((ev, idx) => 
                                    <div key={ev.id} className={checkClass(idx) ? "highlighted" : "grayed" }>
                                        <EventCarouselCard event={ev} />
                                    </div>
                                )}
                            </Carousel>
                            <Icon component={ArrowRight} onClick={nextSlide} size={50} className='latest-events__arrow latest-events__arrow__next' />
                        </>
                    ) : (
                        <Row>
                            {events.map((ev, i) => (
                                <>
                                    <Col span={events.length === 5 ? 4 : (24 / events.length)}>
                                        <EventCarouselCard event={ev}/>
                                    </Col>
                                    {events.length === 5 && i !== 4 &&
                                        <Col span={1}></Col>
                                    }
                                </>
                            ))}
                        </Row>
                    )}
                </Default>
                <Mobile>
                    <Carousel 
                        autoplay
                        pauseOnHover
                        dots={true}
                        autoplaySpeed={2500}
                        slidesToShow={1}
                        slidesToScroll={1}
                    >
                        {events.map(ev => 
                            <div key={ev.id} className='highlighted'>
                                <EventCarouselCard event={ev} />
                            </div>
                        )}
                    </Carousel>
                </Mobile>
                </>
            )
            : (
                <div>{t('EventPages.NoEventsFound')}</div>
            )}
        </div>
    )
}

export default LatestEventsCarousel;