import React from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formRules } from 'App/common/formRules/formRules';

interface LoginFormProps extends FormProps {
	loading: boolean;
}

const LoginForm = (props: LoginFormProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);
	const { loading, ...rest } = props;
	const { email, loginPassword } = formRules.user;
	return (
		<Form {...rest}>
			<Form.Item name='email' messageVariables={{ arg: t('models:User.Labels.Email') }} rules={email()}>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder={t('models:User.Placeholders.Email')}
				/>
			</Form.Item>
			<Form.Item
				name='password'
				messageVariables={{ arg: t('models:User.Labels.Password') }}
				rules={loginPassword()}
			>
				<Input
					prefix={<LockOutlined className='site-form-item-icon' />}
					type='password'
					placeholder={t('models:User.Placeholders.Password')}
				/>
			</Form.Item>
			<Row justify='start'>
				<Col span={12}>
					<Form.Item>
						<Button loading={loading} type='primary' htmlType='submit' size='large'>
							{t('common:Actions.SignIn')}
						</Button>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Row justify='end'>
						<Link to='account/resend-confirmation-email'>{t('AuthPages.ResendConfirmationEmail')}</Link>
					</Row>
					<Row justify='end'>
						<Link to='account/forgot-password'>{t('AuthPages.ForgotPassword')}</Link>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};

export default LoginForm;
