import { Rule } from 'antd/lib/form';
import i18n from 'i18n';

export const eventFormRules = {
    title: (): Rule[] => [{ required: true, max: 127 }],
    organizer: (): Rule[] => [{ required: true, max: 127 }],
    organizerEmail: (): Rule[] => [{ required: true, max: 127, type: 'email' }],
    dateStart: (): Rule[] => [{ required: true }],
    dateEnd: (): Rule[] => [{ required: true }],
    dates: (): Rule[] => [{ required: true }],
    
    category: (): Rule[] => [{ required: true }],
    tags: (): Rule[] => [{ 
        max: 6,
        type: 'array'
    },
    {
        validator: (rule, value, callback) => {
            if(value) {
                value.forEach(element => {
                    if(element.length > 127)
                        callback(i18n.t('form:ValidationMessages.TooLongTag', {
                            len: '127'
                        }));
                });
            }

            callback();
        },
    }],
    
    city: (): Rule[] => [{ max: 127 }],
    state: (): Rule[] => [{ max: 127 }],
    country: (): Rule[] => [{ max: 3 }],

    tos: (): Rule[] => [{
        required: true,
        validator: (rule, value, callback) => {
            if(value)
                callback();
            
            callback(i18n.t('form:ValidationMessages.TOS'));
        }
    }],

    privacy: (): Rule[] => [{
        required: true,
        validator: (rule, value, callback) => {
            if(value)
                callback();
            
            callback(i18n.t('form:ValidationMessages.PrivacyPolicy'));
        }
    }]
}