import StatusType from 'App/types/requestStatus';
const { INITIAL } = StatusType;

export interface AccountState {
	status: {
		confirmEmail: StatusType;
		forgotPassword: StatusType;
		updateAccountDetails: StatusType;
		resetPassword: StatusType;
		deleteAccount: StatusType;
		resendConfirmationEmail: StatusType;
	};
}

export const accountInitialState: AccountState = {
	status: {
		confirmEmail: INITIAL,
		forgotPassword: INITIAL,
		updateAccountDetails: INITIAL,
		resetPassword: INITIAL,
		deleteAccount: INITIAL,
		resendConfirmationEmail: INITIAL,
	},
};
