import { serverURL } from 'App/api/agent/axios/configuration';
import { EventForGetEventsResponse, EventForGetPromotedEventsResponse } from 'App/api/endpoints/events/responses'
import React from 'react'
import { Link } from 'react-router-dom';
import { EventUtils } from '../../../utils/EventUtils';

import Blank from 'App/common/assets/blank.webp';

import './EventCarouselCard.less';

interface EventCarouselCardProps {
    event: EventForGetEventsResponse | EventForGetPromotedEventsResponse;
}

export const EventCarouselCard = ({ event }: EventCarouselCardProps) => {
    const bannerUrl = (): string => (serverURL + event.urlBanner).replace('\\', '/');

    return (
        <Link to={`/event/${event.slug ?? event.id}`}>
            <div className='event-carousel-card'>
                <div className='event-carousel-card__image' style={{ backgroundImage: `url(${bannerUrl()}), url(${Blank})`}}></div>
                <div className='event-carousel-card__text'>
                    <div className='event-carousel-card__header'>
                        <div className='event-carousel-card__header__title'>
                            <b>{event.title}</b>
                        </div>
                    </div>
                    <div className='event-carousel-card__content'>
                        <div className='date'>
                            {EventUtils.buildDateString(event.dateStart, event.dateEnd)}
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}
