import agent from "App/api/agent/agent";
import { AppThunk } from "../store";
import { 
    AdminGetAdvertsRequest, 
    CreateAdvertRequest, 
    GetAdvertsRequest, 
    UpdateAdvertRequest
} from "App/api/endpoints/adverts/requests";
import { 
    adminGetAdvertFailure,
    adminGetAdvertsFailure, 
    adminGetAdvertsStart, 
    adminGetAdvertsSuccess, 
    adminGetAdvertStart,
    adminGetAdvertSuccess,
    createAdvertFailure,
    createAdvertStart,
    createAdvertSuccess,
    deleteAdvertFailure,
    deleteAdvertStart,
    deleteAdvertSuccess,
    getAdvertsFailure,
    getAdvertsStart,
    getAdvertsSuccess,
    updateAdvertFailure,
    updateAdvertStart,
    updateAdvertSuccess
} from "./adverts.global.slice";


export const adminGetAdverts = (params: AdminGetAdvertsRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(adminGetAdvertsStart());
    agent.Adverts.adminGetAdverts(params)
        .then((res) => {
            dispatch(adminGetAdvertsSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(adminGetAdvertsFailure(err)));
};

export const adminGetAdvert = (advertId: string, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(adminGetAdvertStart());
    agent.Adverts.adminGetAdvert(advertId)
        .then((res) => {
            dispatch(adminGetAdvertSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(adminGetAdvertFailure(err)));
};

export const createAdvert = (params: CreateAdvertRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(createAdvertStart());
    agent.Adverts.createAdvert(params)
        .then((res) => {
            dispatch(createAdvertSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(createAdvertFailure(err)));
};

export const updateAdvert = (advertId: string, params: UpdateAdvertRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(updateAdvertStart());
    agent.Adverts.updateAdvert(advertId, params)
        .then((res) => {
            dispatch(updateAdvertSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(updateAdvertFailure(err)));
};

export const deleteAdvert = (advertId: string, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(deleteAdvertStart());
    agent.Adverts.deleteAdvert(advertId)
        .then((res) => {
            dispatch(deleteAdvertSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(deleteAdvertFailure(err)));
};

export const getAdverts = (params: GetAdvertsRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(getAdvertsStart());
    agent.Adverts.getAdverts(params)
        .then((res) => {
            dispatch(getAdvertsSuccess(res));
                if(onSuccess)
                    onSuccess();
        })
       .catch((err) => dispatch(getAdvertsFailure(err)));
};