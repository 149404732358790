import { advertFormRules } from './models/advert.rules';
import { contactFormRules } from './models/contact.rules';
import { eventFormRules } from './models/event.rules';
import { maintenanceMessageFormRules } from './models/maintenanceMessage.rules';
import { searchEventFormRules } from './models/searchEvent.rules';
import { userFormRules } from './models/user.rules';

export const formRules = {
	advert: advertFormRules,
	contact: contactFormRules,
	event: eventFormRules,
	searchEvent: searchEventFormRules,
	user: userFormRules,
	maintenanceMessages: maintenanceMessageFormRules,
};
