import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Row, Col, notification, PageHeader, UploadProps } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { UploadFile } from 'antd/lib/upload/interface';
import { CreateAdvertRequest } from 'App/api/endpoints/adverts/requests';
import { createAdvert } from 'App/globalState/adverts/adverts.global.thunk';
import { cleanUpAdvertsStatus } from 'App/globalState/adverts/adverts.global.slice';
import CreateAdvertForm from './formss/createAdvertForm/CreateAdvertForm';

const { LOADING } = StatusType;

export const CreateAdvertContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation(['page', 'common']);

	const advertsStatus = useSelector((state: RootState) => state.global.adverts.status);

    const [pictureFile, setPictureFile] = useState<UploadFile>(null);

    const isFileImage = (file: UploadFile): boolean => {
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
        
        if (!isImage) {
            notification.error({
				message: t('common:Errors.Error'),
				description: t('AdminPages.EventsPages.FileIsNotImage'),
			});
        }

        return isImage;
    }

    const uploadPictureProps: UploadProps = {
        name: 'pictureFile',
        multiple: false,
        maxCount: 1,
        showUploadList: pictureFile != null,
        accept: "image/png, image/jpeg",
		fileList: pictureFile ? [ pictureFile ] : [],
        onRemove: (file) => {
            setPictureFile(null);
		},
		beforeUpload: (file : UploadFile) => {
            if(isFileImage(file))
                setPictureFile(file);
            else
                setPictureFile(null);

			return false;
		}
    };
      
	const handleFormSubmit = (values: CreateAdvertRequest) => {
        values.pictureFile = pictureFile;

		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.AdvertsPages.SuccessCreateDescription'),
			});

            history.push('/admin/adverts');
		};
		dispatch(createAdvert(values, onSuccess));
	};

	useEffect(() => {
		return () => {
			dispatch(cleanUpAdvertsStatus());
		};
	}, [dispatch]);

	return (
		<React.Fragment>
			<Row className='mb-5'>
				<Col>
					<Button
						style={{ marginLeft: 16 }}
						block
						onClick={() => history.push('/admin/adverts')}
						icon={<ArrowLeftOutlined />}
					>
						{t('common:Actions.GoBack')}
					</Button>
				</Col>
			</Row>
			<Row justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.AdvertsPages.CreatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<CreateAdvertForm
                                loading={advertsStatus.createAdvert === LOADING}
                                onFinish={handleFormSubmit}
                                advertUploadProps={uploadPictureProps}
                            />
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default CreateAdvertContainer;
