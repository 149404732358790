import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { SubmitContactFormRequest } from 'App/api/endpoints/contact/requests';
import { formRules } from 'App/common/formRules/formRules';
import React from 'react'
import { useTranslation } from 'react-i18next';

import './ContactForm.less';

interface ContactFormProps {
    loading?: boolean;
    onFinish: (params: SubmitContactFormRequest) => void;
}

const ContactForm = ({ loading, onFinish }: ContactFormProps) => {
	const { t } = useTranslation(['form', 'common', 'page']);
    const [form] = useForm();
    
	const { name, email, message } = formRules.contact;

    const clearAndSend = (params: SubmitContactFormRequest) => {
        form.setFieldsValue({
            name: "",
            email: "",
            message: ""
        });
        onFinish(params);
    }

    return (
        <div className='contact-form'>
            <p className='contact-form__title'>
                <b>
                    {t('page:HomePages.Contact.WriteToUs')}!
                </b>
            </p>

            <Form onFinish={clearAndSend} form={form} className='contact-form__form'>
                <Form.Item
                    messageVariables={{ arg: t('models:Contact.Labels.Name') }}
                    name='name'
                    rules={name()}
                >
                    <Input placeholder={t('models:Contact.Placeholders.Name')} />
                </Form.Item>

                <Form.Item
                    messageVariables={{ arg: t('models:Contact.Labels.Email') }}
                    name='email'
                    rules={email()}
                >
                    <Input placeholder={t('models:Contact.Placeholders.Email')} />
                </Form.Item>

                <Form.Item
                    messageVariables={{ arg: t('models:Contact.Labels.Message') }}
                    name='message'
                    rules={message()}
                >
                    <TextArea rows={6} placeholder={t('models:Contact.Placeholders.Message')} />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type='default' htmlType='submit' className='contact-form__form__button'>
                        {t('common:Actions.Submit')}
                    </Button>
			    </Form.Item>
            </Form>
        </div>
    )
}

export default ContactForm;