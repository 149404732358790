import { LocalStorage } from 'App/common/utils/localStorage.utils'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import './CookiesInfo.less';

export const CookiesInfo = () => {
	const { t } = useTranslation('page');
    const [accepted, setAccepted] = useState(LocalStorage.acceptedCookiesInfo.get());

    const onAccept = () => {
        LocalStorage.acceptedCookiesInfo.save(true);
        setAccepted(true);
    }

    return !accepted 
    ?
    (
        <div className="cookies-eu-banner">
            {t('Cookies.Info')} <a href='/terms-of-service'>{t('Cookies.TOS')}</a>.<br/>
            <button onClick={onAccept}>
                {t('Cookies.Accept')}
            </button>
        </div>
    )
    :
    (<></>)
}
