import { Modal } from 'antd';
import { EventForGetEventsResponse } from 'App/api/endpoints/events/responses'
import React from 'react'
import { useTranslation } from 'react-i18next';
import EventCardMobile from '../eventList/eventCardMobile/EventCardMobile';

interface EventPreviewProps {
    event?: EventForGetEventsResponse;
    onCancel?: () => void;
}

export const EventPreview = ({ event, onCancel }: EventPreviewProps) => {
	const { t } = useTranslation('common');

    return event ? (
        <Modal 
            open
            destroyOnClose
            okText={t('common:Actions.Details')}
            okButtonProps={{
                href: `/event/${event.slug ?? event.id}`
            }}
            cancelText={t('common:Actions.Close')}
            onCancel={onCancel}
            closable={false}
        >
            <EventCardMobile 
                event={event}
            />
        </Modal>
    ) : <></>
}
