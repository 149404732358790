import { createSlice } from '@reduxjs/toolkit';
import StatusType from 'App/types/requestStatus';
import { accountInitialState, AccountState } from './account.state';

const { FAILED, SUCCESS, LOADING } = StatusType;

const accountSlice = createSlice({
	name: 'account',
	initialState: accountInitialState,
	reducers: {
		confirmEmailStart: (state: AccountState) => {
			state.status.confirmEmail = LOADING;
		},
		confirmEmailSuccess: (state: AccountState) => {
			state.status.confirmEmail = SUCCESS;
		},
		confirmEmailFailure: (state: AccountState) => {
			state.status.confirmEmail = FAILED;
		},

		forgotPasswordStart: (state: AccountState) => {
			state.status.forgotPassword = LOADING;
		},
		forgotPasswordSuccess: (state: AccountState) => {
			state.status.forgotPassword = SUCCESS;
		},
		forgotPasswordFailure: (state: AccountState) => {
			state.status.forgotPassword = FAILED;
		},

		updateAccountDetailsStart: (state: AccountState) => {
			state.status.updateAccountDetails = LOADING;
		},
		updateAccountDetailsSuccess: (state: AccountState) => {
			state.status.updateAccountDetails = SUCCESS;
		},
		updateAccountDetailsFailure: (state: AccountState) => {
			state.status.updateAccountDetails = FAILED;
		},

		resetPasswordStart: (state: AccountState) => {
			state.status.resetPassword = LOADING;
		},
		resetPasswordSuccess: (state: AccountState) => {
			state.status.resetPassword = SUCCESS;
		},
		resetPasswordFailure: (state: AccountState) => {
			state.status.resetPassword = FAILED;
		},

		deleteAccountStart: (state: AccountState) => {
			state.status.deleteAccount = LOADING;
		},
		deleteAccountSuccess: (state: AccountState) => {
			state.status.deleteAccount = SUCCESS;
		},
		deleteAccountFailure: (state: AccountState) => {
			state.status.deleteAccount = FAILED;
		},

		resendConfirmationEmailStart: (state: AccountState) => {
			state.status.resendConfirmationEmail = LOADING;
		},
		resendConfirmationEmailSuccess: (state: AccountState) => {
			state.status.resendConfirmationEmail = SUCCESS;
		},
		resendConfirmationEmailFailure: (state: AccountState) => {
			state.status.resendConfirmationEmail = FAILED;
		},

		cleanUpAccountStatusStart: (state: AccountState) => {
			state.status = accountInitialState.status;
		},
	},
});

export default accountSlice;

export const {
	confirmEmailStart,
	confirmEmailSuccess,
	confirmEmailFailure,

	forgotPasswordStart,
	forgotPasswordSuccess,
	forgotPasswordFailure,

	updateAccountDetailsStart,
	updateAccountDetailsSuccess,
	updateAccountDetailsFailure,

	resetPasswordStart,
	resetPasswordSuccess,
	resetPasswordFailure,

	deleteAccountStart,
	deleteAccountSuccess,
	deleteAccountFailure,

	resendConfirmationEmailStart,
	resendConfirmationEmailSuccess,
	resendConfirmationEmailFailure,

	cleanUpAccountStatusStart,
} = accountSlice.actions;
