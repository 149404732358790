import { EventSearchFormValues } from "../components/eventSearchForm/EventSearchFormParams";
import querystring from 'querystring';
import moment from "moment";

const checkDefPrices = (value, min, max) => {
    if(value.length < 2)
        return false;

    return !(value[0] === min && value[1] === max);
}

export const URLUtils = {
    paramsToURL: (params: EventSearchFormValues, baseUrl: string = '/') => {
        let searchParams : {[key: string]: any} = {};

        if(params.query) searchParams.query = params.query;

        if(params.country) searchParams.country = params.country;
        if(params.state) searchParams.state = params.state;
        if(params.city) searchParams.city = params.city;
		if(params.distance) searchParams.distance = params.distance;

		if(params.categories && params.categories.length) searchParams.categories = params.categories;
		if(params.surfaces && params.surfaces.length) searchParams.surfaces = params.surfaces;
		if(params.safetyCageRequired) searchParams.safetyCageRequired = params.safetyCageRequired;

        let setCurrency = false;
        if(params.priceEntry && checkDefPrices(params.priceEntry, 0, 2100)) {
            setCurrency = true;
            searchParams.priceEntry = [
                params.priceEntry[0], 
                params.priceEntry[1]
            ];
        }
        if(params.priceTicket && checkDefPrices(params.priceTicket, 0, 600)) {
            setCurrency = true;
            searchParams.priceTicket = [
                params.priceTicket[0], 
                params.priceTicket[1]
            ];
        }
        if(setCurrency)
            searchParams.priceCurrency = params.priceCurrency;

        if(params.dateStart)
            searchParams.dateStart = moment(params.dateStart).format("YYYY-MM-DD");
        if(params.dateEnd)
            searchParams.dateEnd = moment(params.dateEnd).format("YYYY-MM-DD");

        if(params.page) searchParams.page = params.page;
        if(params.pageSize) searchParams.pageSize = params.pageSize;

		return `${baseUrl}?${querystring.stringify(searchParams)}`;
    },

    isArrayOfStrings: (value: any): boolean => {
		if (Array.isArray(value)) {
			let retTrue = true;

			value.forEach(function(item) {
				if(typeof item !== 'string'){
					retTrue = false;
			   	}
			});

			return retTrue && value.length > 0;
		}

		return false;
	},

    compareParams: (obj1: Object, obj2: Object): boolean => {
        if(!obj1 || !obj2)
            return false;
            
        let len = null;

        if(Object.keys(obj1).length !== Object.keys(obj2).length)
            return false;
        else
            len = JSON.stringify(obj1).length;
        
        let match = 0;
        let stringified = JSON.stringify(obj1);
        
        let ii = 0;
        while( ii < stringified.length) {
            if(JSON.stringify(obj2).includes(stringified[ii])) {
                match++;
            }
            ii++;
        }

        return match === len;
    }
}