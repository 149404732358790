import React, { useEffect } from 'react';
import { Form, Input, Button, Upload, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { formRules } from 'App/common/formRules/formRules';
import { DraggerProps } from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { UpdateAdvertRequest } from 'App/api/endpoints/adverts/requests';
import { AdminGetAdvertResponse } from 'App/api/endpoints/adverts/responses';

interface UpdateAdvertFormProps {
	onFinish: (values: UpdateAdvertRequest) => void;
	initialValues?: AdminGetAdvertResponse;
	loading: boolean;

    advertUploadProps: DraggerProps;
}

const UpdateAdvertForm = ({ initialValues, loading, onFinish, advertUploadProps }: UpdateAdvertFormProps) => {
	const { t } = useTranslation(['form', 'common', 'page']);
	const { title } = formRules.advert;

    const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue({ ...initialValues });
	}, [initialValues, form]);
	

	return (
		<Form 
            layout='vertical' 
            labelAlign='right' 
            className='px-3 update-advert-form' 
            initialValues={initialValues} 
            onFinish={onFinish} 
            form={form}
        >
            <Form.Item
				messageVariables={{ arg: t('models:Advert.Labels.Title') }}
				label={t('models:Advert.Labels.Title')}
				name='title'
				rules={title()}
			>
				<Input placeholder={t('models:Advert.Placeholders.Title')} />
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Advert.Labels.Picture') }}
				label={t('models:Advert.Labels.Picture')}
				name='pictureFile'
			>
				<Upload.Dragger {...advertUploadProps} multiple={false}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('page:AdminPages.EventsPages.FileSubmitText')}</p>
                    <p className="ant-upload-hint">{t('page:AdminPages.EventsPages.FileSubmitHint')}</p>
                </Upload.Dragger>
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Advert.Labels.Link') }}
				label={t('models:Advert.Labels.Link')}
				name='link'
			>
				<Input placeholder={t('models:Advert.Placeholders.Link')} />
			</Form.Item>

            <Form.Item
				messageVariables={{ arg: t('models:Advert.Labels.Active') }}
				label={t('models:Advert.Labels.Active')}
				name='active'
                valuePropName='checked'
			>
				<Switch />
			</Form.Item>

			<Form.Item>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default UpdateAdvertForm;
