import React from 'react';
import CreateUserContainer from './views/createUser/CreateUserContainer';
import { Route, Switch } from 'react-router';
import UsersTableContainer from './views/usersTable/UsersTableContainer';
import UpdateUserContainer from './views/updateUser/UpdateUserContainer';
import UserDetailsContainer from './views/userDetails/UserDetailsContainer';

const AdminUsersPages = () => {
	return (
		<Switch>
			<Route exact path='/admin/users' component={UsersTableContainer} />
			<Route exact path='/admin/users/create' component={CreateUserContainer} />
			<Route exact path='/admin/users/:userId/update' component={UpdateUserContainer} />
			<Route path='/admin/users/:userId' component={UserDetailsContainer} />
		</Switch>
	);
};

export default AdminUsersPages;
