import moment, { Moment } from 'moment';

export const dateTimeUtils = {
	getLocalDateTimeString: (date: string): string => {
		return moment(new Date(date + 'Z'))
			.local()
			.format('DD-MM-YYYY HH:mm:ss');
	},

	getLocalDateTime: (date: string): Moment => {
		return moment(new Date(date + 'Z')).local();
	},

	getUtcTimeString: (date: string): string => {
		return moment(new Date(date + 'Z'))
			.utc()
			.format('DD-MM-YYYY HH:mm:ss');
	},

	getUtcTime: (date: string): Moment => {
		return moment(new Date(date + 'Z')).utc();
	},

	formatWholeDayDate: (date: Moment, isWholeDay: boolean): string => {
		date = moment(date);
		if(isWholeDay)
			return date.format("DD-MM-YYYY");
		
		return date?.format("DD-MM-YYYY HH:mm");
	}
};
