import { TFunction } from 'i18next';

export const validationMessages = (t: TFunction) => {
	return {
		default: t('form:ValidationMessages.Default'),
		required: t('form:ValidationMessages.Required'),
		enum: t('form:ValidationMessages.Enum'),
		whitespace: t('form:ValidationMessages.Whitespace'),
		date: {
			format: t('form:ValidationMessages.Date.Format'),
			parse: t('form:ValidationMessages.Date.Parse'),
			invalid: t('form:ValidationMessages.Date.Invalid'),
		},
		types: {
			string: t('form:ValidationMessages.Types.String'),
			method: t('form:ValidationMessages.Types.Method'),
			array: t('form:ValidationMessages.Types.Array'),
			object: t('form:ValidationMessages.Types.Object'),
			number: t('form:ValidationMessages.Types.Number'),
			date: t('form:ValidationMessages.Types.Date'),
			boolean: t('form:ValidationMessages.Types.Boolean'),
			integer: t('form:ValidationMessages.Types.Integer'),
			float: t('form:ValidationMessages.Types.Float'),
			regexp: t('form:ValidationMessages.Types.Regexp'),
			email: t('form:ValidationMessages.Types.Email'),
			url: t('form:ValidationMessages.Types.Url'),
			hex: t('form:ValidationMessages.Types.Hex'),
		},
		string: {
			len: t('form:ValidationMessages.String.Len'),
			min: t('form:ValidationMessages.String.Min'),
			max: t('form:ValidationMessages.String.Max'),
			range: t('form:ValidationMessages.String.Range'),
		},
		number: {
			len: t('form:ValidationMessages.Number.Len'),
			min: t('form:ValidationMessages.Number.Min'),
			max: t('form:ValidationMessages.Number.Max'),
			range: t('form:ValidationMessages.Number.Range'),
		},
		array: {
			len: t('form:ValidationMessages.Array.Len'),
			min: t('form:ValidationMessages.Array.Min'),
			max: t('form:ValidationMessages.Array.Max'),
			range: t('form:ValidationMessages.Array.Range'),
		},
		pattern: {
			mismatch: t('form:ValidationMessages.Pattern.Mismatch'),
		},
	};
};
