import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import StatusType from "App/types/requestStatus";
import { alterLayoutInitialState, AlterLayoutState } from "./alterLayout.global.state";

const { LOADING, SUCCESS, FAILED } = StatusType;

export const globalAlterLayoutSlice = createSlice({
    name: 'global-alter-layout',
    initialState: alterLayoutInitialState,
    reducers: {
        setHideGlobalNavbarStart: (state: AlterLayoutState) => {
           state.status.setHideGlobalNavbar = LOADING;
        },
        setHideGlobalNavbarSuccess: (state: AlterLayoutState, action: PayloadAction<boolean>) => {
           state.status.setHideGlobalNavbar = SUCCESS;
           state.hideGlobalNavbar = action.payload;
        },
        setHideGlobalNavbarFailure: (state: AlterLayoutState, action: PayloadAction<any>) => {
           state.status.setHideGlobalNavbar = FAILED;
        },

        cleanUpAlterLayout: (state: AlterLayoutState) => {
		  state.hideGlobalNavbar = alterLayoutInitialState.hideGlobalNavbar;
		  state.status.setHideGlobalNavbar = alterLayoutInitialState.status.setHideGlobalNavbar;
		},
    }
});

export default globalAlterLayoutSlice;

export const {
    setHideGlobalNavbarStart,
    setHideGlobalNavbarSuccess,
    setHideGlobalNavbarFailure,

    cleanUpAlterLayout
} = globalAlterLayoutSlice.actions;