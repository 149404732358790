import { AppThunk } from '../store';
import {
	loginStart,
	loginSuccess,
	loginFailure,
	logoutStart,
	cleanUpAuthStatusStart,
	registerFailure,
	registerStart,
	registerSuccess,
	refreshTokenStart,
	refreshTokenSuccess,
	refreshTokenFailure,
	loginFinish,
} from './auth.global.slice';
import { LoginRequest, RegisterRequest } from 'App/api/endpoints/auth/requests';
import agent from 'App/api/agent/agent';
import { LoginResponse, RefreshTokenResponse } from 'App/api/endpoints/auth/responses';
import { clearAccountDetails, getAccountDetails } from '../account/account.global.thunk';

export const login = (payload: LoginRequest, onSuccess?: () => void, onFailure?: () => void): AppThunk => async (
	dispatch
) => {
	dispatch(loginStart());
	agent.Auth.login(payload)
		.then((response: LoginResponse) => {
			dispatch(loginSuccess(response));
			dispatch(getAccountDetails(() => dispatch(loginFinish())));
			if (onSuccess) onSuccess();
		})
		.catch(() => {
			if (onFailure) onFailure();
			dispatch(loginFailure());
		});
};

export const logout = (onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(logoutStart());
	agent.Auth.logout();
	localStorage.removeItem('returnUrl');
	dispatch(clearAccountDetails());
	if (onSuccess) onSuccess();
};

export const register = (payload: RegisterRequest, onSuccess?: () => void): AppThunk => async (dispatch) => {
	dispatch(registerStart());
	agent.Auth.register(payload)
		.then(() => {
			dispatch(registerSuccess());
			if (onSuccess) onSuccess();
		})
		.catch(() => dispatch(registerFailure()));
};

export const refreshToken = (onSuccess?: Function, onFailure?: Function): AppThunk => async (dispatch) => {
	dispatch(refreshTokenStart());
	agent.Auth.refreshToken()
		.then((response: RefreshTokenResponse) => {
			dispatch(refreshTokenSuccess(response));
			if (onSuccess) onSuccess();
		})
		.catch(() => {
			dispatch(refreshTokenFailure());
			if (onFailure) onFailure();
		});
};

export const cleanUpAuthStatus = (): AppThunk => async (dispatch) => {
	dispatch(cleanUpAuthStatusStart());
};
