import { SettingFilled } from '@ant-design/icons';
import { Dropdown, Button, Menu, Switch } from 'antd';
import Column from 'antd/lib/table/Column';
import { AdminGetAdvertsRequest } from 'App/api/endpoints/adverts/requests';
import { AdvertForAdminGetAdvertsResponse } from 'App/api/endpoints/adverts/responses';
import ExtendedTable from 'App/common/components/extendedTable/ExtendedTable';
import { tableUtils } from 'App/common/utils/table.utils';
import { AppThunk } from 'App/globalState/store';
import { IPagination } from 'App/types/pagination/pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dateTimeUtils } from '../../../../../../common/utils/dateTime.utils';

interface AdvertsTableProps {
	dataSource: AdvertForAdminGetAdvertsResponse[];
	loading: boolean;
	requestPagination: IPagination;
	deleteAdvert: (advertToDelete: AdvertForAdminGetAdvertsResponse) => void;
	getAdverts: (params: AdminGetAdvertsRequest) => AppThunk;
}

const { manageTableFilters, manageTableOrderBy, requestFiltersToAntdFilters, requestOrderByToAntdOrderBy } = tableUtils;

const AdvertsTable = ({ dataSource, loading, requestPagination, deleteAdvert, getAdverts }: AdvertsTableProps) => {
	const translatedOrderBy = requestOrderByToAntdOrderBy(requestPagination.orderBy);
	const translatedFilters = requestFiltersToAntdFilters(requestPagination.filters);

	const { t } = useTranslation();

	const menuForActionDropdown = (record: AdvertForAdminGetAdvertsResponse) => (
		<Menu>
			<Menu.Item key='edit-advert'>
				<Button type='link'>
					<Link to={`/admin/adverts/${record.id}/update`}>{t('common:Actions.Edit')}</Link>
				</Button>
			</Menu.Item>
			<Menu.Item key='delete-advert'>
				<Button type='link' onClick={() => deleteAdvert(record)}>
					{t('common:Actions.Remove')}
				</Button>
			</Menu.Item>
		</Menu>
	);

	return (
		<ExtendedTable
			searchable
			requestPagination={requestPagination}
			fetchDataAction={getAdverts}
			loading={loading}
			showHeader={!loading}
			dataSource={dataSource}
		>
            <Column
				key='title'
				dataIndex='title'
				title={t('models:Advert.Labels.Title')}
				sorter={{ multiple: 3 }}
				render={(title, record: AdvertForAdminGetAdvertsResponse) => (
					<Link to={`/admin/adverts/${record.id}`}>{title}</Link>
				)}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'title')}
			/>
            <Column
				key='latestChange'
				dataIndex='latestChange'
				title={t('models:Advert.Labels.LatestChange')}
				sorter={{ multiple: 3 }}
				render={(latestChange, record: AdvertForAdminGetAdvertsResponse) => (
					<Link to={`/admin/adverts/${record.id}`}>{dateTimeUtils.getUtcTimeString(latestChange)}</Link>
				)}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'dateCreated')}
			/>
            <Column
				title={t('models:Advert.Labels.Active')}
				dataIndex='active'
				key='active'
				filters={[{ text: t('common:Actions.Yes'), value: "true" }, { text: t('common:Actions.No'), value: "false" }]}
				filterMultiple={true}
				filteredValue={manageTableFilters(translatedFilters, 'status')}
                render={(value) => <Switch checked={value} />}
			/>

			<Column
				title={t('common:Actions.Actions')}
				render={(record: AdvertForAdminGetAdvertsResponse) => (
					<Dropdown
						overlay={() => menuForActionDropdown(record)}
						trigger={['click']}
						placement='bottomCenter'
					>
						<Button type='link'>
							<SettingFilled />
						</Button>
					</Dropdown>
				)}
			/>
		</ExtendedTable>
	);
};

export default AdvertsTable;
