import { Col, Row, Tag } from 'antd';
import { EventForGetEventsResponse } from 'App/api/endpoints/events/responses'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EventUtils } from '../../../utils/EventUtils';
import { serverURL } from 'App/api/agent/axios/configuration';
import moment from 'moment';

import Blank from 'App/common/assets/blank.webp';

import './EventCardMobile.less';
import { LoadingOutlined } from '@ant-design/icons';
import { EventCategoryGrouping } from 'App/common/utils/eventCategoryGrouping.utils';

interface EventCardProps {
    event: EventForGetEventsResponse;
}

export default function EventCardMobile({ event }: EventCardProps) {
	const { t } = useTranslation('page');

    const imgPath = (): string => `${serverURL + event?.urlBanner?.replaceAll('\\', '/')}`;

    const isLive = (): boolean => {
        const now = moment.utc();
        return moment.utc(event.dateStartUTC) <= now && moment.utc(event.dateEndUTC) >= now;
    }
    
    return (
        <Link to={`/event/${event.slug ?? event.id}`}>
            <div className='event-card-mobile'>
                {isLive() &&
                    <div className='event-card-mobile__live'>
                        <LoadingOutlined/> LIVE
                    </div>
                }
                <Row>
                    <Col className='event-card-mobile__image'>
                        <div className='event-card-mobile__image__bg' style={{ backgroundImage: `url(${imgPath()}), url(${Blank})`}}></div>
                    </Col>
                </Row>
                <Row>
                    <Col className='event-card-mobile__content'>
                        <Row className='event-card-mobile__section'>
                            <div className='event-card-mobile__header'>
                                <div className='event-card-mobile__header__title'>
                                    {event.title}
                                </div>
                                <div className='event-card-mobile__header__date'>
                                    {EventUtils.buildDateString(event.dateStart, event.dateEnd)}
                                </div>
                            </div>
                        </Row>
                        <Row className='event-card-mobile__section'>
                            <div className='event-card-mobile__location'>
                                {event.state}, {event.city}, {event.locationName?.length ? event.locationName : event.street}
                            </div>
                        </Row>
                        <Row className='event-card-mobile__section'>
                            <div className='event-card-mobile__description'>
                                <b>{t('models:Event.Labels.Organizer')}:</b> {event.organizer} <br/>
                                <b>{t('models:Event.Labels.Category')}:</b> {' '}
                                {
                                    EventCategoryGrouping.toGroups(t, event.categories).map(g => (
                                        <Tag 
                                            className='event-card-mobile__description__tag'
                                            color={EventUtils.categoryGroupColor(g.value)} 
                                        >
                                            {g.label}
                                        </Tag>
                                    ))//.join(', ')
                                } <br/>
                                {event.priceEntry != null &&
                                    <><b>{t('models:Event.Labels.PriceEntry')}:</b> {event.priceEntry} {event.priceCurrency}</>
                                }
                                {event.priceEntry != null && event.priceTicket != null &&
                                    <>, {' '}</>
                                }
                                {event.priceTicket != null &&
                                    <><b>{t('models:Event.Labels.PriceTicket')}:</b> {event.priceTicket} {event.priceCurrency} <br/></>
                                }
                            </div>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Link>
    );
}
