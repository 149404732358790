import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Badge, Button, Col, Modal, notification, Result, Row, Typography } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { StatusType } from 'App/types/requestStatus';
import { ArrowLeftOutlined, CalendarOutlined, CheckCircleOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { gold } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';
import { Mobile } from 'App/common/components/Responsive/Mobile';
import { Default } from 'App/common/components/Responsive/Default';
import { RootState } from 'App/globalState/root.reducer';
import { getAdminEvent, updateEventStatus } from 'App/globalState/events/events.global.thunk';
import { cleanUpAdminEvent, cleanUpEventsStatus } from 'App/globalState/events/events.global.slice';
import { GetEventTabs } from './components/GetEventTabs';
import { EventStatus } from 'App/common/enums';
import { serverURL } from 'App/api/agent/axios/configuration';

interface RouteParams {
	eventId: string;
}

interface EventDetailsContainerProps extends RouteComponentProps<RouteParams> { }

const { LOADING } = StatusType;

const EventDetailsContainer = ({ match }: EventDetailsContainerProps) => {
	const eventId = match.params.eventId;
	const { t } = useTranslation(['page', 'common']);

	const history = useHistory();
	const dispatch = useDispatch();

	const event = useSelector((state: RootState) => state.global.events.adminEvent);
	const eventStatus = useSelector((state: RootState) => state.global.events.status);

	useEffect(() => {
		if (!event) {
			dispatch(getAdminEvent(eventId));
		}
	}, [dispatch, event, eventId]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpEventsStatus());
			dispatch(cleanUpAdminEvent());
		};
	}, [dispatch]);

	const onDeleteEvent = () => {
		Modal.confirm({
			title: `${t('AdminPages.EventsPages.ConfirmEventDeletionTitle')} ${event?.title} (${event?.organizer})?`,
			icon: <ExclamationCircleOutlined />,
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.EventsPages.SuccessDeleteDescription'),
					});
					history.push('/admin/events');
				};

				dispatch(updateEventStatus(eventId, { status: EventStatus.Deleted }, onSucces));
			},
		});
	};

	const onVerifyEvent = () => {
		Modal.confirm({
			title: `${t('AdminPages.EventsPages.ConfirmEventVerificationTitle')} ${event?.title} (${event?.organizer})?`,
			icon: <ExclamationCircleOutlined />,
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.EventsPages.SuccessVerificationDescription'),
					});
					dispatch(getAdminEvent(eventId));
				};

				dispatch(updateEventStatus(eventId, { status: EventStatus.Verified }, onSucces));
			},
		});
	};

	const onUnverifyEvent = () => {
		Modal.confirm({
			title: `${t('AdminPages.EventsPages.ConfirmEventUnverificationTitle')} ${event?.title} (${event?.organizer})?`,
			icon: <ExclamationCircleOutlined />,
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.EventsPages.SuccessUnverificationDescription'),
					});
					dispatch(getAdminEvent(eventId));
				};

				dispatch(updateEventStatus(eventId, { status: EventStatus.Waiting }, onSucces));
			},
		});
	};

	return eventStatus.getAdminEvent === LOADING ? (
		<LoadingScreen container='screen' />
	) : event ? (
		<>
			<Button
				style={{ marginLeft: 16 }}
				onClick={() => history.push('/admin/events')}
				icon={<ArrowLeftOutlined />}
			>
				{t('common:Actions.GoBack')}
			</Button>

			<Button
				style={{ marginLeft: 16 }}
				onClick={() => history.push(`/admin/events/${eventId}/update`)}
				icon={<EditOutlined />}
			>
				{t('common:Actions.Edit')}
			</Button>

			{Number(EventStatus[event.status].valueOf()) === EventStatus.Waiting &&
				<Button
					style={{ marginLeft: 16 }}
					onClick={onVerifyEvent}
					icon={<CheckCircleOutlined />}
				>
					{t('common:Actions.Verify')}
				</Button>
			}

			{Number(EventStatus[event.status].valueOf()) === EventStatus.Verified &&
				<Button
					style={{ marginLeft: 16 }}
					onClick={onUnverifyEvent}
					icon={<CheckCircleOutlined />}
				>
					{t('common:Actions.Unverify')}
				</Button>
			}

			<Button
				style={{ marginLeft: 16 }}
				onClick={onDeleteEvent}
				icon={<DeleteOutlined />}
			>
				{t('common:Actions.Remove')}
			</Button>

			<Row justify='center' className='mt-5'>
				<Col>
					<Mobile>
						<Avatar size={72} src={serverURL + event.urlBanner} icon={<CalendarOutlined />} />
					</Mobile>
					<Default>
						<Avatar size={128} src={serverURL + event.urlBanner} icon={<CalendarOutlined />} />
					</Default>
				</Col>
			</Row>
			<Row justify='center'>
				<Col>
					<Badge style={{ color: gold[5] }} count={0}>
						<Typography.Text delete={event.status === EventStatus.Deleted} strong style={{ fontSize: '1.5rem' }}>
							{event.title}
						</Typography.Text>
					</Badge>
				</Col>
			</Row>
			<Row justify='center'>
				<Col>
					<Typography.Text type='secondary'>{event.organizer}</Typography.Text>
				</Col>
			</Row>
			<GetEventTabs event={event} />
		</>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('page:AdminPages.EventsPages.EventNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default EventDetailsContainer;
