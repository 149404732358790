import { serverURL } from 'App/api/agent/axios/configuration';
import { AdvertForGetAdvertsResponse } from 'App/api/endpoints/adverts/responses';
import React from 'react'

interface AdvertTileProps {
    advert: AdvertForGetAdvertsResponse;
}

const AdvertTile = ({ advert }: AdvertTileProps) => {
    return advert ? (
        <div className='parts-tile'>
            <a href={advert.link} target='blank'>
                <img src={serverURL + advert.picture?.replaceAll('\\', '/')} alt={advert.title} style={{ width: '100%', height: 'auto' }}/>
            </a>
        </div>
    ) : (
        <></>
    )
}

export default AdvertTile;