import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Avatar, Badge, Button, Card, Col, Modal, notification, Result, Row, Switch, Typography, Image } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { StatusType } from 'App/types/requestStatus';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { gold } from '@ant-design/colors';
import { useTranslation } from 'react-i18next';
import { Mobile } from 'App/common/components/Responsive/Mobile';
import { Default } from 'App/common/components/Responsive/Default';
import { RootState } from 'App/globalState/root.reducer';
import { adminGetAdvert, deleteAdvert } from 'App/globalState/adverts/adverts.global.thunk';
import { cleanUpAdminAdvert, cleanUpAdvertsStatus } from 'App/globalState/adverts/adverts.global.slice';
import { serverURL } from 'App/api/agent/axios/configuration';
import { dateTimeUtils } from 'App/common/utils/dateTime.utils';

interface RouteParams {
	advertId: string;
}

interface AdvertDetailsContainerProps extends RouteComponentProps<RouteParams> { }

const { LOADING } = StatusType;

const AdvertDetailsContainer = ({ match }: AdvertDetailsContainerProps) => {
	const advertId = match.params.advertId;
	const { t } = useTranslation(['page', 'common']);

	const history = useHistory();
	const dispatch = useDispatch();

	const advert = useSelector((state: RootState) => state.global.adverts.adminAdvert);
	const advertStatus = useSelector((state: RootState) => state.global.adverts.status);

	useEffect(() => {
		if (!advert) {
			dispatch(adminGetAdvert(advertId));
		}
	}, [dispatch, advert, advertId]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpAdvertsStatus());
			dispatch(cleanUpAdminAdvert());
		};
	}, [dispatch]);

    const adImage = (): string => `${serverURL}${advert?.picture?.replaceAll('\\', '/')}`;

	const onDeleteEvent = () => {
		Modal.confirm({
			title: `${t('AdminPages.AdvertsPages.ConfirmAdvertDeletionTitle')} ${advert?.title}?`,
			icon: <ExclamationCircleOutlined />,
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.AdvertsPages.SuccessDeleteDescription'),
					});
					history.push('/admin/adverts');
				};

				dispatch(deleteAdvert(advertId, onSucces));
			},
		});
	};

	return advertStatus.adminGetAdvert === LOADING ? (
		<LoadingScreen container='screen' />
	) : advert ? (
		<>
			<Button
				style={{ marginLeft: 16 }}
				onClick={() => history.push('/admin/adverts')}
				icon={<ArrowLeftOutlined />}
			>
				{t('common:Actions.GoBack')}
			</Button>

			<Button
				style={{ marginLeft: 16 }}
				onClick={() => history.push(`/admin/events/${advertId}/update`)}
				icon={<EditOutlined />}
			>
				{t('common:Actions.Edit')}
			</Button>

			<Button
				style={{ marginLeft: 16 }}
				onClick={onDeleteEvent}
				icon={<DeleteOutlined />}
			>
				{t('common:Actions.Remove')}
			</Button>

			<Row justify='center' className='mt-5'>
				<Col>
					<Mobile>
						<Avatar size={72} src={adImage()} />
					</Mobile>
					<Default>
						<Avatar size={128} src={adImage()} />
					</Default>
				</Col>
			</Row>
			<Row justify='center'>
				<Col>
					<Badge style={{ color: gold[5] }} count={0}>
						<Typography.Text strong style={{ fontSize: '1.5rem' }}>
							{advert.title}
						</Typography.Text>
					</Badge>
				</Col>
			</Row>
            <Row
                style={{
                    marginTop: 16,
                }}
                justify='center'
            >
                <Col xs={23} md={18} xl={14}>
                    <Card
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Typography>
                                    <Typography.Text type='secondary'>
                                        {t('models:Advert.Labels.Link')}:{' '}
                                    </Typography.Text>
                                    <Typography.Text>
                                        <a href={advert.link} target='blank'>
                                            {advert.link}
                                        </a>
                                    </Typography.Text>
                                </Typography>
                            </Col>
                            <Col span={24}>
                                <Typography>
                                    <Typography.Text type='secondary'>
                                        {t('models:Advert.Labels.LatestChange')}:{' '}
                                    </Typography.Text>
                                    <Typography.Text>
                                        {dateTimeUtils.getUtcTimeString(advert.latestChange.toString())}
                                    </Typography.Text>
                                </Typography>
                            </Col>
                            <Col span={24}>
                                <Typography>
                                    <Typography.Text type='secondary'>
                                        {t('models:Advert.Labels.Active')}:{' '}
                                    </Typography.Text>
                                    <Typography.Text>
                                        <Switch checked={advert.active} />
                                    </Typography.Text>
                                </Typography>
                            </Col>
                            <Col span={24}>
                                <Typography>
                                    <Typography.Text type='secondary'>
                                        {t('models:Advert.Labels.Picture')}:{' '}
                                    </Typography.Text>
                                    <Image src={adImage()} />
                                </Typography>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
		</>
	) : (
		<Result
			status='404'
			title={t('common:Errors.AnErrorOccured')}
			subTitle={t('page:AdminPages.AdvertsPages.AdvertNotFound')}
			extra={
				<Button type='primary' onClick={() => history.push('/')}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		/>
	);
};

export default AdvertDetailsContainer;
