import { GetUserResponse } from 'App/api/endpoints/users/responses';
import { UserForGetUsersResponse } from 'App/api/endpoints/users/responses/getUsersResponse';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import { IPagination } from 'App/types/pagination/pagination';
import { StatusType } from 'App/types/requestStatus';

const { INITIAL } = StatusType;
const { defaultPaginationValues } = paginationUtils;

export interface AdminUsersState {
	status: {
		getUsers: StatusType;
		getUser: StatusType;
		deleteUser: StatusType;
		createUser: StatusType;
		updateUser: StatusType;
	};
	users: UserForGetUsersResponse[];
	getUsersParams: IPagination;
	selectedUser: GetUserResponse | null;
}

export const adminUsersInitialState: AdminUsersState = {
	status: {
		getUsers: INITIAL,
		getUser: INITIAL,
		deleteUser: INITIAL,
		createUser: INITIAL,
		updateUser: INITIAL,
	},
	users: [],
	getUsersParams: defaultPaginationValues,
	selectedUser: null,
};
