import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminGetAdvertResponse, AdminGetAdvertsResponse, GetAdvertsResponse } from "App/api/endpoints/adverts/responses";
import StatusType from "App/types/requestStatus";
import { advertsInitialState, AdvertsState } from "./adverts.global.state";

const { LOADING, SUCCESS, FAILED } = StatusType;

export const globalAdvertsSlice = createSlice({
    name: 'global-adverts',
    initialState: advertsInitialState,
    reducers: {
        adminGetAdvertsStart: (state: AdvertsState) => {
           state.status.adminGetAdverts = LOADING;
        },
        adminGetAdvertsSuccess: (state: AdvertsState, action: PayloadAction<AdminGetAdvertsResponse>) => {
           state.status.adminGetAdverts = SUCCESS;
           const { adverts, ...pagination } = action.payload;
           state.adminAdverts = adverts;
           state.adminGetAdvertsParams = pagination;
        },
        adminGetAdvertsFailure: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.adminGetAdverts = FAILED;
        },

        adminGetAdvertStart: (state: AdvertsState) => {
           state.status.adminGetAdvert = LOADING;
        },
        adminGetAdvertSuccess: (state: AdvertsState, action: PayloadAction<AdminGetAdvertResponse>) => {
           state.status.adminGetAdvert = SUCCESS;
           state.adminAdvert = action.payload;
        },
        adminGetAdvertFailure: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.adminGetAdvert = FAILED;
        },

        createAdvertStart: (state: AdvertsState) => {
           state.status.createAdvert = LOADING;
        },
        createAdvertSuccess: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.createAdvert = SUCCESS;
        },
        createAdvertFailure: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.createAdvert = FAILED;
        },

        updateAdvertStart: (state: AdvertsState) => {
           state.status.updateAdvert = LOADING;
        },
        updateAdvertSuccess: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.updateAdvert = SUCCESS;
        },
        updateAdvertFailure: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.updateAdvert = FAILED;
        },

        deleteAdvertStart: (state: AdvertsState) => {
           state.status.deleteAdvert = LOADING;
        },
        deleteAdvertSuccess: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.deleteAdvert = SUCCESS;
        },
        deleteAdvertFailure: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.deleteAdvert = FAILED;
        },

        getAdvertsStart: (state: AdvertsState) => {
           state.status.getAdverts = LOADING;
        },
        getAdvertsSuccess: (state: AdvertsState, action: PayloadAction<GetAdvertsResponse>) => {
           state.status.getAdverts = SUCCESS;
           const { adverts, ...pagination } = action.payload;
           state.adverts = adverts;
           state.getAdvertsParams = pagination;
        },
        getAdvertsFailure: (state: AdvertsState, action: PayloadAction<any>) => {
           state.status.getAdverts = FAILED;
        },

        cleanUpAdminAdvert: (state: AdvertsState) => {
            state.adminAdvert = advertsInitialState.adminAdvert;
            state.status.adminGetAdvert = advertsInitialState.status.adminGetAdvert;
        },
        cleanUpAdvertsStatus: (state: AdvertsState) => {
            state.status = advertsInitialState.status;
        },
        cleanUpAdminAdvertsTable: (state: AdvertsState) => {
            state.adminGetAdvertsParams = advertsInitialState.adminGetAdvertsParams;
            state.adminAdverts = advertsInitialState.adminAdverts;
            state.status.adminGetAdverts = advertsInitialState.status.adminGetAdverts;
        },
        cleanUpAdverts: (state: AdvertsState) => {
            state.adverts = advertsInitialState.adverts;
            state.getAdvertsParams = advertsInitialState.getAdvertsParams;
            state.status.getAdverts = advertsInitialState.status.getAdverts;
        }
    }
});

export default globalAdvertsSlice;

export const {
    adminGetAdvertsStart,
    adminGetAdvertsSuccess,
    adminGetAdvertsFailure,

    adminGetAdvertStart,
    adminGetAdvertSuccess,
    adminGetAdvertFailure,

    createAdvertStart,
    createAdvertSuccess,
    createAdvertFailure,

    updateAdvertStart,
    updateAdvertSuccess,
    updateAdvertFailure,

    deleteAdvertStart,
    deleteAdvertSuccess,
    deleteAdvertFailure,

    getAdvertsStart,
    getAdvertsSuccess,
    getAdvertsFailure,

    cleanUpAdminAdvert,
    cleanUpAdminAdvertsTable,
    cleanUpAdverts,
    cleanUpAdvertsStatus
} = globalAdvertsSlice.actions;