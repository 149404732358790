import { MessageForGetCurrentMessagesResponse } from 'App/api/endpoints/maintenanceMessages/responses';
import { StatusType } from 'App/types/requestStatus';

const { INITIAL } = StatusType;

export interface MaintenanceMessagesState {
    status: {
        getCurrentMessages: StatusType,
    };

    currentMessages: MessageForGetCurrentMessagesResponse[]
}

export const maintenanceMessagesInitialState: MaintenanceMessagesState = {
    status: {
        getCurrentMessages: INITIAL,
    },

    currentMessages: []
};