export enum EventCategory {
    // Rajdy
    Rally = 1,
    Kjs = 13,
    Rallysprint = 14,
    SuperStage = 15,
    SuperSprint = 24,
    Offroad = 5,
    RSMP = 25,
    HRSMP = 26,
    RMPZ = 27,
    RSMSL = 28,
    RSMDSL = 37,
    RO = 29,
    SKJS = 30,
    Wrc = 47,

    // Wyścigi
    RaceFlat = 2,
    RaceMountain = 16,
    Rallycross = 4,
    WreckRace = 17,
    TimeAttack = 18,
    TrackDay = 19,
    DragRace = 20,
    Autocross = 31,
    Sprint = 38,

    // Drift
    DriftOther = 3,
    DriftCompPro = 48,
    DriftCompAm = 49,
    DriftTraining = 50,

    // RC
    RcIndoorRace = 8,
    RcDrift = 40,
    RcTrial = 41,
    RcOffroad = 42,
    RcRally = 43,
    RcShow = 44,
    
    // Inne
    Fair = 9,
    Training = 21,
    Schooling = 22,
    FreeRide = 23,
    Charity = 39,
    Integration = 45,
    Exhibition = 46,

    // Zloty i spoty
    Jamboree = 6,
    Meetup = 7,
    Classic = 32,
    Oldtimer = 33,
    American = 34,
    Stance = 35,
    Tuning = 36,
    Bmw = 10,
    Vw = 11,
    Jdm = 12,
}