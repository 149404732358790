import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Button, Col, notification, Row, Typography } from 'antd';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { cleanUpEventsStatus } from 'App/globalState/events/events.global.slice';
import { confirmEvent } from 'App/globalState/events/events.global.thunk';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

const { Title } = Typography;
interface RouteParams {
	eventId: string;
	creationDate: string;
}

interface ConfirmEventContainerProps extends RouteComponentProps<RouteParams> {}

export const ConfirmEventContainer = ({ match }: ConfirmEventContainerProps) => {
    const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.global.events.status.confirmEvent);

	const { t } = useTranslation('page');
    const { eventId, creationDate } = match.params;

	useEffect(() => {
		if (status === StatusType.INITIAL) {
			let handleSuccess: () => void = () => {
				notification.success({
					message: t('common:Success.Success'),
					description: t('EventPages.ConfirmEventContainer.ConfirmSuccess'),
				});
			};

			dispatch(confirmEvent({ eventId, creationDate: creationDate }, handleSuccess));
		}
		return () => {
			cleanUpEventsStatus();
		};
	});

	return status === StatusType.LOADING || status === StatusType.INITIAL ? (
		<LoadingScreen container='screen' />
	) : (
		<>
			{status === StatusType.SUCCESS && (
				<Col className='confirmEmail'>
					<Row justify='center' align='middle'>
						<CheckOutlined className='confirmIconSuccess' />
					</Row>
					<Row justify='center' align='middle'>
						<Title className='text-center' level={4}>
							{t('EventPages.ConfirmEventContainer.EventConfirmed')}
						</Title>
					</Row>
                    <Row justify='center' align='middle'>
						{t('EventPages.ConfirmEventContainer.Info')}
					</Row>
				</Col>
			)}
			{status === StatusType.FAILED && (
				<Col className='confirmEmail'>
					<Row justify='center' align='middle'>
						<ExclamationOutlined className='confirmIconError' />
					</Row>
					<Row justify='center' align='middle'>
						<Title className='text-center' level={4}>
							{t('EventPages.ConfirmEventContainer.ConfirmError')}
						</Title>
					</Row>
				</Col>
			)}
            <Row justify='center' align='middle'>
                <Link to='/'>
                    <Button size='large' block type='primary'>
                        {t('EventPages.ConfirmEventContainer.BackToMain')}
                    </Button>
                </Link>
            </Row>
		</>
	);
}
