import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Divider, notification, PageHeader, Popconfirm, Result, Row } from 'antd';
import { UpdateAccountDetailsRequest } from 'App/api/endpoints/account/requests/updateAccountDetailsRequest';
import LoadingScreen from 'App/common/components/LoadingScreen';
import { getAccountDetails } from 'App/globalState/account/account.global.thunk';
import { logout } from 'App/globalState/auth/auth.global.thunk';
import { RootState } from 'App/globalState/root.reducer';
import StatusType from 'App/types/requestStatus';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { updateAccountDetails, deleteAccount } from '../../state/account.thunk';
import EditProfileForm from './forms/EditProfileForm';

const EditProfileContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['page', 'common']);
	const history = useHistory();

	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);
	const updateAccountStatus = useSelector((state: RootState) => state.pages.account.status.updateAccountDetails);
	const getAccountDetailsStatus = useSelector((state: RootState) => state.global.account.status.getAccountDetails);
	const deleteAccountStatus = useSelector((state: RootState) => state.pages.account.status.deleteAccount);

	useEffect(() => {
		if (!accountDetails) dispatch(getAccountDetails());
	}, [dispatch, accountDetails]);

	const onFinish = (values: UpdateAccountDetailsRequest) => {
		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.UpdateAccountDetailsSuccess'),
			});
		};
		dispatch(updateAccountDetails(values, onSuccess));
	};

	const deleteAccountHandler = () => {
		const onDeleteAccountSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPages.DeleteAccountSuccess'),
			});

			let onLogoutSuccess = () => {
				history.push('/');
			};

			dispatch(logout(onLogoutSuccess));
		};
		dispatch(
			deleteAccount(
				{ ...(accountDetails as UpdateAccountDetailsRequest), isDeleted: true },
				onDeleteAccountSuccess
			)
		);
	};

	if (accountDetails) {
		return (
			<>
				<Row justify='center'>
					<Col span={24}>
						<Row justify='center'>
							<Col>
								<PageHeader className='p-3' title={t('AccountPages.EditProfilePageTitle')} />
							</Col>
						</Row>
						<Row justify='center'>
							<Col xs={24} md={20} lg={10} xl={8} xxl={6}>
								<EditProfileForm
									name='editProfileForm'
									size='middle'
									onFinish={onFinish}
									initialValues={accountDetails}
									loading={updateAccountStatus === StatusType.LOADING}
								/>
							</Col>
						</Row>
						<Divider />
					</Col>
				</Row>
				<Row justify='center'>
					<Col>
						<Popconfirm
							title={t('common:Warnings.ActionWillBeIrreversible')}
							icon={<ExclamationCircleOutlined style={{ color: 'red' }} />}
							onConfirm={deleteAccountHandler}
						>
							<Button loading={deleteAccountStatus === StatusType.LOADING} danger>
								{t('AccountPages.DeleteAccount')}
							</Button>
						</Popconfirm>
					</Col>
				</Row>
			</>
		);
	} else {
		if (getAccountDetailsStatus === StatusType.LOADING) {
			return <LoadingScreen container='fill' />;
		} else {
			return (
				<Result
					status='warning'
					title={t('common:Errors.AnErrorOccured')}
					extra={
						<Button type='primary' onClick={() => history.push('/')}>
							{t('common:Actions.BackToHome')}
						</Button>
					}
				/>
			);
		}
	}
};

export default EditProfileContainer;
