import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Modal, notification } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { paginationUtils } from 'App/common/utils/pagination.utils';
import EventsTable from './components/EventsTable';
import { getAdminEvents, updateEventStatus } from 'App/globalState/events/events.global.thunk';
import { cleanUpAdminEventsTable } from 'App/globalState/events/events.global.slice';
import { EventForGetAdminEventsResponse } from 'App/api/endpoints/events/responses';
import { EventStatus } from 'App/common/enums';

const { LOADING } = StatusType;
const { defaultPaginationValues } = paginationUtils;
const EventsTableContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

    const adminEvents = useSelector((state: RootState) => state.global.events.adminEvents);
    const eventsStatus = useSelector((state: RootState) => state.global.events.status);
    const getAdminEventsParams = useSelector((state: RootState) => state.global.events.getAdminEventsParams);

	useEffect(() => {
		// pierwsze uruchomienie z domyślnie ustawionymi filtrami i orderby
		dispatch(
			getAdminEvents({
				...defaultPaginationValues,
				...getAdminEventsParams,
			})
		);

		return () => {
			dispatch(cleanUpAdminEventsTable());
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const onDeleteEvent = (eventToDelete: EventForGetAdminEventsResponse) => {
		Modal.confirm({
			title: `${t('AdminPages.EventsPages.ConfirmEventDeletionTitle')} ${eventToDelete?.title} (${eventToDelete?.organizer})?`,
			icon: <ExclamationCircleOutlined />,
			content: t('common:Warnings.ActionWillBeIrreversible'),
			okText: t('common:Actions.Yes'),
			okType: 'primary',
			cancelText: t('common:Actions.No'),
			onOk() {
				const onSucces = () => {
					notification.success({
						message: t('common:Success.Success'),
						description: t('AdminPages.EventsPages.SuccessDeleteDescription'),
					});
					dispatch(getAdminEvents(getAdminEventsParams));
				};
				dispatch(updateEventStatus(eventToDelete.id, { status: EventStatus.Deleted }, onSucces));
			},
		});
	};

	return (
		<>
			<Row>
				<Col span={24}>
					<Link to='/admin/events/create'>
						<Button icon={<PlusOutlined />}>{t('AdminPages.EventsPages.NewEventButton')}</Button>
					</Link>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<EventsTable
						dataSource={adminEvents}
						loading={eventsStatus.getAdminEvents === LOADING}
						requestPagination={getAdminEventsParams}
						deleteEvent={onDeleteEvent}
						getEvents={getAdminEvents}
					/>
				</Col>
			</Row>
		</>
	);
};

export default EventsTableContainer;
