import React from 'react';
import { Route } from 'react-router';
import HomeContainer from './views/home/HomeContainer';
import EventContainer from './views/event/EventContainer';
import EventSubmittedContainer from './views/eventSubmitted/EventSubmittedContainer';
import { ContactContainer } from './views/contact/ContactContainer';
import { SubmitEventContainer } from './views/submitEvent/SubmitEventContainer';
import { ConfirmEventContainer } from './views/confirmEvent/ConfirmEventContainer';
import { TermsOfServiceContainer } from './views/termsOfService/TermsOfServiceContainer';
import { PrivacyPolicyContainer } from './views/privacyPolicy/PrivacyPolicyContainer';

const HomePages = [
    <Route key='/' exact path='/' component={HomeContainer} />,
    
    <Route key='/dodaj-wydarzenie' exact path='/dodaj-wydarzenie' component={SubmitEventContainer} />,
    <Route key='/submit' exact path='/submit' component={SubmitEventContainer} />,

    <Route key='/submitted' exact path='/submitted' component={EventSubmittedContainer} />,

    <Route key='/contact' exact path='/contact' component={ContactContainer} />,
    <Route key='/kontakt' exact path='/kontakt' component={ContactContainer} />,

    <Route key='/event' exact path='/event/:eventId' component={EventContainer} /> ,
    <Route key='/events/confirm' exact path='/events/confirm/:eventId/:creationDate' component={ConfirmEventContainer} />,

    <Route key='/terms-of-service' path='/terms-of-service' component={TermsOfServiceContainer} />,
    <Route key='/privacy-policy' path='/privacy-policy' component={PrivacyPolicyContainer} />
];

export default HomePages;
