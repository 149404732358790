import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Row, Col, notification, PageHeader, UploadProps } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { UploadFile } from 'antd/lib/upload/interface';
import { UpdateAdvertRequest } from 'App/api/endpoints/adverts/requests';
import { adminGetAdvert, updateAdvert } from 'App/globalState/adverts/adverts.global.thunk';
import { cleanUpAdminAdvert, cleanUpAdminAdvertsTable, cleanUpAdvertsStatus } from 'App/globalState/adverts/adverts.global.slice';
import UpdateAdvertForm from './formss/updateAdvertForm/UpdateAdvertForm';

const { LOADING } = StatusType;

interface RouteParams {
	advertId: string;
}

interface UpdateAdvertContainerProps extends RouteComponentProps<RouteParams> {}

export const UpdateAdvertContainer = ({ match }: UpdateAdvertContainerProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation(['page', 'common']);

	const advertId = match.params.advertId;
    const advert = useSelector((state: RootState) => state.global.adverts.adminAdvert);
    const advertStatus = useSelector((state: RootState) => state.global.adverts.status);

    const [pictureFile, setPictureFile] = useState<UploadFile>(null);

    const isFileImage = (file: UploadFile): boolean => {
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
        
        if (!isImage) {
            notification.error({
				message: t('common:Errors.Error'),
				description: t('AdminPages.EventsPages.FileIsNotImage'),
			});
        }

        return isImage;
    }

    const uploadPictureProps: UploadProps = {
        name: 'pictureFile',
        multiple: false,
        maxCount: 1,
        showUploadList: pictureFile != null,
        accept: "image/png, image/jpeg",
		fileList: pictureFile ? [ pictureFile ] : [],
        onRemove: (file) => {
            setPictureFile(null);
		},
		beforeUpload: (file : UploadFile) => {
            if(isFileImage(file))
                setPictureFile(file);
            else
                setPictureFile(null);

			return false;
		}
    };
      
	const handleFormSubmit = (values: UpdateAdvertRequest) => {
        values.pictureFile = pictureFile;

		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.AdvertsPages.SuccessUpdateDescription'),
			});

            history.push('/admin/adverts');
		};
		dispatch(updateAdvert(advertId, values, onSuccess));
	};

	useEffect(() => {
		if (!advert) {
			dispatch(adminGetAdvert(advertId));
		}
	}, [dispatch, advert, advertId]);

	useEffect(() => {
		return () => {
			dispatch(cleanUpAdvertsStatus());
			dispatch(cleanUpAdminAdvert());
			dispatch(cleanUpAdminAdvertsTable());
		};
	}, [dispatch]);

	return (
		<React.Fragment>
			<Row className='mb-5'>
				<Col>
					<Button
						style={{ marginLeft: 16 }}
						block
						onClick={() => history.push('/admin/adverts')}
						icon={<ArrowLeftOutlined />}
					>
						{t('common:Actions.GoBack')}
					</Button>
				</Col>
			</Row>
			<Row justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.AdvertsPages.UpdatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<UpdateAdvertForm
								initialValues={advert}
                                loading={advertStatus.updateAdvert === LOADING}
                                onFinish={handleFormSubmit}
                                advertUploadProps={uploadPictureProps}
                            />
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default UpdateAdvertContainer;
