import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface ForbbidenContainerProps extends RouteComponentProps {}

const ForbiddenContainer = ({ history }: ForbbidenContainerProps) => {
	const buttonGoBackHomeOnClick = () => history.push('/');
	const { t } = useTranslation(['page', 'common']);

	return (
		<Result
			status='403'
			title='403'
			subTitle={t('ResultsPages.ForbiddenWeAreSorry')}
			extra={
				<Button type='primary' onClick={buttonGoBackHomeOnClick}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		></Result>
	);
};

export default ForbiddenContainer;
