import { Input, Button, Form } from 'antd';
import { FormProps } from 'antd/lib/form';
import { formRules } from 'App/common/formRules/formRules';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EditProfileFormProps extends FormProps {
	loading: boolean;
}

const EditProfileForm = (props: EditProfileFormProps) => {
	const { t } = useTranslation(['form', 'common']);
	const { loading, ...rest } = props;
	const { firstName, lastName, phoneNumber } = formRules.user;
	return (
		<Form layout='vertical' className='px-3' {...rest}>
			<Form.Item
				name='firstName'
				label={t('models:User.Labels.FirstName')}
				rules={firstName()}
				messageVariables={{ arg: t('models:User.Labels.FirstName') }}
			>
				<Input placeholder={t('models:User.Placeholders.FirstName')} />
			</Form.Item>
			<Form.Item
				name='lastName'
				label={t('models:User.Labels.LastName')}
				rules={lastName()}
				messageVariables={{ arg: t('models:User.Labels.LastName') }}
			>
				<Input placeholder={t('models:User.Placeholders.LastName')} />
			</Form.Item>
			<Form.Item
				name='phoneNumber'
				messageVariables={{ arg: t('models:User.Labels.PhoneNumber') }}
				label={t('models:User.Labels.PhoneNumber')}
				rules={phoneNumber()}
			>
				<Input placeholder={t('models:User.Placeholders.PhoneNumber')} />
			</Form.Item>
			<Form.Item>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default EditProfileForm;
