import { requests } from '../../agent/agent';
import { HttpStatusCodeResponse } from 'App/types/httpResponse.d';

import appConfig from 'app.config';
import { CreateMessageRequest, GetMessagesRequest, UpdateMessageRequest } from './requests';
import { GetMessageResponse, CreateMessageResponse, UpdateMessageResponse, GetMessagesResponse, GetCurrentMessagesResponse } from './responses';

const { baseUrlToIncludeInEmail, confirmEmail } = appConfig.urls.frontend;

export const MaintenanceMessagesApi = {
    getMessages: (body: GetMessagesRequest): Promise<GetMessagesResponse> => requests.get(`/maintenance/messages`, body),

    getMessage: (messageId: number): Promise<GetMessageResponse> => requests.get(`/maintenance/messages/${messageId}`),

    getCurrentMessages: (): Promise<GetCurrentMessagesResponse> => requests.get(`/maintenance/messages/current`),

    createMessage: (body: CreateMessageRequest): Promise<CreateMessageResponse> =>
        requests.post(`/maintenance/messages`, { ...body, urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${confirmEmail}` }),

    updateMessage: (messageId: number, body: UpdateMessageRequest): Promise<UpdateMessageResponse> =>
        requests.put(`/maintenance/messages/${messageId}`, body),

    deleteMessage: (messageId: number): Promise<HttpStatusCodeResponse> => requests.delete(`/maintenance/messages/${messageId}`)
};
