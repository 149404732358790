import StatusType from "App/types/requestStatus"

const { INITIAL } = StatusType;

export interface AlterLayoutState {
    status: {
        setHideGlobalNavbar: StatusType,
    };

    hideGlobalNavbar: boolean;
}

export const alterLayoutInitialState: AlterLayoutState = {
    status: {
        setHideGlobalNavbar: INITIAL,
    },

    hideGlobalNavbar: false,
}