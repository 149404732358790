import React, { ReactNode, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetAdminEventResponse } from 'App/api/endpoints/events/responses';
import EventGeneralTab from './eventGeneralTab/EventGeneralTab';
import EventSystemTab from './eventGeneralTab/EventSystemTab';
import EventGraphicsTab from './eventGeneralTab/EventGraphicsTab';
import EventLocationTab from './eventGeneralTab/EventLocationTab';

interface GetEventTabsProps {
	event: GetAdminEventResponse;
}

export const GetEventTabs = ({ event }: GetEventTabsProps) => {
	interface AvailableTab {
		key: string;
		content: ReactNode;
		tab: string;
	}

	const { t } = useTranslation('page');

	const availableTabs = [
		{
			key: 'k1',
			tab: t('AdminPages.EventsPages.TabGeneral'),
			content: <EventGeneralTab event={event} />,
		},
		{
			key: 'k2',
			tab: t('AdminPages.EventsPages.TabSystem'),
			content: <EventSystemTab event={event} />,
		},
		{
			key: 'k3',
			tab: t('AdminPages.EventsPages.TabGraphics'),
			content: <EventGraphicsTab event={event} />,
		},
		{
			key: 'k4',
			tab: t('AdminPages.EventsPages.TabLocation'),
			content: <EventLocationTab event={event} />,
		},
	] as AvailableTab[];

	const [state, setState] = useState<AvailableTab>(availableTabs.find((a) => a.key === 'k1'));

	const onTabChange = (key: string) => {
		setState(availableTabs.find((a) => a.key === key));
	};

	return (
		<Row
			style={{
				marginTop: 16,
			}}
			justify='center'
		>
			<Col xs={23} md={18} xl={14}>
				<Card
					tabList={availableTabs}
					activeTabKey={state.key}
					onTabChange={(key) => {
						onTabChange(key);
					}}
				>
					{state.content}
				</Card>
			</Col>
		</Row>
	);
};
