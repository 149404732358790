import { GetAdminEventsRequest, GetEventsRequest, GetPromotedEventsRequest } from "App/api/endpoints/events/requests";
import { EventForGetAdminEventsResponse, EventForGetEventsResponse, EventForGetPromotedEventsResponse, GetAdminEventResponse, GetEventResponse, GetEventsCountriesResponse, UpdateEventResponse } from "App/api/endpoints/events/responses";
import { EventStatus } from "App/common/enums";
import { paginationUtils } from "App/common/utils/pagination.utils";
import StatusType from "App/types/requestStatus"

const { INITIAL } = StatusType;
const { defaultPaginationValues } = paginationUtils;

export interface EventsState {
    status: {
        getAdminEvents: StatusType,
        getAdminEvent: StatusType,
        adminCreateEvent: StatusType,
        getEvents: StatusType,
        getRecentlyAddedEvents: StatusType,
        getPromotedEvents: StatusType,
        getEventsCountries: StatusType,
        getEvent: StatusType,
        createEvent: StatusType,
        confirmEvent: StatusType,
        updateEvent: StatusType,
        updateEventStatus: StatusType,
    };

    event: GetEventResponse;
    events: EventForGetEventsResponse[];
    getEventsParams: GetEventsRequest;

    recentlyAddedEvents: EventForGetEventsResponse[];
    getRecentlyAddedEvents: GetEventsRequest;

    promotedEvents: EventForGetPromotedEventsResponse[];
    getPromotedEventsParams: GetPromotedEventsRequest;

    adminEvent: GetAdminEventResponse;
    adminEvents: EventForGetAdminEventsResponse[];
    getAdminEventsParams: GetAdminEventsRequest;

    updateEvent: UpdateEventResponse;
    eventsCountries: GetEventsCountriesResponse;
}

export const eventsInitialState: EventsState = {
    status: {
        getAdminEvents: INITIAL,
        getAdminEvent: INITIAL,
        adminCreateEvent: INITIAL,
        getEvents: INITIAL,
        getRecentlyAddedEvents: INITIAL,
        getPromotedEvents: INITIAL,
        getEventsCountries: INITIAL,
        getEvent: INITIAL,
        createEvent: INITIAL,
        confirmEvent: INITIAL,
        updateEvent: INITIAL,
        updateEventStatus: INITIAL,
    },

    event: null,
    events: [],
    getEventsParams: { 
        ...defaultPaginationValues,
        orderBy: [
            {
                field: "dateStart",
                direction: "Ascending",
            }
        ],
        coorditanes: null, 
        rangeInKilometers: 0 
    },

    recentlyAddedEvents: [],
    getRecentlyAddedEvents: { 
        ...defaultPaginationValues,
        pageSize: 9,
        orderBy: [
            {
                field: "dateCreated",
                direction: "Descending",
            }
        ],
        coorditanes: null, 
        rangeInKilometers: 0 
    },

    promotedEvents: [],
    getPromotedEventsParams: {
        ...defaultPaginationValues,
        pageSize: 9,
        orderBy: [
            {
                field: "dateStartUTC",
                direction: "Ascending"
            }
        ]
    },

    adminEvent: null,
    adminEvents: [],
    getAdminEventsParams: { 
        ...defaultPaginationValues,
        orderBy: [
            {
                field: "dateCreated",
                direction: "Descending",
            }
        ],
        filters: [
            {
                field: "status",
                values: [
                    EventStatus[EventStatus.Waiting],
                    EventStatus[EventStatus.Verified]
                ]
            }
        ],
        coorditanes: null, 
        rangeInKilometers: 0 
    },

    updateEvent: null,
    eventsCountries: null,
}