import { Col, notification, Row } from 'antd';
import PageLabel from 'App/common/components/PageLabel/PageLabel';
import React, { useEffect, useRef } from 'react'
import { MemberCard } from './memberCard/MemberCard';
import { useTranslation } from 'react-i18next';
import ContactForm from './contactForm/ContactForm';
import { SubmitContactFormRequest } from 'App/api/endpoints/contact/requests';
import { submitContactForm } from 'App/globalState/contact/contact.global.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App/globalState/root.reducer';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import StatusType from 'App/types/requestStatus';
import { Helmet } from "react-helmet-async";

import Antos from 'App/common/assets/profiles/Antos.jpg';
import Kujawa from 'App/common/assets/profiles/Kujawa.jpg';
import WrapDirect from 'App/common/assets/profiles/WrapDirect.jpg';

import './ContactContainer.less';
import { useLocation } from 'react-router';

export const ContactContainer = () => {
	const { t } = useTranslation('page');
	const { executeRecaptcha } = useGoogleReCaptcha();
	const dispatch = useDispatch();
	const location = useLocation();
	const contactFormRef = useRef(null);
    
	let contactStatus = useSelector((state: RootState) => state.global.contact.status.submitContactForm);

    useEffect(()=> {
		if (location.hash) {
			if(location.hash.slice(1) === 'message-us' ||
			   location.hash.slice(1) === 'napisz-do-nas' ) {
				smoothScrollTo(contactFormRef.current, -64);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location,]);

    const smoothScrollTo = (elem: any, offset?: number) => {
		if(!elem) return;

		const y = elem.getBoundingClientRect().top + window.pageYOffset + offset;
		window.scrollTo({ top: y, behavior: 'smooth' });
	}

    const onMessage = async (params: SubmitContactFormRequest) => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available');
			return;
		}

        const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.EventsPages.SuccessCreateDescription'),
			});
		};

		params.token = await executeRecaptcha('contactSubmit');
		dispatch(submitContactForm(params, onSuccess));
    }

    return (
        <div className='contact-container'>
            <Helmet>
                <title id='contact'>{t('Navbar.Contact')} - Wydarzenia Motoryzacyjne</title>
                
                {/* META Props */}
                <meta id='contact' name="description" content="Skontaktuj się z nami" />

                {/* OG Props */}
                <meta id='contact' property="og:description" content="Skontaktuj się z nami" />
                <meta id='contact' property="og:url" content="https://kalendarz-motoryzacyjny.pl/kontakt" />
            </Helmet>

            <Row className='contact-container__content'>
                {t('HomePages.Contact.Content.One')}
                <br/><br/>
                {t('HomePages.Contact.Content.Two')}
            </Row>

            <PageLabel message={`${t('HomePages.Contact.WhoAreWe')}:`} />

            <Row className='contact-container__team'>
                <Col xs={24} xl={8}>
                    <MemberCard 
                        image={Kujawa} 
                        name={t('HomePages.Contact.Members.AgnieszkaKujawa.Name')}
                        description={t('HomePages.Contact.Members.AgnieszkaKujawa.Description')}

                        facebook='https://www.facebook.com/Agniess.plAK'
                        instagram='https://www.instagram.com/agniess.pl'
                        website='http://www.agniess.pl/'
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <MemberCard
                        image={Antos} 
                        name={t('HomePages.Contact.Members.KacperAntos.Name')}
                        description={t('HomePages.Contact.Members.KacperAntos.Description')}

                        facebook='https://www.facebook.com/kacperantos51'
                        instagram='https://www.instagram.com/kacperantos51'
                        youtube='https://www.youtube.com/@kacperantos51'
                    />
                </Col>
                <Col xs={24} xl={8}>
                    <MemberCard 
                        image={WrapDirect} 
                        name={t('HomePages.Contact.Members.WrapDirect.Name')}
                        description={t('HomePages.Contact.Members.WrapDirect.Description')}

                        instagram='https://www.instagram.com/wrap.direct'
                        website='https://wrap.direct'
                    />
                </Col>
            </Row>

            <div id="message-us" ref={contactFormRef}>
                <ContactForm onFinish={onMessage} loading={contactStatus === StatusType.LOADING} />
            </div>
        </div>
    )
}
