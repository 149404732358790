import { requests } from '../../agent/agent';
import { GetUsersRequest, UpdateUserRequest, CreateUserRequest } from './requests';
import { GetUserResponse, CreateUserResponse, GetUsersResponse, UpdateUserResponse } from './responses';
import { HttpStatusCodeResponse } from 'App/types/httpResponse.d';

import appConfig from 'app.config';

const { baseUrlToIncludeInEmail, confirmEmail } = appConfig.urls.frontend;

export const UsersApi = {
	getUsers: (body: GetUsersRequest): Promise<GetUsersResponse> => requests.post(`/users/search`, body),

	getUser: (userId: string): Promise<GetUserResponse> => requests.get(`/users/${userId}`),

	createUser: (body: CreateUserRequest): Promise<CreateUserResponse> =>
		requests.post(`/users`, { ...body, urlToIncludeInEmail: `${baseUrlToIncludeInEmail}${confirmEmail}` }),

	updateUser: (userId: string, body: UpdateUserRequest): Promise<UpdateUserResponse> =>
		requests.put(`/users/${userId}`, body),

	deleteUser: (userId: string): Promise<HttpStatusCodeResponse> => requests.delete(`/users/${userId}`),
};
