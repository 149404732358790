import { MenuOutlined } from '@ant-design/icons';
import { Col, Menu, Row } from 'antd';
import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Default } from '../Responsive/Default';
import { Mobile } from '../Responsive/Mobile';
import AccountSubMenu from './AccountSubMenu';
import HomeMenuItem from './HomeMenuItem';

interface UserNavbarProps {
	accountDetails: GetAccountDetailsResponse;
}

const UserNavbar = ({ accountDetails }: UserNavbarProps) => {
	const location = useLocation();
	return (
		<>
			<Mobile>
				<Row>
					<Col span={16}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-mobile' />
						</Menu>
					</Col>
					<Col span={8}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							<Menu.SubMenu
								key='user-mobile'
								className='float-right'
								icon={<MenuOutlined className='mr-0' />}
							>
								<Menu.Divider />
								<AccountSubMenu key='mobile-account' isMobile={true} accountDetails={accountDetails} />
							</Menu.SubMenu>
						</Menu>
					</Col>
				</Row>
			</Mobile>

			<Default>
				<Row justify='space-between'>
					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-start'>
							<HomeMenuItem key='home-default' />
						</Menu>
					</Col>

					<Col span={12}>
						<Menu mode='horizontal' selectedKeys={[location.pathname]} className='justify-content-end'>
							<AccountSubMenu key='default-account' isMobile={false} accountDetails={accountDetails} />
						</Menu>
					</Col>
				</Row>
			</Default>
		</>
	);
};

export default UserNavbar;
