import React from 'react';
import { Modal } from 'antd';
import MessageForm from '../../forms/MessageForm';
import { MessageFormValues } from '../../forms/utils/messageFormValues';
import { useTranslation } from 'react-i18next';

export interface CreateMessageModalModalProps {
	showModal: boolean;
	loading: boolean;
	onOk: (values: MessageFormValues) => void;
	onCancel: Function;
}

const CreateMessageModal = ({ onCancel, onOk, showModal, loading }: CreateMessageModalModalProps) => {
	const { t } = useTranslation();

	return (
		<Modal
			title={t('AdminPages.MaintenanceMessagesPages.CreateMessageModalTitle')}
			visible={showModal}
			footer={null}
			onCancel={() => onCancel()}
			maskClosable={false}
			destroyOnClose
		>
			<MessageForm loading={loading} onFinish={onOk} />
		</Modal>
	);
};

export default CreateMessageModal;
