import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import ForbiddenContainer from './views/forbidden/ForbiddenContainer';
import InternalServerErrorContainer from './views/internalServerError/InternalServerErrorContainer';
import NotFoundContainer from './views/notFound/NotFoundContainer';

const ResultPages = [
	<Route key='/403' path='/403' component={ForbiddenContainer} />,
	<Route key='/404' path='/404' component={NotFoundContainer} />,
	<Route key='/500' path='/500' component={InternalServerErrorContainer} />,
	<Redirect key='redirect' to='/404' />,
];

export default ResultPages;
