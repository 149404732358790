import { GetAccountDetailsResponse } from 'App/api/endpoints/account/responses';
import StatusType from 'App/types/requestStatus';
const { INITIAL } = StatusType;

export interface GlobalAccountState {
	status: {
		getAccountDetails: StatusType;
	};
	accountDetails: GetAccountDetailsResponse | null;
}

export const globalAccountInitialState: GlobalAccountState = {
	status: {
		getAccountDetails: INITIAL,
	},
	accountDetails: null,
};
