import { Col, Row, Typography } from 'antd';
import { GetAdminEventResponse } from 'App/api/endpoints/events/responses';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { allCountries } from 'country-region-data';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";

interface EventLocationTabProps {
	event: GetAdminEventResponse;
}

const EventLocationTab = ({ event }: EventLocationTabProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);

	if (event) {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Country')}:{' '}
							</Typography.Text>
							<Typography.Text>{allCountries.find(c => c[1] === event.country)[0]}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.State')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.state}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.City')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.city}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Street')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.street}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.LocationName')}:{' '}
							</Typography.Text>
							<Typography.Text>{event.locationName}</Typography.Text>
						</Typography>
					</Col>
					<Col span={24}>
						<div className='map-container'>
							<MapContainer 
								center={[event.latitude, event.longitude]} 
								zoom={14}
								scrollWheelZoom={false}
							>
								<TileLayer
									attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
									url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								/>
								<Marker
									position={[event.latitude, event.longitude]} 
									icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}
								>
									<Popup>
										{event.latitude}, {event.longitude}
									</Popup>
								</Marker>
							</MapContainer>
						</div>
					</Col>
				</Row>
			</>
		);
	} else {
		return <></>;
	}
};

export default EventLocationTab;
