import StatusType from "App/types/requestStatus";

const { INITIAL } = StatusType;

export interface ContactState {
    status: {
        submitContactForm: StatusType
    }
}

export const contactInitialState: ContactState = {
    status: {
        submitContactForm: INITIAL
    }
}