import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Row, Col, notification, PageHeader, UploadProps } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import querystring from 'querystring';

import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { adminCreateEvent, getAdminEvent } from 'App/globalState/events/events.global.thunk';
import { cleanUpEventsStatus } from 'App/globalState/events/events.global.slice';
import CreateEventForm from './forms/createEventForm/CreateEventForm';
import { AdminCreateEventRequest } from 'App/api/endpoints/events/requests/adminCreateEventRequest';
import { UploadFile } from 'antd/lib/upload/interface';
import { URLUtils } from 'App/pages/homePages/views/home/utils/URLUtils';

const { LOADING } = StatusType;

export const CreateEventContainer = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { t } = useTranslation(['page', 'common']);

	const eventsStatus = useSelector((state: RootState) => state.global.events.status);
	const duplicateEvent = useSelector((state: RootState) => state.global.events.adminEvent);

    const [bannerFile, setBannerFile] = useState<UploadFile>(null);
    const [posterFile, setPosterFile] = useState<UploadFile>(null);
	const [initialValues, setInitialValues] = useState(null);

    const isFileImage = (file: UploadFile): boolean => {
        const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
        
        if (!isImage) {
            notification.error({
				message: t('common:Errors.Error'),
				description: t('AdminPages.EventsPages.FileIsNotImage'),
			});
        }

        return isImage;
    }

    const uploadBannerProps: UploadProps = {
        name: 'banner',
        multiple: false,
        maxCount: 1,
        showUploadList: bannerFile != null,
        accept: "image/png, image/jpeg",
		fileList: bannerFile ? [ bannerFile ] : [],
        onRemove: (file) => {
            setBannerFile(null);
		},
		beforeUpload: (file : UploadFile) => {
            if(isFileImage(file))
                setBannerFile(file);
            else
                setBannerFile(null);

			return false;
		}
    };

    const uploadPosterProps: UploadProps = {
        name: 'poster',
        multiple: false,
        maxCount: 1,
        showUploadList: bannerFile != null,
        accept: "image/png, image/jpeg",
		fileList: posterFile ? [ posterFile ] : [],
        onRemove: (file) => {
            setPosterFile(null);
		},
		beforeUpload: (file : UploadFile) => {
            if(isFileImage(file))
                setPosterFile(file);
            else
                setPosterFile(null);

			return false;
		}
    };
      
	const handleFormSubmit = (values: AdminCreateEventRequest) => {
        values.banner = bannerFile;
        values.poster = posterFile;

		const onSuccess = () => {
			notification.success({
				message: t('common:Success.Success'),
				description: t('AdminPages.EventsPages.SuccessCreateDescription'),
			});

            history.push('/admin/events');
		};
		dispatch(adminCreateEvent(values, onSuccess));
	};

	useEffect(() => {
		return () => {
			dispatch(cleanUpEventsStatus());
		};
	}, [dispatch]);

	useEffect(()=> {
		if (location.search) {
			const search = querystring.parse(location.search.substring(1));

			if(search.duplicateid) {
				const duplicateId = URLUtils.isArrayOfStrings(search.duplicateid) ? search.duplicateid[0] : search.duplicateid as string;
				dispatch(getAdminEvent(duplicateId));
			}
		} 
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location,]);

	useEffect(() => {
		if(duplicateEvent) {
			console.warn("NEW DUPLICATE", duplicateEvent);
			setInitialValues({
				...duplicateEvent,
				banner: bannerFile,
				poster: posterFile
			} as AdminCreateEventRequest);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duplicateEvent])

	return (
		<React.Fragment>
			<Row className='mb-5'>
				<Col>
					<Button
						style={{ marginLeft: 16 }}
						block
						onClick={() => history.push('/admin/events')}
						icon={<ArrowLeftOutlined />}
					>
						{t('common:Actions.GoBack')}
					</Button>
				</Col>
			</Row>
			<Row justify='center'>
				<Col span={24}>
					<Row justify='center'>
						<Col>
							<PageHeader title={t('AdminPages.EventsPages.CreatePageHeaderTitle')} />
						</Col>
					</Row>
					<Row justify='center'>
						<Col xs={24} md={20} lg={15} xl={10} xxl={8}>
							<CreateEventForm
                                loading={eventsStatus.adminCreateEvent === LOADING}
                                onFinish={handleFormSubmit}
								initialValues={initialValues}
                                bannerUploadProps={uploadBannerProps}
                                posterUploadProps={uploadPosterProps}
                            />
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default CreateEventContainer;
