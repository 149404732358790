import React from 'react';
import { Route, Switch } from 'react-router';
import CreateEventContainer from './views/createEvent/CreateEventContainer';
import EventDetailsContainer from './views/eventDetails/EventDetailsContainer';
import EventsTableContainer from './views/eventsTable/EventsTableContainer';
import UpdateEventContainer from './views/updateEvent/UpdateEventContainer';

const AdminEventPages = () => {
	return (
		<Switch>
			<Route exact path='/admin/events' component={EventsTableContainer} />
            <Route exact path='/admin/events/create' component={CreateEventContainer} />
            <Route exact path='/admin/events/:eventId' component={EventDetailsContainer} /> 
			<Route exact path='/admin/events/:eventId/update' component={UpdateEventContainer} />
		</Switch>
	);
};

export default AdminEventPages;
