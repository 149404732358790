import { AdminGetAdvertsRequest, GetAdvertsRequest } from "App/api/endpoints/adverts/requests";
import { AdminGetAdvertResponse, AdvertForAdminGetAdvertsResponse, AdvertForGetAdvertsResponse } from "App/api/endpoints/adverts/responses";
import { paginationUtils } from "App/common/utils/pagination.utils";
import StatusType from "App/types/requestStatus";

const { INITIAL } = StatusType;
const { defaultPaginationValues } = paginationUtils;

export interface AdvertsState {
    status: {
        adminGetAdverts: StatusType,
        adminGetAdvert: StatusType,
        createAdvert: StatusType,
        updateAdvert: StatusType,
        deleteAdvert: StatusType,
        getAdverts: StatusType
    };

    adminAdvert: AdminGetAdvertResponse;
    adminAdverts: AdvertForAdminGetAdvertsResponse[];
    adminGetAdvertsParams: AdminGetAdvertsRequest;

    adverts: AdvertForGetAdvertsResponse[];
    getAdvertsParams: GetAdvertsRequest;
}

export const advertsInitialState: AdvertsState = {
    status: {
        adminGetAdverts: INITIAL,
        adminGetAdvert: INITIAL,
        createAdvert: INITIAL,
        updateAdvert: INITIAL,
        deleteAdvert: INITIAL,
        getAdverts: INITIAL
    },

    adminAdvert: null,
    adminAdverts: [],
    adminGetAdvertsParams: {
        ...defaultPaginationValues,
        orderBy: [
            {
                field: 'LatestChange',
                direction: 'Descending'
            }
        ]
    },

    adverts: [],
    getAdvertsParams: {
        ...defaultPaginationValues,
        orderBy: [
            {
                field: 'LatestChange',
                direction: 'Descending'
            }
        ]
    }
}