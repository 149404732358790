import { EventCategory } from "../enums";

export const key_Rallies = 'rallies';
export const key_Races = 'races';
export const key_Drift = 'drift';
export const key_RcModels = 'rcModels';
export const key_Others = 'others';
export const key_JamboreesAndSpots = 'jamboreesAndSpots';

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
}

function getKeyByValue(value: number) {
    const indexOfS = Object.values(EventCategory).indexOf(value as unknown as EventCategory);
    const key = Object.keys(EventCategory)[indexOfS];
    return key;
}

function createOption(category: EventCategory, t) {
    return {
        label: t(`models:Event.Category.${getKeyByValue(category)}`),
        value: getKeyByValue(category)
    };
}

const options = (t): Option[] => [
    // Rajdy
    {
        label: t(`models:Event.Category.Rallies`),
        value: key_Rallies,
        children: [
            createOption(EventCategory.Rally, t),
            createOption(EventCategory.Kjs, t),
            createOption(EventCategory.Rallysprint, t),
            createOption(EventCategory.SuperStage, t),
            createOption(EventCategory.SuperSprint, t),
            createOption(EventCategory.Offroad, t),
            createOption(EventCategory.RSMP, t),
            createOption(EventCategory.HRSMP, t),
            createOption(EventCategory.RMPZ, t),
            createOption(EventCategory.RSMSL, t),
            createOption(EventCategory.RSMDSL, t),
            createOption(EventCategory.RO, t),
            createOption(EventCategory.SKJS, t),
            createOption(EventCategory.Wrc, t),
        ]
    },

    // Wyścigi
    {
        label: t(`models:Event.Category.Races`),
        value: key_Races,
        children: [
            createOption(EventCategory.RaceFlat, t),
            createOption(EventCategory.RaceMountain, t),
            createOption(EventCategory.Rallycross, t),
            createOption(EventCategory.WreckRace, t),
            createOption(EventCategory.TimeAttack, t),
            createOption(EventCategory.TrackDay, t),
            createOption(EventCategory.DragRace, t),
            createOption(EventCategory.Autocross, t),
            createOption(EventCategory.Sprint, t),
        ]
    },

    // Drift
    {
        label: t(`models:Event.Category.Drift`),
        value: key_Drift,
        children: [
            createOption(EventCategory.DriftCompPro, t),
            createOption(EventCategory.DriftCompAm, t),
            createOption(EventCategory.DriftTraining, t),
            createOption(EventCategory.DriftOther, t),
        ]
    },

    // RC
    {
        label: t(`models:Event.Category.RcModels`),
        value: key_RcModels,
        children: [
            createOption(EventCategory.RcDrift, t),
            createOption(EventCategory.RcTrial, t),
            createOption(EventCategory.RcOffroad, t),
            createOption(EventCategory.RcRally, t),
            createOption(EventCategory.RcIndoorRace, t),
            createOption(EventCategory.RcShow, t),
        ]
    },

    // Inne
    {
        label: t(`models:Event.Category.Others`),
        value: key_Others,
        children: [
            createOption(EventCategory.Fair, t),
            createOption(EventCategory.Training, t),
            createOption(EventCategory.Schooling, t),
            createOption(EventCategory.FreeRide, t),
            createOption(EventCategory.Charity, t),
            createOption(EventCategory.Integration, t),
            createOption(EventCategory.Exhibition, t),
        ]
    },

    // Zloty i spoty
    {
        label: t(`models:Event.Category.JamboreesAndMeetups`),
        value: key_JamboreesAndSpots,
        children: [
            createOption(EventCategory.Jamboree, t),
            createOption(EventCategory.Meetup, t),
            createOption(EventCategory.Classic, t),
            createOption(EventCategory.Oldtimer, t),
            createOption(EventCategory.American, t),
            createOption(EventCategory.Stance, t),
            createOption(EventCategory.Tuning, t),
            createOption(EventCategory.Bmw, t),
            createOption(EventCategory.Vw, t),
            createOption(EventCategory.Jdm, t),
        ]
    },
];

export const EventCategoryGrouping = {
    toOptions: (t): Option[] => options(t),

    categoryToPath: (t, category: EventCategory): any[] => {
        const opts = options(t);
        const mainIndex = opts.findIndex(p => p.value === category || p.children?.some(c => c.value === category));
        const opt = opts[mainIndex];
        const childIndex = opt.children?.findIndex(c => c.value === category);

        if(childIndex == null || childIndex < 0)
            return [opt.value];

        return [opt.value, opt.children[childIndex].value];
    },

    toGroups: (t, categories: EventCategory[]): Option[] => {
        const labels = [];
        const opts = options(t);

        categories.forEach(category => {
            const group = opts.find(g => g.value === category || g.children?.some(c => c.value === category));

            if(group && !labels.find(gr => gr.value === group.value)) {
                labels.push({
                    value: group.value,
                    label: group.label
                });
            }
        });

        return labels;
    }
}