import { SettingFilled } from '@ant-design/icons';
import { Tag, Dropdown, Button, Menu } from 'antd';
import Column from 'antd/lib/table/Column';
import { GetUsersRequest } from 'App/api/endpoints/users/requests';
import { UserForGetUsersResponse } from 'App/api/endpoints/users/responses/getUsersResponse';
import ExtendedTable from 'App/common/components/extendedTable/ExtendedTable';
import { tableUtils } from 'App/common/utils/table.utils';
import { AppThunk } from 'App/globalState/store';
import { IPagination } from 'App/types/pagination/pagination';
import Role from 'App/types/role';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface UsersTableProps {
	dataSource: UserForGetUsersResponse[];
	loading: boolean;
	requestPagination: IPagination;
	deleteUser: (userToDelete: UserForGetUsersResponse) => void;
	getUsers: (params: GetUsersRequest) => AppThunk;
}

const { manageTableFilters, manageTableOrderBy, requestFiltersToAntdFilters, requestOrderByToAntdOrderBy } = tableUtils;

const UsersTable = ({ dataSource, loading, requestPagination, deleteUser, getUsers }: UsersTableProps) => {
	const translatedOrderBy = requestOrderByToAntdOrderBy(requestPagination.orderBy);
	const translatedFilters = requestFiltersToAntdFilters(requestPagination.filters);

	const { t } = useTranslation();

	const menuForActionDropdown = (record: UserForGetUsersResponse) => (
		<Menu>
			<Menu.Item key='edit-user'>
				<Button type='link'>
					<Link to={`/admin/users/${record.id}/update`}>{t('common:Actions.Edit')}</Link>
				</Button>
			</Menu.Item>
			<Menu.Item key='delete-user'>
				<Button type='link' onClick={() => deleteUser(record)}>
					{t('common:Actions.Remove')}
				</Button>
			</Menu.Item>
		</Menu>
	);

	return (
		<ExtendedTable
			searchable
			requestPagination={requestPagination}
			fetchDataAction={getUsers}
			loading={loading}
			showHeader={!loading}
			dataSource={dataSource}
		>
			<Column
				key='firstName'
				dataIndex='firstName'
				title={t('models:User.Labels.FirstName')}
				sorter={{ multiple: 3 }}
				render={(firstName, record: UserForGetUsersResponse) => (
					<Link to={`/admin/users/${record.id}`}>{firstName}</Link>
				)}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'firstName')}
			/>
			<Column
				key='lastName'
				dataIndex='lastName'
				title={t('models:User.Labels.LastName')}
				sorter={{ multiple: 2 }}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'lastName')}
			/>
			<Column
				key='email'
				dataIndex='email'
				title={t('models:User.Labels.Email')}
				sorter={{ multiple: 1 }}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'email')}
			/>
			<Column
				title={t('models:User.Labels.EmailConfirmed')}
				dataIndex='emailConfirmed'
				render={(emailConfirmed: boolean) => (
					<>{emailConfirmed ? t('common:Actions.Yes') : t('common:Actions.No')}</>
				)}
				filters={[
					{ text: t('common:Actions.Yes'), value: true },
					{ text: t('common:Actions.No'), value: false },
				]}
				filterMultiple={false}
				filteredValue={manageTableFilters(translatedFilters, 'emailConfirmed')}
			/>
			<Column
				title={t('models:User.Labels.IsDeleted')}
				dataIndex='isDeleted'
				render={(isDeleted: boolean) => <>{isDeleted ? t('common:Actions.Yes') : t('common:Actions.No')}</>}
				filters={[
					{ text: t('common:Actions.Yes'), value: true },
					{ text: t('common:Actions.No'), value: false },
				]}
				filterMultiple={false}
				filteredValue={manageTableFilters(translatedFilters, 'isDeleted')}
			/>
			<Column
				title={t('models:User.Labels.Roles')}
				dataIndex='roles'
				render={(roles: string[]) => (
					<>
						{roles.map((role: string) => {
							const roleTranslationKey = `common:Roles.${role}`;
							const color = role === Role.ADMIN ? 'blue' : 'volcano';
							return (
								<Tag key={role} color={color}>
									{t(roleTranslationKey)}
								</Tag>
							);
						})}
					</>
				)}
			/>

			<Column
				title={t('common:Actions.Actions')}
				render={(record: UserForGetUsersResponse) => (
					<Dropdown
						overlay={() => menuForActionDropdown(record)}
						trigger={['click']}
						placement='bottomCenter'
					>
						<Button type='link'>
							<SettingFilled />
						</Button>
					</Dropdown>
				)}
			/>
		</ExtendedTable>
	);
};

export default UsersTable;
