import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FileSearchOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { Input } from 'antd';

import LoadingScreen from 'App/common/components/LoadingScreen';
import PageTitle from 'App/common/components/PageTitle';
import { LogForGetLogsFilesResponse } from 'App/api/endpoints/logs/responses';
import LogCard from './components/logCard/LogCard';

import { StatusType } from 'App/types/requestStatus';
import { useTranslation } from 'react-i18next';
import AllLogsCard from './components/allLogsCard/AllLogsCard';

import './styles/AllLogsContainer.less';
import { RootState } from 'App/globalState/root.reducer';
import { getLogsFiles, getLogsFileContent, downloadLogsFile, downloadAllLogsFiles } from '../../state/logs.thunk';

import OgBanner from 'App/common/assets/ogbanner.jpg';

const { TextArea } = Input;
const { LOADING } = StatusType;

const AllLogsContainer = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation('page');

	const { logs, logContent, status } = useSelector((state: RootState) => state.pages.admin.logs);

	const [fileName, setFileName] = useState('');
	const [activeCardIndex, setActiveCardIndex] = useState(-1);

	useEffect(() => {
		dispatch(getLogsFiles());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getLogsFileContent(fileName));
	}, [dispatch, fileName]);

	const showLogsFileContent = (fileName: string) => {
		setFileName(fileName);
	};

	const handleLogCardClick = (name: string, index: number) => {
		return () => {
			showLogsFileContent(name);
			setActiveCardIndex(index);
		};
	};

	const handleDownloadClick = (name: string) => {
		return () => {
			dispatch(downloadLogsFile(name));
		};
	};

	return (
		<div className='all-logs-container'>
			<img alt={'banner placeholder'} src={OgBanner} style={{display: 'none', width: '0px', height: '0px'}} />

			<PageTitle title={t('AdminPages.LogsPages.PageTitle')} icon={<FileSearchOutlined />} />
			{status.getLogsFiles === LOADING ? (
				<LoadingScreen container={'screen'} />
			) : (
				<Row className='all-logs-container__logs-content-row'>
					<Col xl={20} xs={24}>
						{status.getLogsFileContent === LOADING ? (
							<LoadingScreen container='fill' />
						) : (
							<TextArea
								value={Array.prototype.join.call(logContent, '\n')}
								placeholder={t('AdminPages.LogsPages.TextAreaPlaceholder')}
								className='all-logs-container__logs-content-row__logs-content'
							/>
						)}
					</Col>
					<Col xl={4} xs={24} className='all-logs-container__logs-content-row__col-cards'>
						<AllLogsCard onDownloadClick={() => dispatch(downloadAllLogsFiles('allLogs'))} />
						{logs.map((log: LogForGetLogsFilesResponse, index: number) => {
							return (
								<LogCard
									key={index}
									activeCardIndex={activeCardIndex}
									index={index}
									log={log}
									onCardClick={handleLogCardClick}
									onDownloadClick={handleDownloadClick}
								/>
							);
						})}
					</Col>
				</Row>
			)}
		</div>
	);
};

export default AllLogsContainer;
