import React from 'react';
import { Input, Select, Button, Form, Switch } from 'antd';
import Role from 'App/types/role';
import { UpdateUserRequest } from 'App/api/endpoints/users/requests';
import { useTranslation } from 'react-i18next';
import { formRules } from 'App/common/formRules/formRules';

interface UpdateUserFormProps {
	initialValues: {
		email: string;
		firstName: string;
		lastName: string;
		roles: Role[];
		isDeleted: boolean;
	};
	onFinish: (values: UpdateUserRequest) => void;
	loading: boolean;
}

const UpdateUserForm = ({ initialValues, loading, onFinish }: UpdateUserFormProps) => {
	const { t } = useTranslation(['form', 'common']);
	const { firstName, lastName, roles, isDeleted } = formRules.user;

	return (
		<Form layout='vertical' className='px-3' initialValues={initialValues} onFinish={onFinish}>
			<Form.Item
				label={t('models:User.Labels.FirstName')}
				messageVariables={{ arg: t('models:User.Labels.FirstName') }}
				name='firstName'
				rules={firstName()}
			>
				<Input placeholder={t('models:User.Placeholders.FirstName')} />
			</Form.Item>

			<Form.Item
				label={t('models:User.Labels.LastName')}
				messageVariables={{ arg: t('models:User.Labels.LastName') }}
				name='lastName'
				rules={lastName()}
			>
				<Input placeholder={t('models:User.Placeholders.LastName')} />
			</Form.Item>

			<Form.Item
				name='roles'
				label={t('models:User.Labels.Roles')}
				messageVariables={{ arg: t('models:User.Labels.Roles') }}
				rules={roles()}
			>
				<Select mode='multiple' placeholder={t('models:User.Placeholders.SelectRoles')}>
					<Select.Option value='User'>{t('common:Roles.User')}</Select.Option>
					<Select.Option value='Administrator'>{t('common:Roles.Administrator')}</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item
				name='isDeleted'
				label={t('models:User.Labels.IsDeleted')}
				messageVariables={{ arg: t('models:User.Labels.IsDeleted') }}
				rules={isDeleted()}
				valuePropName='checked'
			>
				<Switch />
			</Form.Item>

			<Form.Item>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Save')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default UpdateUserForm;
