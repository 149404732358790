import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { adminUsersInitialState, AdminUsersState } from './users.state';
import { StatusType } from 'App/types/requestStatus';
import { GetUserResponse, GetUsersResponse, UpdateUserResponse } from 'App/api/endpoints/users/responses';

const { FAILED, LOADING, SUCCESS } = StatusType;

export const adminUsersSlice = createSlice({
	name: 'admin-users',
	initialState: adminUsersInitialState,
	reducers: {
		getUsersStart: (state: AdminUsersState) => {
			state.status.getUsers = LOADING;
			state.users = adminUsersInitialState.users;
		},
		getUsersSuccess(state: AdminUsersState, action: PayloadAction<GetUsersResponse>) {
			state.status.getUsers = SUCCESS;
			const { users, ...pagination } = action.payload;
			state.users = action.payload.users;
			state.getUsersParams = pagination;
		},
		getUsersFailure(state: AdminUsersState) {
			state.status.getUsers = FAILED;
		},

		getUserStart: (state: AdminUsersState) => {
			state.status.getUser = LOADING;
			state.selectedUser = adminUsersInitialState.selectedUser;
		},
		getUserSuccess: (state: AdminUsersState, action: PayloadAction<GetUserResponse>) => {
			state.status.getUser = SUCCESS;
			state.selectedUser = action.payload;
		},
		getUserFailure: (state: AdminUsersState) => {
			state.status.getUser = FAILED;
		},

		deleteUserStart: (state: AdminUsersState) => {
			state.status.deleteUser = LOADING;
		},
		deleteUserSuccess: (state: AdminUsersState, action: PayloadAction<string>) => {
			state.status.deleteUser = SUCCESS;
			state.users = state.users.filter((u) => u.id !== action.payload);
		},
		deleteUserFailure: (state: AdminUsersState) => {
			state.status.deleteUser = FAILED;
		},

		createUserStart: (state: AdminUsersState) => {
			state.status.createUser = LOADING;
		},
		createUserSuccess: (state: AdminUsersState) => {
			state.status.createUser = SUCCESS;
		},
		createUserFailure: (state: AdminUsersState) => {
			state.status.createUser = FAILED;
		},

		updateUserStart: (state: AdminUsersState) => {
			state.status.updateUser = LOADING;
		},
		updateUserSuccess: (state: AdminUsersState, action: PayloadAction<UpdateUserResponse>) => {
			state.status.updateUser = SUCCESS;
			const user = state.users.find((u) => u.id === action.payload.id);

			if (user) {
				const { firstName, lastName, roles } = action.payload;

				user.firstName = firstName;
				user.lastName = lastName;

				user.roles = roles;
			}
		},
		updateUserFailure: (state: AdminUsersState) => {
			state.status.updateUser = FAILED;
		},

		cleanUpUserStatus: (state: AdminUsersState) => {
			state.status = adminUsersInitialState.status;
		},

		cleanUpUsersTable: (state: AdminUsersState) => {
			state.getUsersParams = adminUsersInitialState.getUsersParams;
			state.users = adminUsersInitialState.users;
			state.status.getUsers = adminUsersInitialState.status.getUsers;
		},

		cleanUpSelectedUser: (state: AdminUsersState) => {
			state.selectedUser = adminUsersInitialState.selectedUser;
		},
	},
});

export default adminUsersSlice;

export const {
	getUsersStart,
	getUsersSuccess,
	getUsersFailure,

	getUserStart,
	getUserSuccess,
	getUserFailure,

	deleteUserStart,
	deleteUserSuccess,
	deleteUserFailure,

	createUserStart,
	createUserSuccess,
	createUserFailure,

	updateUserStart,
	updateUserSuccess,
	updateUserFailure,

	cleanUpUserStatus,
	cleanUpSelectedUser,
	cleanUpUsersTable,
} = adminUsersSlice.actions;
