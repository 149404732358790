import React from 'react';
import { Route, Switch } from 'react-router';
import AdvertDetailsContainer from './views/advertDetails/AdvertDetailsContainer';
import AdvertsTableContainer from './views/advertsTable/AdvertsTableContainer';
import CreateAdvertContainer from './views/createAdvert/CreateAdvertContainer';
import UpdateAdvertContainer from './views/updateAdvert/UpdateAdvertContainer';

const AdminAdvertPages = () => {
	return (
		<Switch>
			<Route exact path='/admin/adverts' component={AdvertsTableContainer} />
            <Route exact path='/admin/adverts/create' component={CreateAdvertContainer} />
            <Route exact path='/admin/adverts/:advertId' component={AdvertDetailsContainer} /> 
			<Route exact path='/admin/adverts/:advertId/update' component={UpdateAdvertContainer} />
		</Switch>
	);
};

export default AdminAdvertPages;
