import { Col, Row, Typography, Image } from 'antd';
import { serverURL } from 'App/api/agent/axios/configuration';
import { GetAdminEventResponse } from 'App/api/endpoints/events/responses';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EventGraphicsTabProps {
	event: GetAdminEventResponse;
}

const EventGraphicsTab = ({ event }: EventGraphicsTabProps) => {
	const { t } = useTranslation(['page', 'form', 'common']);

	if (event) {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Banner')}:{'\n'}
							</Typography.Text>
							{event.urlBanner ? 
							<Image src={serverURL + event.urlBanner} /> :
							<></>}
						</Typography>
					</Col>
					<Col span={24}>
						<Typography>
							<Typography.Text type='secondary'>
								{t('models:Event.Labels.Poster')}:{'\n'}
							</Typography.Text>
							{event.urlPoster ?
							<Image src={serverURL + event.urlPoster} /> :
							<></>}
						</Typography>
					</Col>
				</Row>
			</>
		);
	} else {
		return <></>;
	}
};

export default EventGraphicsTab;
