import { SettingFilled } from '@ant-design/icons';
import { Dropdown, Button, Menu, Tag, Switch } from 'antd';
import Column from 'antd/lib/table/Column';
import { GetAdminEventsRequest } from 'App/api/endpoints/events/requests';
import { EventForGetAdminEventsResponse } from 'App/api/endpoints/events/responses';
import ExtendedTable from 'App/common/components/extendedTable/ExtendedTable';
import { EventStatus } from 'App/common/enums';
import { tableUtils } from 'App/common/utils/table.utils';
import { AppThunk } from 'App/globalState/store';
import { IPagination } from 'App/types/pagination/pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dateTimeUtils } from '../../../../../../common/utils/dateTime.utils';

import './EventsTable.less';

interface EventsTableProps {
	dataSource: EventForGetAdminEventsResponse[];
	loading: boolean;
	requestPagination: IPagination;
	deleteEvent: (eventToDelete: EventForGetAdminEventsResponse) => void;
	getEvents: (params: GetAdminEventsRequest) => AppThunk;
}

const { manageTableFilters, manageTableOrderBy, requestFiltersToAntdFilters, requestOrderByToAntdOrderBy } = tableUtils;

const EventsTable = ({ dataSource, loading, requestPagination, deleteEvent, getEvents }: EventsTableProps) => {
	const translatedOrderBy = requestOrderByToAntdOrderBy(requestPagination.orderBy);
	const translatedFilters = requestFiltersToAntdFilters(requestPagination.filters);

	const { t } = useTranslation();

	const menuForActionDropdown = (record: EventForGetAdminEventsResponse) => (
		<Menu>
			<Menu.Item key='edit-event'>
				<Button type='link'>
					<Link to={`/admin/events/${record.id}/update`}>{t('common:Actions.Edit')}</Link>
				</Button>
			</Menu.Item>
			<Menu.Item key='duplicate-event'>
				<Button type='link'>
					<Link to={`/admin/events/create?duplicateid=${record.id}`}>{t('common:Actions.Duplicate')}</Link>
				</Button>
			</Menu.Item>
			{Number(EventStatus[record.status].valueOf()) !== EventStatus.Deleted &&
				<Menu.Item key='delete-event'>
					<Button type='link' onClick={() => deleteEvent(record)}>
						{t('common:Actions.Remove')}
					</Button>
				</Menu.Item>
			}
		</Menu>
	);

	const statusFilters = () => {
		const filters = (Object.keys(EventStatus) as Array<keyof typeof EventStatus>).map((key) => {
			if(isNaN(Number(key)))
				return { text: t(`models:Event.Status.${key}`), value: key }
			else
				return null;
		});

		return filters.filter(x => x);
	};

	return (
		<ExtendedTable
			searchable
			requestPagination={requestPagination}
			fetchDataAction={getEvents}
			loading={loading}
			showHeader={!loading}
			dataSource={dataSource}
			className='events-table'
		>
            <Column
				key='title'
				dataIndex='title'
				title={t('models:Event.Labels.Title')}
				sorter={{ multiple: 3 }}
				render={(title, record: EventForGetAdminEventsResponse) => (
					<Link to={`/admin/events/${record.id}`}>
						{title}
					</Link>
				)}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'title')}
				width={400}
			/>
            <Column
				key='dateStart'
				dataIndex='dateStart'
				title={t('models:Event.Labels.DateStart')}
				sorter={{ multiple: 3 }}
				render={(dateStart, record: EventForGetAdminEventsResponse) => (
					<Link to={`/admin/events/${record.id}`}>{dateTimeUtils.formatWholeDayDate(dateStart, record.isWholeDay)}</Link>
				)}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'dateStart')}
			/>
            <Column
				key='dateEnd'
				dataIndex='dateEnd'
				title={t('models:Event.Labels.DateEnd')}
				render={(dateEnd, record: EventForGetAdminEventsResponse) => (
					<Link to={`/admin/events/${record.id}`}>{dateTimeUtils.formatWholeDayDate(dateEnd, record.isWholeDay)}</Link>
				)}
			/>
            <Column
				key='dateCreated'
				dataIndex='dateCreated'
				title={t('models:Event.Labels.DateCreated')}
				sorter={{ multiple: 3 }}
				render={(dateCreated, record: EventForGetAdminEventsResponse) => (
					<Link to={`/admin/events/${record.id}`}>{dateTimeUtils.getUtcTimeString(dateCreated)}</Link>
				)}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'dateCreated')}
			/>
            <Column
				title={t('models:Event.Labels.Status')}
				dataIndex='status'
				render={(status: EventStatus) => (
					<Tag color={tableUtils.statusToColor(status)}>{t(`models:Event.Status.${status}`)}</Tag>
				)}
				filters={statusFilters()}
				filterMultiple={true}
				filteredValue={manageTableFilters(translatedFilters, 'status')}
				width={150}
			/>
			<Column
				key='datePromotionStart'
				title={t('models:Event.Labels.Promoted')}
				dataIndex='datePromotionStart'
				render={datePromotionStart => (
					<Switch checked={datePromotionStart} />
				)}
				sorter={{ multiple: 3 }}
				sortOrder={manageTableOrderBy(translatedOrderBy, 'datePromotionStart')}
				width={150}
			/>

			<Column
				title={t('common:Actions.Actions')}
				render={(record: EventForGetAdminEventsResponse) => (
					<Dropdown
						overlay={() => menuForActionDropdown(record)}
						trigger={['click']}
						placement='bottomCenter'
					>
						<Button type='link'>
							<SettingFilled />
						</Button>
					</Dropdown>
				)}
				width={100}
			/>
		</ExtendedTable>
	);
};

export default EventsTable;
