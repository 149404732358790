import React from 'react'

import './Banner.less';

import banner from '../../../../../../common/assets/baner.webp';
import logo from '../../../../../../common/assets/logoheader.webp';

const Banner = () => {
  return (
    <div
        className='banner-main' 
        style={{ 'backgroundImage': `url(${banner})` }}
    >
      <div className='banner-main__logo-container'>
        <img className='banner-main__logo' src={logo} alt="logo" />
      </div>
	  </div>
  )
}

export default Banner;