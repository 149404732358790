import React from 'react';

import './TermsOfServiceContainer.less';

export const TermsOfServiceContainer = () => {
	const sectionBreak = () => (
		<>
			<br />
			<hr />
			<br />
		</>
	);

	const phone = () => '881 966 232';

	const email = () => <a href='mailto:kalendarzmotoryzacyjny@gmail.com'>kalendarzmotoryzacyjny@gmail.com</a>;

	const website = () => <a href='https://kalendarz-motoryzacyjny.pl'>https://kalendarz-motoryzacyjny.pl</a>;

	return (
		<div className='terms-of-service-container'>
			<h1>
				<b>REGULAMIN SERWISU INTERNETOWEGO KALENDARZ-MOTORYZACYJNY.PL</b>
			</h1>
			<br />
			<b>SPIS TREŚCI:</b>
			<br /> <br />
			<ol>
				<li>POSTANOWIENIA WSTĘPNE</li>
				<li>ROLA KALENDARZ-MOTORYZACYJNY.PL</li>
				<li>OGÓLNE ZASADY KORZYSTANIA Z SERWISU INTERNETOWEGO</li>
				<li>USŁUGI ELEKTRONICZNE W KALENDARZ-MOTORYZACYJNY.PL</li>
				<li>WARUNKI KORZYSTANIA Z TABLICY OGŁOSZEŃ, ZASADY DODAWANIA OGŁOSZEŃ PRZEZ UŻYTKOWNIKÓW</li>
				<li>WARUNKI KORZYSTANIA Z POZOSTAŁYCH USŁUG ELEKTRONICZNYCH</li>
				<li>USŁUGI REKLAMOWE</li>
				<li>OGRANICZENIE, ZAWIESZENIE I ZAKOŃCZENIE ŚWIADCZENIA USŁUG</li>
				<li>PLASOWANIE OGŁOSZEŃ</li>
				<li>DOSTĘP DO DANYCH W KALENDARZ-MOTORYZACYJNY.PL</li>
				<li>KONTAKT Z USŁUGODAWCĄ</li>
				<li>PROCEDURA ROZPATRYWANIA REKLAMACJI</li>
				<li>USTAWOWE PRAWO ODSTĄPIENIA OD UMOWY</li>
				<li>
					POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ ORAZ ZASADY DOSTĘPU DO TYCH
					PROCEDUR
				</li>
				<li>POSTANOWIENIA DOTYCZĄCE PRZEDSIĘBIORCÓW</li>
				<li>PRAWA AUTORSKIE DO KALENDARZ-MOTORYZACYJNY.PL</li>
				<li>PRZERWY I AWARIE TECHNICZNE</li>
				<li>POSTANOWIENIA KOŃCOWE</li>
			</ol>
			{sectionBreak()}
			<p>
				Dziękujemy za odwiedzenie naszego serwisu internetowego udostępnianego pod adresem internetowym{' '}
				{website()} (dalej jako: "Kalendarz-motoryzacyjny.pl", "Serwis Internetowy" lub "Serwis").
			</p>
			<p>
				Kalendarz-motoryzacyjny.pl jest miejscem, w którym zamieszczane są ogłoszenia na temat wydarzeń
				motoryzacyjnych w całej Polsce. Serwis Internetowy powstał, aby z jednej strony ułatwić dotarcie z
				informacjami o wydarzeniach motoryzacyjnych do osób nimi zainteresowanych, a z drugiej strony - aby
				ułatwić tym osobom znalezienie interesujących je wydarzeń.
			</p>
			<p>
				Serwis Internetowy współtworzony jest przez jego użytkowników, którzy mogą dodawać swoje ogłoszenia,
				korzystając z odpowiedniego formularza na stronie Kalendarz-motoryzacyjny.pl.
			</p>
			<p>
				Ogłoszenia zamieszczone w Serwisie Internetowym mają charakter wyłącznie informacyjny i nie stanowią
				oferty w rozumieniu art. 66 § 1 Kodeksu cywilnego. Celem skorzystania z jakichkolwiek usług będących
				przedmiotem danego ogłoszenia niezbędny jest każdorazowo kontakt z organizatorem wydarzenia poza
				Serwisem Internetowym i ustalenie szczegółów przez zainteresowane strony. Serwis Internetowy nie
				umożliwia użytkownikom zawierania jakichkolwiek umów w przedmiocie zamieszczonych w nim ogłoszeń.
			</p>
			<p>
				Formuła niniejszego regulaminu zakłada ustalenie ogólnych zasad i warunków korzystania z Serwisu
				Internetowego. Niniejsze warunki, w razie podjęcia decyzji przez użytkownika o korzystaniu z Kalendarz-
				motoryzacyjny.pl, regulują w szczególności zasady korzystania z Serwisu Internetowego, w tym kwestie
				naszej odpowiedzialności.
			</p>
			<p>
				<b>
					Zapraszamy do zapoznania się z regulaminem, <br />
					Zespół Kalendarz-motoryzacyjny.pl
				</b>
			</p>
			{sectionBreak()}
			<h2>1) POSTANOWIENIA WSTĘPNE</h2>
			<ol>
				<li>
					Właścicielem Serwisu Internetowego jest Agnieszka Kujawa prowadząca działalność gospodarczą pod
					firmą AGNIESS.PL AGNIESZKA KUJAWA wpisana do Centralnej Ewidencji i Informacji o Działalności
					Gospodarczej Rzeczypospolitej Polskiej prowadzonej przez ministra właściwego do spraw gospodarki,
					posiadająca: adres do doręczeń: ul. Szkolna 43a, 64-000 Turew, NIP 6981856234, REGON 386483806,
					adres poczty elektronicznej: {email()} oraz numer telefonu: {phone()} (dalej jako: „Usługodawca”).
				</li>
				<li>
					Regulamin skierowany jest do wszystkich Użytkowników korzystających z Serwisu Internetowego, chyba
					że dane postanowienie stanowi inaczej. Postanowienia niniejszego Regulaminu nie mają na celu
					wyłączać ani ograniczać jakichkolwiek praw Użytkowników będących Konsumentami lub Przedsiębiorcami
					na Prawach Konsumenta przysługujących im na mocy bezwzględnie wiążących przepisów prawa. W przypadku
					niezgodności postanowień niniejszego Regulaminu z powyższymi przepisami, pierwszeństwo przysługuje
					tym przepisom.
				</li>
				<li>
					Określenia użyte w niniejszym Regulaminie i rozpoczynające się wielką literą oznaczają:
					<ol type='a'>
						<li>
							<b>DZIEŃ ROBOCZY</b> - jeden dzień od poniedziałku do piątku z wyłączeniem dni ustawowo
							wolnych od pracy.
						</li>
						<li>
							<b>FORMULARZ DODANIA OGŁOSZENIA</b> - Usługa Elektroniczna, interaktywny formularz dostępny
							w Serwisie Internetowym umożliwiający każdemu Użytkownikowi dodanie własnego Ogłoszenia na
							Tablicę Ogłoszeń.
						</li>
						<li>
							<b>FORMULARZ KONTAKTOWY</b> - Usługa Elektroniczna, interaktywny formularz dostępny w
							Serwisie Internetowym umożliwiający Użytkownikom nawiązanie kontaktu z Usługodawcą.
						</li>
						<li>
							<b>KONSUMENT</b> - osoba fizyczna, dla której korzystanie z Usług Elektronicznych dostępnych
							w Serwisie Internetowym nie jest związane bezpośrednio z jej działalnością gospodarczą lub
							zawodową.
						</li>
						<li>
							<b>KODEKS CYWILNY</b> - ustawa z dnia 23 kwietnia 1964 r. – Kodeks cywilny (Dz. U. Nr 16,
							poz. 93 ze zm.).
						</li>
						<li>
							<b>OGŁOSZENIE</b> - jakiekolwiek ogłoszenie, niezależnie od jego nazwy, w tym przede
							wszystkim ogłoszenie o tematyce wydarzeń motoryzacyjnych, opublikowane na Tablicy Ogłoszeń w
							Serwisie Internetowym. Ogłoszenia w Serwisie Internetowym mają charakter wyłącznie
							informacyjny i nie stanowią oferty w rozumieniu art. 66 § 1 Kodeksu Cywilnego.
						</li>
						<li>
							<b>PRAWO AUTORSKIE</b> - ustawa o prawie autorskim i prawach pokrewnych z dnia 4 lutego 1994
							r. (Dz. U. Nr 24, poz. 83 ze zm.).
						</li>
						<li>
							<b>PRZEDSIĘBIORCA NA PRAWACH KONSUMENTA</b> - osoba fizyczna, dla której korzystanie z Usług
							Elektronicznych dostępnych w Kalendarz-motoryzacyjny.pl jest związane bezpośrednio z jej
							działalnością gospodarczą, gdy z okoliczności wynika, że nie posiada to dla tej osoby
							charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią
							działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i
							Informacji o Działalności Gospodarczej.
						</li>
						<li>
							<b>REGULAMIN</b> - niniejszy regulamin Serwisu Internetowego.
						</li>
						<li>
							<b>KALENDARZ-MOTORYZACYJNY.PL, SERWIS INTERNETOWY, SERWIS</b> - serwis internetowy
							Usługodawcy dostępny pod adresem internetowym {website()}.
						</li>
						<li>
							<b>TABLICA OGŁOSZEŃ</b> - Usługa Elektroniczna, tj. interaktywna tablica Ogłoszeń dostępna w
							Serwisie Internetowym, w ramach której publikowane i wyświetlane są Ogłoszenia dodane przez
							Użytkowników oraz Usługodawcę Kalendarz-motoryzacyjny.pl.
						</li>
						<li>
							<b>USŁUGA ELEKTRONICZNA</b> - usługa świadczona drogą elektroniczną przez Usługodawcę na
							rzecz Użytkowników za pośrednictwem Serwisu Internetowego zgodnie z Regulaminem.
						</li>
						<li>
							<b>UŻYTKOWNIK</b> - (1) osoba fizyczna posiadająca pełną zdolność do czynności prawnych, a w
							wypadkach przewidzianych przez przepisy powszechnie obowiązujące także osoba fizyczna
							posiadająca ograniczoną zdolność do czynności prawnych; (2) osoba prawna; albo (3) jednostka
							organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną -
							korzystająca albo zamierzająca korzystać z Usług Elektronicznych dostępnych w
							Kalendarz-motoryzacyjny.pl.
						</li>
						<li>
							<b>USŁUGODAWCA</b> - Agnieszka Kujawa prowadząca działalność gospodarczą pod firmą
							AGNIESS.PL AGNIESZKA KUJAWA wpisana do Centralnej Ewidencji i Informacji o Działalności
							Gospodarczej Rzeczypospolitej Polskiej prowadzonej przez ministra właściwego do spraw
							gospodarki, posiadająca: adres do doręczeń: ul. Szkolna 43a, 64-000 Turew, NIP 6981856234,
							REGON 386483806, adres poczty elektronicznej: {email()} oraz numer telefonu: {phone()}.
						</li>
						<li>
							<b>USTAWA O PRAWACH KONSUMENTA</b> - ustawa z dnia 30 maja 2014 r. o prawach konsumenta
							(Dz.U. 2014 poz. 827 ze zm.).
						</li>
						<li>
							<b>WYSZUKIWARKA</b> - Usługa Elektroniczna, dostępna dla wszystkich Użytkowników
							wyszukiwarka znajdująca się na stronie Serwisu Internetowego umożliwiająca wyszukiwanie
							Ogłoszeń zamieszczonych w Serwisie Internetowym.
						</li>
					</ol>
				</li>
			</ol>
			<h2>2) ROLA KALENDARZ-MOTORYZACYJNY.PL</h2>
			<ol>
				<li>
					Kalendarz-motoryzacyjny.pl jest miejscem, w którym zamieszczane są Ogłoszenia na temat wydarzeń
					motoryzacyjnych w całej Polsce. Serwis Internetowy powstał, aby z jednej strony ułatwić dotarcie z
					informacjami o wydarzeniach motoryzacyjnych do osób nimi zainteresowanych, a z drugiej strony - aby
					ułatwić tym osobom znalezienie interesujących je wydarzeń.
				</li>
				<li>
					Serwis Internetowy umożliwia korzystającym z niego Użytkownikom między innymi dodawanie,
					wyszukiwanie i przeglądanie Ogłoszeń publikowanych na Tablicy Ogłoszeń.
				</li>
				<li>
					Ogłoszenia zamieszczone w Serwisie Internetowym mają charakter wyłącznie informacyjny i nie stanowią
					oferty w rozumieniu art. 66 § 1 Kodeksu Cywilnego. Celem skorzystania z usług będących przedmiotem
					Ogłoszenia niezbędny jest każdorazowo kontakt z organizatorem wydarzenia poza Serwisem Internetowym
					i ustalenie szczegółów przez zainteresowane strony. Usługodawca Kalendarz-motoryzacyjny.pl nie
					umożliwia Użytkownikom zawierania jakichkolwiek umów w przedmiocie zamieszczonych w nim Ogłoszeń.
				</li>
				<li>
					Użytkownicy są samodzielnymi podmiotami trzecimi w stosunku do Usługodawcy Kalendarz-
					motoryzacyjny.pl. Usługodawca nie świadczy na rzecz Użytkowników innych usług niż Usługi
					Elektroniczne wskazane w Regulaminie. Jakiekolwiek umowy zawierane z ogłoszeniodawcami lub
					organizatorami wydarzeń będących przedmiotem Ogłoszeń są zawierane poza Serwisem Internetowym na
					wyłączną odpowiedzialność stron takiej umowy oraz na uzgodnionych przez nie warunkach.
				</li>
			</ol>
			<h2>3) OGÓLNE ZASADY KORZYSTANIA Z SERWISU INTERNETOWEGO</h2>
			<ol>
				<li>
					Użytkownik obowiązany jest do korzystania z Serwisu Internetowego zgodnie z jego tematyką i
					przeznaczeniem, niniejszym Regulaminem oraz w sposób zgodny z prawem i dobrymi obyczajami, mając na
					uwadze poszanowanie dóbr osobistych oraz praw autorskich i własności intelektualnej Usługodawcy,
					innych Użytkowników oraz osób trzecich. Użytkownik obowiązany jest do wprowadzania danych zgodnych
					ze stanem faktycznym.
				</li>
				<li>
					Użytkownika obowiązuje zakaz dostarczania treści o charakterze bezprawnym. Użytkownikowi zabrania
					się przesyłania niezamówionych informacji handlowych (spam) za pośrednictwem Serwisu Internetowego.
					Użytkownik obowiązany jest nie podejmować działań zakłócających prawidłowe funkcjonowanie Kalendarz-
					motoryzacyjny.pl.
				</li>
				<li>
					Zgodnie z art. 14 ust. 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną
					(Dz.U. 2002 nr 144, poz. 1204 ze zm.), Usługodawca nie ponosi odpowiedzialności za dane zamieszczane
					w Serwisie Internetowym przez Użytkowników, jeśli nie wie o bezprawnym charakterze tych danych lub
					związanej z nimi działalności. Usługodawca nie ma prawnego obowiązku sprawdzania oraz weryfikacji
					danych przechowywanych przez Użytkowników, jednakże w razie otrzymania urzędowego zawiadomienia lub
					uzyskania wiarygodnej wiadomości o bezprawnym charakterze danych lub związanej z nimi działalności,
					Usługodawca podejmuje odpowiednie kroki w celu niezwłocznego uniemożliwienia dostępu do tych danych
					w Serwisie Internetowym.
				</li>
				<li>
					Usługodawca nie gwarantuje aktualności, rzetelności i wiarygodności danych zamieszczanych w Serwisie
					Internetowym przez samych Użytkowników, w szczególności w ramach dodawanych przez nich Ogłoszeń.
					Usługodawca nie ponosi odpowiedzialności za nieprawidłowość tych danych, z zastrzeżeniem, że
					pozostaje to bez uszczerbku dla ewentualnej odpowiedzialności Usługodawcy wobec Użytkownika będącego
					Konsumentem lub Przedsiębiorcą na Prawach Konsumenta przewidzianej przez powszechnie obowiązujące
					przepisy prawa.
				</li>
				<li>
					Usługodawca podejmuje należyte starania, aby korzystanie z Serwisu Internetowego było zrozumiałe i
					przejrzyste dla Użytkowników, jednak nie może zagwarantować, że Użytkownik będzie potrafił
					obsługiwać samodzielnie Usługi Elektroniczne albo że okażą się ona przydatne w osiągnięciu celów
					oczekiwanych przez Użytkownika. Usługodawca udostępnia Serwis Internetowy w formie, w jakiej został
					on dostarczony i nie czyni żadnych dorozumianych lub wyrażonych otwarcie zapewnień co do jego
					użyteczności do konkretnych zastosowań, co nie wyłącza ani nie ogranicza ewentualnej
					odpowiedzialności Usługodawcy wobec Użytkownika będącego Konsumentem lub Przedsiębiorcą na Prawach
					Konsumenta przewidzianej przez powszechnie obowiązujące przepisy prawa.
				</li>
				<li>
					Prawidłowe korzystanie z Serwisu Internetowego i jego Usług Elektronicznych może wymagać spełnienia
					następujących wymogów technicznych przez Użytkownika: (1) komputer, laptop lub inne urządzenie
					multimedialne z dostępem do Internetu; (2) dostęp do poczty elektronicznej; (3) przeglądarka
					internetowa w aktualnej wersji: Mozilla Firefox, Opera, Google Chrome lub Safari; (4) zalecana
					minimalna rozdzielczość ekranu: 1024x768; (5) włączenie w przeglądarce internetowej możliwości
					zapisu plików Cookies oraz obsługi Javascript.
				</li>
				<li>
					Korzystanie z Serwisu Internetowego wiąże się ze standardowymi zagrożenia występującymi w sieci
					Internet. Podstawowym zagrożeniem każdego użytkownika Internetu, w tym osób korzystających z Usług
					Elektronicznych, jest możliwość „zainfekowania” systemu teleinformatycznego przez różnego rodzaju
					złośliwe oprogramowanie tworzone głównie w celu wyrządzania szkód lub uzyskania nieuprawnionego
					dostępu do danych Użytkownika. Dla uniknięcia zagrożeń z tym związanych, Usługodawca zaleca, aby
					Użytkownik zaopatrzył swój sprzęt, który wykorzystuje podłączając się do Internetu, w program
					antywirusowy i stale go aktualizował, instalując jego najnowsze wersje.
				</li>
				<li>
					Administratorem danych osobowych przetwarzanych w Serwisie Internetowym w związku z realizacją
					postanowień niniejszego Regulaminu jest Usługodawca. Dane osobowe przetwarzane są w celach, przez
					okres i w oparciu o podstawy i zasady wskazane w polityce prywatności opublikowanej na stronie
					Serwisu Internetowego. Polityka prywatności zawiera przede wszystkim zasady dotyczące przetwarzania
					danych osobowych przez Usługodawcę w Serwisie Internetowym, w tym podstawy, cele i okres
					przetwarzania danych osobowych oraz prawa osób, których dane dotyczą, a także informacje w zakresie
					stosowania w Serwisie Internetowym plików cookies oraz narzędzi analitycznych. Korzystanie z Serwisu
					Internetowego jest dobrowolne. Podobnie związane z tym podanie danych osobowych przez Użytkownika
					korzystającego z Serwisu Internetowego jest dobrowolne, z zastrzeżeniem wyjątków wskazanych w
					polityce prywatności (np. zawarcie umowy oraz obowiązki ustawowe Usługodawcy).
				</li>
			</ol>
			<h2>4) USŁUGI ELEKTRONICZNE W KALENDARZ-MOTORYZACYJNY.PL</h2>
			<ol>
				<li>Korzystać z Serwisu Internetowego na warunkach wskazanych w Regulaminie może każdy Użytkownik.</li>
				<li>
					Usługodawca udostępnia Użytkownikom w szczególności następujące Usługi Elektroniczne:
					<ol type='a'>
						<b>
							<li>Tablica Ogłoszeń.</li>
						</b>
						<b>
							<li>Formularz Dodawania Ogłoszeń.</li>
						</b>
						<b>
							<li>Formularz Kontaktowy.</li>
						</b>
						<b>
							<li>Wyszukiwarka.</li>
						</b>
					</ol>
				</li>
				<li>
					Korzystanie z dostępnych Usług Elektronicznych jest nieodpłatne, z zastrzeżeniem usług reklamowych,
					o których mowa w punkcie 7. Regulaminu.
				</li>
				<li>
					Szczegółowy opis Usług Elektronicznych i zasad ich działania dostępny jest w niniejszym Regulaminie
					oraz na stronach Serwisu Internetowego.
				</li>
			</ol>
			<h2>5) WARUNKI KORZYSTANIA Z TABLICY OGŁOSZEŃ, ZASADY DODAWANIA OGŁOSZEŃ PRZEZ UŻYTKOWNIKÓW</h2>
			<ol>
				<li>
					Na Tablicy Ogłoszeń publikowane są Ogłoszenia dodawane bezpośrednio przez Usługodawcę Kalendarz-
					motoryzacyjny.pl lub przez Użytkowników korzystających z Formularza Dodawania Ogłoszenia.
				</li>
				<li>
					Dodawanie Ogłoszenia przez Użytkownika rozpoczyna się z chwilą przejścia do Formularza Dodawania
					Ogłoszenia dostępnego w zakładce{' '}
					<i>
						"<b>Dodaj wydarzenie</b>"
					</i>{' '}
					widocznej na stronie Kalendarz-motoryzacyjny.pl. Dodanie Ogłoszenia za pomocą Formularza Dodawania
					Ogłoszenia wymaga wykonania dwóch kolejnych kroków przez Użytkownika - (1) uzupełnienia Formularza
					Dodawania Ogłoszenia danymi wskazanymi jako obowiązkowe, (2) kliknięcia pola{' '}
					<i>
						"<b>Stwórz</b>"
					</i>{' '}
					oraz (3) potwierdzenia chęci dodania Ogłoszenia poprzez kliknięcie w link potwierdzający przesłany
					na podany adres poczty elektronicznej - z tą chwilą Ogłoszenie zostaje dodane i jednocześnie
					przekazane do zatwierdzenia przez Usługodawcę. W Formularzu Dodawania Ogłoszenia niezbędne jest
					podanie przez Użytkownika co najmniej następujących danych dotyczących przedmiotu Ogłoszenia: nazwa
					i adres poczty elektronicznej organizatora, tytuł wydarzenia, termin i lokalizacja wydarzenia oraz
					kategoria. Użytkownik może podać również inne opcjonalne dane zgodnie z nazewnictwem kolejnych pól
					Formularza Dodawania Ogłoszenia, a także załączyć grafiki i zdjęcia promujące przedmiot Ogłoszenia.
				</li>
				<li>
					Usługodawca zatwierdza Ogłoszenie i publikuje je na Tablicy Ogłoszeń niezwłocznie, nie później niż w
					ciągu 3 Dni Roboczych od chwili dodania Ogłoszenia zgodnie z pkt. 5.2 wyżej, chyba że Usługodawca
					stwierdził, że Ogłoszenie może naruszać warunki niniejszego Regulaminu lub jego treść pozostawia
					wątpliwości co do autentyczności przedmiotu Ogłoszenia lub osoby je dodającej - w takim wypadku
					Usługodawca uprawniony jest do wstrzymania dodania Ogłoszenia i podjęcia próby kontaktu z
					Użytkownikiem w celu zmiany, uzupełnienia lub dodatkowego potwierdzenia autentyczności Ogłoszenia.
				</li>
				<li>
					Ogłoszenie Użytkownika po jego publikacji staje się widoczne dla wszystkich osób odwiedzających
					Serwis Internetowy. Ogłoszenie jest widoczne w Serwisie Internetowym do czasu (1) usunięcia
					Ogłoszenia przez Usługodawcę z przyczyn wskazanych w niniejszym Regulaminie, (2) rozpatrzenia
					wniosku o wycofanie Ogłoszenia przesłanego przez Użytkownika lub (3) upłynięcia terminu wydarzenia
					będącego przedmiotem Ogłoszenia - po tym czasie Ogłoszenie automatycznie wygasa i przestaje być
					widoczne na Tablicy Ogłoszeń.
				</li>
				<li>
					Użytkownik ma prawo zgłosić chęć edycji swojego Ogłoszenia przez cały okres jego widoczności w
					Serwisie Internetowym, kontaktując się w tym celu z Usługodawcą Kalendarz-motoryzacyjny.pl.
					Usługodawca nanosi zmiany w terminie i na zasadach określonych w pkt. 5.3 wyżej.
				</li>
				<li>
					Użytkownika zamieszczającego Ogłoszenie obowiązują następujące warunki dodawania Ogłoszeń:
					<ol type='a'>
						<li>
							Ogłoszenie musi zawsze dotyczyć faktycznego wydarzenia motoryzacyjnego, którego
							organizatorem jest Użytkownik bądź osoba lub podmiot trzeci, w imieniu którego Użytkownik
							dodaje Ogłoszenie, działając jako jego upoważniony przedstawiciel.
						</li>
						<li>
							Ogłoszenie powinno być zgodne ze stanem faktycznym, zawierać jasne, rzetelne, zrozumiałe, i
							niewprowadzające w błąd informacje dotyczące wydarzenia motoryzacyjnego. Przedmiot i treść
							Ogłoszenia nie może naruszać przepisów powszechnie obowiązującego prawa lub praw osób
							trzecich. Ogłoszenie powinno pozostawać zgodne z niniejszym Regulaminem, zgodne z prawem i
							dobrymi obyczajami, mając na uwadze w szczególności poszanowanie dóbr osobistych oraz praw
							autorskich i własności intelektualnej Usługodawcy, innych Użytkowników oraz osób trzecich.
							Ogłoszenie, jego przedmiot i opis powinny odzwierciedlać rzeczywisty zamiar ogłoszeniodawcy.
						</li>
						<li>
							Jeżeli charakter publikowanych treści tego wymaga, Użytkownik obowiązany jest posiadać
							wszelkie wymagane prawa i zezwolenia do umieszczania określonych treści, materiałów i danych
							w Ogłoszeniu, w szczególności prawa autorskie lub wymagane licencje, zezwolenia i zgody na
							ich wykorzystywanie, rozpowszechnianie, udostępnianie, publikację itd., zwłaszcza prawo
							publikowania i rozpowszechniania w sieci Internet, systemie on-line oraz prawo do
							wykorzystania i rozpowszechniania wizerunku w przypadku treści, które obejmują wizerunek
							osób trzecich.
						</li>
						<li>
							Treść i zawartość Ogłoszenia powinna odpowiadać tematyce Serwisu Internetowego. Ogłoszenie
							powinno być przyporządkowane do odpowiedniej kategorii na Tablicy Ogłoszeń. Treść Ogłoszenia
							powinna być formułowana w powszechnie zrozumiałej formie i w języku polskim.
						</li>
						<li>
							Użytkownik obowiązany jest do niezamieszczania w ramach Ogłoszenia (w tym także poprzez
							zdjęcia lub inne materiały) treści bezprawnych, obraźliwych, wulgarnych, treści
							pornograficznych, treści nawołujących do szerzenia nienawiści, rasizmu i ksenofobii oraz
							treści reklamowych i informacji handlowych zachęcających do korzystania z serwisów
							konkurencyjnych wobec Serwisu Internetowego, w tym także adresów stron internetowych, nazw
							oraz logo takich stron.
						</li>
					</ol>
				</li>
				<li>
					Użytkownik dodający Ogłoszenie ponosi pełną odpowiedzialność za wszelkie rozpowszechniane,
					udostępniane, publikowane i przechowywane przez siebie w ramach Ogłoszenia treści, informacje, dane
					i materiały (w tym zdjęcia i inne materiały graficzne dołączone do Ogłoszenia).
				</li>
				<li>
					Usługodawca nie gwarantuje Użytkownikom zainteresowania ich Ogłoszeniami. Usługodawca nie
					gwarantuje, że Serwis Internetowy spełni oczekiwania organizatorów wydarzeń oraz przyczyni się do
					osiągnięcia zamierzonych przez nich celów, w szczególności w zakresie promocji i reklamy wydarzenia.
				</li>
				<li>
					Ogłoszenia (w tym również znajdujące się w nich zdjęcia i inne materiały) mają wyłącznie charakter
					informacyjny i nie stanowią oferty w rozumieniu art. 66 § 1 Kodeksu Cywilnego. Za pośrednictwem
					Serwisu Internetowego nie jest możliwe zawarcie jakiejkolwiek umowy o świadczenie usług ani innej
					umowy z autorem Ogłoszenia lub organizatorem wydarzenia motoryzacyjnego (oznacza to m. in. że
					reklamy, cenniki, katalogi i inne informacje o usługach, produktach i wydarzeniach zamieszczone na
					stronach Serwisu przez ogłoszeniodawców powinny być traktowane jako zaproszenie do zawarcia umowy).
				</li>
				<li>
					Usługodawca przed publikacją Ogłoszenia na Tablicy Ogłoszeń, a także w każdym czasie po jego
					opublikowaniu, zastrzega sobie prawo do sprawdzenia zgodności Ogłoszenia z poniższym Regulaminem. Ze
					względu jednak na skalę i ilość Ogłoszeń, ich różnorodność oraz możliwe trudności związane z
					weryfikacją ich treści, Usługodawca nie może zagwarantować, że każde Ogłoszenie widoczne w
					Kalendarz- motoryzacyjny.pl jest rzetelne, autentyczne i zgodne z poniższym Regulaminem. Usługodawca
					przestrzega art. 15 ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U.
					2002 nr 144, poz. 1204 ze zm.), zgodnie z którym nie jest obowiązany do sprawdzania przekazywanych,
					przechowywanych lub udostępnianych w Serwisie danych Użytkowników. Nadto, Usługodawca dokona
					niezwłocznej zmiany lub usunięcia Ogłoszenia, jeżeli otrzyma urzędowe zawiadomienie lub uzyska inną
					wiarygodną wiadomość na temat bezprawności Ogłoszenia, jego przedmiotu bądź związanej z nim
					działalności.
				</li>
			</ol>
			<h2>6) WARUNKI KORZYSTANIA Z POZOSTAŁYCH USŁUG ELEKTRONICZNYCH</h2>
			<ol>
				<li>
					<b>Korzystanie z Formularza Kontaktowego</b> możliwe jest po przejściu do zakładki{' '}
					<i>
						"<b>Kontakt</b>"
					</i>{' '}
					widocznej na stronie Serwisu Internetowego i wymaga dokonania dwóch kolejnych kroków przez
					Użytkownika - (1) wypełnienia Formularza Kontaktowego danymi wskazanymi jako obowiązkowe i (2)
					kliknięcia na stronie Serwisu Internetowego po wypełnieniu Formularza Kontaktowego pola{' '}
					<i>
						"<b>Wyślij</b>"
					</i>{' '}
					- do tego momentu istnieje możliwość samodzielnej modyfikacji wprowadzanych danych. W Formularzu
					Kontaktowym niezbędne jest podanie przez Użytkownika co najmniej treści wiadomości kierowanej do
					Usługodawcy, a także następujących danych dotyczących Użytkownika: imię oraz adres poczty
					elektronicznej, na który Usługodawca będzie mógł udzielić odpowiedzi.
				</li>
				<li>
					Usługa Elektroniczna Formularz Kontaktowy świadczona jest nieodpłatnie oraz ma charakter jednorazowy
					i ulega zakończeniu z chwilą wysłania wiadomości za jego pośrednictwem albo z chwilą wcześniejszego
					zaprzestania wysyłania wiadomości za jego pośrednictwem przez Użytkownika.
				</li>
				<li>
					<b>Korzystanie z Wyszukiwarki</b> możliwe jest po przejściu do zakładki{' '}
					<i>
						"<b>Szukaj wydarzenia</b>"
					</i>{' '}
					widocznej na stronie Serwisu Internetowego. Wyszukiwarka umożliwia Użytkownikowi przede wszystkim
					wyszukanie Ogłoszenia właściwego dla wybranej lokalizacji, frazy lub kategorii wydarzenia.
					Użytkownik może także skorzystać z dodatkowych opcji filtrowania wyników wyszukiwania. Po
					skorzystaniu z Wyszukiwarki przedstawione zostają wyniki wyszukiwania, obejmujące listę Ogłoszeń na
					Tablicy Ogłoszeń, które odpowiadają zastosowanym przez Użytkownika kryteriom wyszukiwania. Dodatkowe
					informacje o plasowaniu Ogłoszeń na Tablicy Ogłoszeń znajdują się w punkcie 9. Regulaminu.
				</li>
				<li>
					Skorzystanie z Wyszukiwarki ma zawsze charakter nieodpłatny, jednorazowy i ulega zakończeniu po
					skorzystaniu z niej przez Użytkownika albo z chwilą wcześniejszego opuszczenia strony Serwisu
					Internetowego.
				</li>
			</ol>
			<h2>7) USŁUGI REKLAMOWE</h2>
			<ol>
				<li>
					Usługodawca umożliwia Użytkownikom także skorzystanie z odpłatnych usług dodatkowych, które polegają
					na wynajmie określonej powierzchni reklamowej na stronach Serwisu Internetowego w celu wyświetlania
					treści reklamowych Użytkownika. Szczegółowe informacje dotyczące formy, zakresu i kosztów usług
					dodatkowych stanowią każdorazowo przedmiot indywidualnych uzgodnień między zainteresowanym
					Użytkownikiem i Usługodawcą.
				</li>
				<li>
					Celem skorzystania z usług dodatkowych w zakresie zamieszczenia reklam lub banerów reklamowych w
					Serwisie Internetowym, niezbędny jest bezpośredni kontakt z Usługodawcą z użyciem Formularza
					Kontaktowego lub danych kontaktowych wskazanych na wstępie Regulaminu.
				</li>
				<li>
					Umowa o świadczenie usług reklamowych opisanych w niniejszym punkcie Regulaminu zawierana jest poza
					Serwisem Internetowym, w wyniku zgodnych ustaleń Usługodawcy i Użytkownika. O ile strony nie
					postanowią inaczej, do umowy o świadczenie usług reklamowych mają zastosowanie odpowiednio
					postanowienia niniejszego Regulaminu, szczególnie zasady dodawania Ogłoszeń wskazane w punkcie 5.6
					Regulaminu.
				</li>
			</ol>
			<h2>8) OGRANICZENIE, ZAWIESZENIE I ZAKOŃCZENIE ŚWIADCZENIA USŁUG</h2>
			<ol>
				<li>
					Użytkownik może zrezygnować z korzystania z Kalendarz-motoryzacyjny.pl oraz poszczególnych Usług
					Elektronicznych w każdym czasie i bez podania przyczyny, zgodnie z warunkami korzystania z nich
					wskazanymi w niniejszym Regulaminie.
				</li>
				<li>
					W przypadku otrzymania urzędowego zawiadomienia lub uzyskania innej wiarygodnej wiadomości, że
					Ogłoszenie dostępne w Serwisie albo związana z nim działalność mają charakter bezprawny,
					Usługodawca, działając na podstawie art. 14 ust. 1 ustawy z dnia 18 lipca 2002 r. o świadczeniu
					usług drogą elektroniczną (Dz.U. 2002 nr 144, poz. 1204 ze zm.), upoważniony będzie do niezwłocznego
					usunięcia lub zmiany Ogłoszenia bez uprzedzenia.
				</li>
				<li>
					Niezależnie od pkt. 8.2 wyżej, Usługodawca zastrzega sobie prawo ograniczenia, zawieszenia, a w
					ostateczności także zakończenia świadczenia wszystkich swoich usług na rzecz danego Użytkownika, w
					tym również w odniesieniu do Ogłoszeń dodanych przez tego Użytkownika w Kalendarz-motoryzacyjny.pl
					lub usług reklamowych, o których mowa w pkt. 7 Regulaminu. Działania, o których mowa powyżej,
					Usługodawca podejmuje tylko wtedy i w takim zakresie, w jakim są one konieczne, przy zachowaniu
					następujących warunków:
					<ol type='a'>
						<li>
							Podstawami ograniczenia, zawieszenia, a w ostateczności także zakończenia świadczenia usług
							na rzecz danego Użytkownika mogą być:
							<ol type='i'>
								<li>
									podanie przez Użytkownika w ramach Serwisu niepełnych (w przypadku, kiedy są
									obowiązkowe) lub nieprawdziwych danych kontaktowych (np. nazwa organizatora, adres
									e-mail);
								</li>
								<li>
									wystawienie Ogłoszenie (w tym jego przedmiot i opis) przez Użytkownika narusza
									przepisy powszechnie obowiązującego prawa, dobre obyczaje, zasady współżycia
									społecznego lub prawa osób trzecich;
								</li>
								<li>
									wystawienie Ogłoszenia (w tym jego przedmiot i opis) przez Użytkownika narusza
									warunki wystawiania Ogłoszeń wskazane w Regulaminie;
								</li>
								<li>
									przesyłanie za pośrednictwem Serwisu treści o charakterze obraźliwym lub spamu, w
									tym również treści o charakterze reklamowym, ogłoszeniowym lub informacji handlowych
									zachęcających do korzystania z serwisów konkurencyjnych wobec Serwisu Internetowego,
									w tym także adresów stron internetowych, nazw oraz logo takich stron;
								</li>
								<li>
									wystawianie przez Użytkownika Ogłoszeń w innym celu niż wynikający z treści i
									przedmiotu Ogłoszenia;
								</li>
								<li>
									działania danego Użytkownika grożą bezprawnym naruszeniem renomy Usługodawcy i jego
									Serwisu Internetowego;
								</li>
								<li>
									działania danego Użytkownika grożą zakłóceniem prawidłowego funkcjonowania Serwisu
									Internetowego lub naruszeniem bezpieczeństwa pozostałych Użytkowników podczas
									korzystania przez nich z Serwisu;
								</li>
								<li>
									zaleganie przez Użytkownika z jakimikolwiek wymagalnymi należnościami na rzecz
									Usługodawcy, np. z tytułu usług reklamowych Serwisu;
								</li>
								<li>
									obowiązek prawny lub regulacyjny, na podstawie którego Usługodawca zobowiązany jest
									do zakończenia świadczenia całości swoich usług na rzecz danego Użytkownika;
								</li>
								<li>
									skorzystanie przez Usługodawcę z prawa do zakończenia świadczenia usług z
									nadrzędnego powodu, który wynika z prawa krajowego zgodnego z prawem Unii
									Europejskiej;
								</li>
								<li>wielokrotne naruszenie Regulaminu przez Użytkownika.</li>
							</ol>
						</li>
						<li>
							Ograniczenie lub zawieszenie świadczenia usług rozumiane jest jako działania zmierzające do
							ograniczenia lub zawieszenia dostępu do poszczególnych usług przez Usługodawcę w Kalendarz-
							motoryzacyjny.pl. Działania te mogą polegać np. na wycofaniu wszystkich jego Ogłoszeń,
							odmowie publikacji nowych Ogłoszeń, odmowie wynajmu przestrzeni reklamowej w Serwisie
							Internetowym lub zablokowaniu dostępu do wybranych funkcjonalności i zasobów Serwisu.
						</li>
						<li>
							Ograniczenie lub zawieszenie świadczenia usług może nastąpić na czas określony wskazany
							przez Usługodawcę lub na czas nieokreślony (do czasu ustąpienia przyczyny zastosowanego
							ograniczenia lub zawieszenia). W czasie ograniczenia lub zawieszenia usług Użytkownik
							obowiązany jest podjąć działania mające na celu usunięcie przyczyny, która była podstawą
							decyzji o ograniczeniu lub zawieszeniu, a po ich usunięciu obowiązany jest poinformować o
							tym niezwłocznie Usługodawcę. Usługodawca cofa nałożone ograniczenia lub zawieszenia
							niezwłocznie po upływie okresu ich zastosowania, a w przypadku decyzji podjętej na czas
							nieokreślony - niezwłocznie, lecz nie później niż w ciągu 10 Dni Roboczych od otrzymania
							informacji o ustaniu przyczyny ich zastosowania.
						</li>
						<li>
							Usługodawca podejmuje decyzję o wyborze sposobu i zakresu ograniczenia, zawieszenia lub
							zakończenia świadczenia swoich usług na rzecz danego Użytkownika w sposób rozsądny,
							konieczny i proporcjonalny do charakteru i zakresu zaistnienia podstaw do podjęcia
							określonej decyzji oraz jej konsekwencji dla danego Użytkownika. Usługodawca przed podjęciem
							decyzji w miarę możliwości wzywa Użytkownika do zaprzestania naruszeń, a dopiero jeżeli
							wezwanie okaże się bezskuteczne lub niemożliwe - Usługodawca może podjąć stosowną decyzję.
							Usługodawca w pierwszej kolejności zobowiązuje się podjąć decyzję o ograniczeniu, a
							następnie dopiero o zawieszeniu świadczenia usług, jeżeli samo ograniczenie okaże się
							niewystarczające lub niecelowe. Zakończenie świadczenia usług jest traktowane jako
							ostateczne rozwiązanie z Użytkownikiem umowy o korzystanie z usług
							Kalendarz-motoryzacyjny.pl i jego podstawą mogą być jedynie przyczyny wskazane w pkt. 8.3
							lit. a) ppkt. ix-xi Regulaminu. Zakończenie świadczenia usług pociąga za sobą usunięcie
							wszystkich Ogłoszeń lub reklam danego Użytkownika.
						</li>
						<li>
							W przypadku podjęcia decyzji o ograniczeniu lub zawieszeniu świadczenia usług na rzecz
							danego Użytkownika Usługodawca przekazuje temu Użytkownikowi - zanim decyzja o ograniczeniu
							lub zawieszeniu stanie się skuteczna lub w momencie, w którym staje się ona skuteczna -
							uzasadnienie tej decyzji w drodze wiadomości przesłanej drogą poczty elektronicznej.
						</li>
						<li>
							W przypadku gdy Usługodawca podejmuje decyzję o zakończeniu świadczenia wszystkich swoich
							usług na rzecz danego Użytkownika, przekazuje temu Użytkownikowi, co najmniej 30 dni przed
							dniem, w którym decyzja o zakończeniu świadczenia usług staje się skuteczna, uzasadnienie
							tej decyzji w drodze wiadomości przesłanej drogą poczty elektronicznej. Okres powiadomienia,
							o którym mowa w zdaniu poprzednim, nie ma zastosowania w przypadku gdy Usługodawca:
							<ol type='i'>
								<li>
									podlega obowiązkowi prawnemu lub regulacyjnemu, na podstawie którego zobowiązany
									jest do zakończenia świadczenia całości swoich usług na rzecz danego Użytkownika w
									sposób uniemożliwiający mu dotrzymanie tego okresu powiadomienia,
								</li>
								<li>
									korzysta z prawa do zakończenia świadczenia usług z nadrzędnego powodu, który wynika
									z prawa krajowego zgodnego z prawem Unii Europejskiej,
								</li>
								<li>
									może wykazać, że dany Użytkownik wielokrotnie naruszył Regulamin, co skutkuje
									zakończeniem świadczenia całości danych Usług Elektronicznych.
								</li>
							</ol>
							W powyższych przypadkach, gdy okres powiadomienia nie ma zastosowania, Usługodawca
							przekazuje danemu Użytkownikowi, bez zbędnej zwłoki, uzasadnienie tej decyzji co najmniej w
							drodze wiadomości przesłanej drogą poczty elektronicznej.
						</li>
						<li>
							Uzasadnienie decyzji Usługodawcy o ograniczeniu, zawieszeniu lub zakończeniu świadczenia
							usług musi zawierać odniesienie do konkretnych faktów lub okoliczności - w tym treści
							powiadomień otrzymanych od stron trzecich, jeśli ma to zastosowanie - które doprowadziły do
							podjęcia przez Usługodawcę danej decyzji, a także odniesienie do mających zastosowanie
							podstaw tej decyzji, o których mowa w punkcie 8.3 lit. a) Regulaminu. Usługodawca nie musi
							podawać (1) uzasadnienia w przypadku, gdy podlega on prawnemu lub regulacyjnemu obowiązkowi
							nieprzedstawiania konkretnych faktów lub okoliczności albo (2) odniesienia do mającej
							zastosowanie podstawy lub mających zastosowanie podstaw, w przypadku gdy może wykazać, że
							dany Użytkownik wielokrotnie naruszył Regulamin, co skutkuje zakończeniem świadczenia
							całości danych usług.
						</li>
						<li>
							W przypadku ograniczenia, zawieszenia lub zakończenia usług, Usługodawca zapewnia
							Użytkownikowi możliwość wyjaśnienia faktów i okoliczności w ramach postępowania
							reklamacyjnego, o którym mowa w pkt. 12 Regulaminu. W przypadku cofnięcia decyzji o
							ograniczeniu, zawieszeniu lub zakończeniu usług przez Usługodawcę, przywraca on bez zbędnej
							zwłoki Użytkownikowi dostęp do tych usług, w tym również przywracając mu dostęp do danych
							osobowych lub innych danych, który wynikał z korzystania przez niego z usług zanim decyzja o
							tym ograniczeniu, zawieszeniu lub zakończeniu stała się skuteczna.
						</li>
					</ol>
				</li>
				<li>
					Zakończenie świadczenia usług w jakimkolwiek trybie pozostaje bez uszczerbku dla możliwości
					przechowywania danych Użytkownika przez okres niezbędny dla realizacji pozostałych celów
					przetwarzania (innych niż świadczenie usług w Kalendarz-motoryzacyjny.pl) zgodnych z polityką
					prywatności Serwisu Internetowego.
				</li>
			</ol>
			<h2>9) PLASOWANIE OGŁOSZEŃ</h2>
			<ol>
				<li>
					Usługodawca może stosować na stronach Kalendarz-motoryzacyjny.pl plasowanie Ogłoszeń, co polega na
					przypisywaniu określonym Ogłoszeniom wyższej pozycji na Tablicy Ogłoszeń niż pozostałym Ogłoszeniom
					zamieszczonym w Serwisie Internetowym.
				</li>
				<li>
					Ogłoszenia plasowane są domyślnie w formie listy lub kalendarza (w zależności od wyboru Użytkownika)
					według terminu wydarzenia, którego dotyczą, w kolejności od najwcześniejszego do najpóźniejszego
					wydarzenia. Pozostałe parametry plasowania uzależnione są od zastosowanych przez Użytkownika
					kryteriów wyszukiwania Ogłoszeń:
					<ol type='a'>
						<li>
							zastosowanie w opisie Ogłoszenia wyrażeń adekwatnych do wyszukiwanych przez Użytkownika;
						</li>
						<li>
							przyporządkowanie Ogłoszenia do właściwej lokalizacji zgodnej z wyszukiwaną przez
							Użytkownika;
						</li>
						<li>jakość prezentacji Ogłoszenia, cechy wizualne oraz jego opis;</li>
						<li>dodanie zdjęć w Ogłoszeniu, w tym również liczba dodanych zdjęć.</li>
					</ol>
				</li>
				<li>
					Parametry determinujące plasowanie Ogłoszeń w Kalendarz-motoryzacyjny.pl mają za zadanie z jednej
					strony ułatwić i przyspieszyć Użytkownikowi znalezienie interesującego go Ogłoszenia, a z drugiej
					strony zwiększyć szanse organizatorów wydarzeń na dotarcie ze swoimi wydarzeniami do docelowej grupy
					odbiorców.
				</li>
				<li>
					Usługodawca pozwala na promowanie Ogłoszeń na stronie Kalendarz-motoryzacyjny.pl poprzez ich
					zapętlone wyświetlanie w sekcji{' '}
					<i>
						"<b>Promowane wydarzenia</b>"
					</i>{' '}
					na stronie Serwisu. Promocja Ogłoszeń w sposób określony w zdaniu poprzednim jest możliwa w zamian
					za zobowiązanie zainteresowanego Użytkownika do wyświetlania promocji Serwisu Internetowego w swoich
					kanałach komunikacji (np. media społecznościowe, strona internetowa). Szczegółowe zasady promowania
					Ogłoszeń, w tym warunki promocji Kalendarz-motoryzacyjny.pl przez zainteresowanego Użytkownika,
					uzgadniane są przez strony poza Serwisem Internetowym.
				</li>
				<li>
					Serwis Internetowy prezentuje Użytkownikom także propozycje najnowszych Ogłoszeń w sekcji{' '}
					<i>
						"<b>Ostatnio dodane wydarzenia</b>"
					</i>
					. Ogłoszenia prezentowane w tej sekcji dobierane są automatycznie na podstawie kryterium dodania
					Ogłoszenia w Serwisie, tj. od najnowszego do najstarszego.
				</li>
			</ol>
			<h2>10) DOSTĘP DO DANYCH W KALENDARZ-MOTORYZACYJNY.PL</h2>
			<ol>
				<li>
					Usługodawca Kalendarz-motoryzacyjny.pl i Użytkownicy podczas korzystania z Serwisu Internetowego
					mogą uzyskać dostęp do różnych kategorii danych osobowych lub innych danych, które Użytkownicy
					dostarczają na potrzeby korzystania z Serwisu lub które są generowane w wyniku korzystania przez
					nich z Serwisu.
				</li>
				<li>
					Użytkownicy nie otrzymują za pośrednictwem Serwisu wglądu do danych osobowych innych Użytkowników, w
					tym dodających Ogłoszenia, z zastrzeżeniem ewentualnych danych osobowych organizatorów wydarzeń
					podanych w treści Ogłoszenia. Dane, które są dostępne dla wszystkich Użytkowników w Serwisie,
					obejmują wyłącznie informacje o wydarzeniach podane w Ogłoszeniach, w szczególności nazwy
					organizatorów, lokalizacji i terminów wydarzeń, ich kategorii, cen biletów itd. Usługodawca
					Kalendarz-motoryzacyjny.pl posiada wyłączny dostęp do wszystkich pozostałych danych dostarczanych
					oraz generowanych w wyniku korzystania przez Użytkowników z Serwisu Internetowego, w tym również ich
					adresów e-mail podawanych w trakcie dodawania Ogłoszeń.
				</li>
			</ol>
			<h2>11) KONTAKT Z USŁUGODAWCĄ</h2>
			<p>
				Zasadniczą formą bieżącej komunikacji na odległość z Usługodawcą jest Formularz Kontaktowy oraz poczta
				elektroniczna (e-mail: {email()}), za pośrednictwem których można wymieniać z Usługodawcą informacje
				dotyczące korzystania z Kalendarz-motoryzacyjny.pl. Użytkownicy mogą kontaktować się z Usługodawcą także
				na inne dopuszczalne prawem sposoby, korzystając z danych kontaktowych wskazanych na wstępie Regulaminu.
			</p>
			<h2>12) PROCEDURA ROZPATRYWANIA REKLAMACJI</h2>
			<ol>
				<li>
					Niniejszy punkt 12. Regulaminu określa procedurę rozpatrywania reklamacji wspólną dla wszystkich
					reklamacji składanych do Usługodawcy, w szczególności reklamacji dotyczących Usług Elektronicznych,
					usług reklamowych, o których mowa w pkt. 7 Regulaminu oraz pozostałych reklamacji związanych z
					działaniem Usługodawcy lub Serwisu Internetowego.
				</li>
				<li>
					Poniżej wskazane są dodatkowe informacje dotyczące przewidzianej przez prawo odpowiedzialności
					Usługodawcy:
					<ol type='a'>
						<li>
							Podstawa i zakres odpowiedzialności Usługodawcy wobec Użytkownika, gdy jego usługa, w tym
							Usługa Elektroniczna świadczona jest w sposób nieprawidłowy, określone są przez powszechnie
							obowiązujące przepisy prawa, w szczególności w Kodeksie Cywilnym, z uwzględnieniem
							ewentualnych umownych modyfikacji odpowiedzialności Usługodawcy wynikających z niniejszego
							Regulaminu, w szczególności z punktu 15. Regulaminu.
						</li>
						<li>
							Dodatkowo, w przypadku reklamacji obejmującej treści cyfrowe lub usługi cyfrowe na podstawie
							umowy zawartej przez Użytkownika będącego Konsumentem lub Przedsiębiorcą na Prawach
							Konsumenta z Usługodawcą od dnia 1. stycznia 2023 r. lub przed tym dniem, jeżeli
							dostarczanie takiej treści lub usługi miało nastąpić lub nastąpiło po tym dniu, zastosowanie
							mają przepisy Ustawy o Prawach Konsumenta w brzmieniu obowiązującym od dnia 1. stycznia 2023
							r., w szczególności art. 43h - 43q Ustawy o Prawach Konsumenta. Przepisy te określają w
							szczególności podstawę i zakres odpowiedzialności Usługodawcy względem Konsumenta oraz
							Przedsiębiorcy na Prawach Konsumenta w razie braku zgodności treści cyfrowej lub usługi
							cyfrowej z zawartą umową.
						</li>
					</ol>
				</li>
				<li>
					Reklamacje związane z działaniem Serwisu Internetowego, w tym jego Usług Elektronicznych, można
					złożyć na przykład drogą poczty elektronicznej (mailowo) na adres: {email()} lub pisemnie na adres:
					ul. Szkolna 43a, 64-000 Turew.
				</li>
				<li>
					Usługodawca zaleca podanie w opisie reklamacji: (1) informacji i okoliczności dotyczących przedmiotu
					reklamacji, w szczególności rodzaju i daty wystąpienia nieprawidłowości; (2) oczekiwań Użytkownika;
					oraz (3) danych kontaktowych składającego reklamację - ułatwi to i przyspieszy rozpatrzenie
					reklamacji przez Usługodawcę. Wymogi podane w zdaniu poprzednim mają formę jedynie zalecenia i nie
					wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.
				</li>
				<li>
					W razie zmiany podanych danych kontaktowych przez składającego reklamację w trakcie rozpatrywania
					reklamacji zobowiązany jest on do powiadomienia o tym Usługodawcy.
				</li>
				<li>
					Do reklamacji mogą zostać załączone przez składającego reklamację dowody (np. zdjęcia, dokumenty lub
					zrzuty ekranu) związane z przedmiotem reklamacji. Usługodawca może także zwrócić się do składającego
					reklamację z prośbą o podanie dodatkowych informacji lub przesłanie dowodów (np. zdjęcia), jeżeli
					ułatwi to i przyspieszy rozpatrzenie reklamacji przez Usługodawcę.
				</li>
				<li>
					Usługodawca ustosunkuje się do reklamacji niezwłocznie, nie później niż w terminie 14 dni
					kalendarzowych od dnia jej otrzymania.
				</li>
			</ol>
			<h2>13) USTAWOWE PRAWO ODSTĄPIENIA OD UMOWY</h2>
			<ol>
				<li>
					Konsument, który zawarł umowę na odległość, może w terminie 14 dni kalendarzowych odstąpić od niej
					bez podawania przyczyny i bez ponoszenia kosztów, z zastrzeżeniem wyjątków wskazanych w pkt. 13.4
					oraz kosztów określonych w pkt. 13.5 Regulaminu.
				</li>
				<li>
					Bieg terminu do odstąpienia od umowy, której przedmiotem są usługi, w tym Usługi Elektroniczne,
					rozpoczyna się od dnia zawarcia umowy.
				</li>
				<li>
					Do zachowania terminu na odstąpienie od umowy wystarczy wysłanie oświadczenia przed jego upływem.
					Oświadczenie o odstąpieniu od umowy może zostać złożone na przykład w formie elektronicznej za
					pośrednictwem poczty elektronicznej na adres: {email()} lub pisemnie na adres: ul. Szkolna 43a,
					64-000 Turew. Konsument może w tym celu skorzystać ze wzoru oświadczenia o odstąpieniu od umowy,
					który stanowi załącznik nr 2 do Ustawy o Prawach Konsumenta, jednak nie jest to obowiązkowe.
				</li>
				<li>
					Prawo odstąpienia od umowy zawartej na odległość nie przysługuje Konsumentowi m. in. w odniesieniu
					do umów: (1) o świadczenie usług, za które Konsument jest zobowiązany do zapłaty ceny, jeżeli
					Usługodawca wykonał w pełni usługę za wyraźną i uprzednią zgodą Konsumenta, który został
					poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Usługodawcę utraci
					prawo odstąpienia od umowy, i przyjął to do wiadomości; (2) o dostarczanie treści cyfrowych, które
					nie są zapisane na nośniku materialnym, za które Konsument jest zobowiązany do zapłaty ceny, jeżeli
					Usługodawca rozpoczął świadczenie za wyraźną i uprzednią zgodą Konsumenta, który został
					poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Usługodawcę utraci
					prawo odstąpienia od umowy, i przyjął to do wiadomości, a Usługodawca przekazał Konsumentowi
					potwierdzenie, o którym mowa w art. 15 ust. 1 i 2 albo art. 21 ust. 1 Ustawy o Prawach Konsumenta.
				</li>
				<li>
					Skutki i koszty związane z wykonaniem prawa odstąpienia od umowy:
					<ol type='a'>
						<li>
							W przypadku odstąpienia od umowy, której przedmiotem są treści cyfrowe lub usługi cyfrowe -
							Usługodawca od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od umowy nie może
							wykorzystywać treści innych niż dane osobowe dostarczone lub wytworzone przez Konsumenta w
							trakcie korzystania z treści cyfrowych lub usługi cyfrowej - dostarczonych przez
							Usługodawcę, z wyjątkiem treści, które: (1) są użyteczne wyłącznie w związku z treścią
							cyfrową lub usługą cyfrową, które stanowiły przedmiot umowy; (2) dotyczą wyłącznie
							aktywności Konsumenta w trakcie korzystania z treści cyfrowych lub usługi cyfrowej
							dostarczonych przez Usługodawcę; (3) zostały połączone przez Usługodawcę z innymi danymi i
							nie mogą zostać z nich wydzielone lub mogą zostać wydzielone jedynie przy nakładzie
							niewspółmiernych wysiłków; (4) zostały wytworzone przez Konsumenta wspólnie z innymi
							Konsumentami, którzy nadal mogą z nich korzystać. Z wyjątkiem przypadków, o których mowa
							wyżej w pkt (1)-(3), Usługodawca na żądanie Konsumenta udostępnia mu treści inne niż dane
							osobowe, które zostały dostarczone lub wytworzone przez Konsumenta w trakcie korzystania z
							treści cyfrowych lub usługi cyfrowej dostarczonych przez Usługodawcę. W przypadku
							odstąpienia od umowy Usługodawca może uniemożliwić Konsumentowi dalsze korzystanie z treści
							cyfrowych lub usługi cyfrowej, w szczególności przez uniemożliwienie Konsumentowi dalszego
							dostępu do treści cyfrowych lub usługi cyfrowej, co nie ma wpływu na uprawnienia Konsumenta,
							o których mowa w zdaniu poprzednim. Konsument ma prawo odzyskać treści cyfrowe od
							Usługodawcy nieodpłatnie, bez przeszkód ze strony Usługodawcy, w rozsądnym terminie i
							powszechnie używanym formacie przeznaczonym do odczytu maszynowego.
						</li>
						<li>
							W przypadku odstąpienia od umowy, której przedmiotem są treści cyfrowe lub usługi cyfrowe
							dostarczane przez Usługodawcę, Konsument jest zobowiązany zaprzestać korzystania z treści
							cyfrowej lub usługi cyfrowej i udostępniania ich osobom trzecim.
						</li>
						<li>
							W przypadku odstąpienia od umowy, do której nie ma zastosowania żadne z wyłączeń wskazanych
							w punkcie 13.4 wyżej, Usługodawca ma obowiązek niezwłocznie, nie później niż w terminie 14
							dni kalendarzowych od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od umowy,
							zwrócić Konsumentowi wszystkie dokonane przez niego płatności. Usługodawca dokonuje zwrotu
							płatności przy użyciu takiego samego sposobu płatności, jakiego użył Konsument, chyba że
							Konsument wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z
							żadnymi kosztami.
						</li>
						<li>
							W przypadku usługi, której wykonywanie - na wyraźne żądanie Konsumenta - rozpoczęło się
							przed upływem terminu do odstąpienia od umowy, Konsument, który wykonuje prawo odstąpienia
							od umowy po zgłoszeniu takiego żądania, ma obowiązek zapłaty za świadczenia spełnione do
							chwili odstąpienia od umowy. Kwotę zapłaty oblicza się proporcjonalnie do zakresu
							spełnionego świadczenia, z uwzględnieniem uzgodnionej w umowie ceny lub wynagrodzenia.
							Jeżeli cena lub wynagrodzenie są nadmierne, podstawą obliczenia tej kwoty jest wartość
							rynkowa spełnionego świadczenia.
						</li>
					</ol>
				</li>
				<li>
					Zawarte w niniejszym punkcie 13. Regulaminu postanowienia dotyczące Konsumenta stosuje się również
					do umów zawartych z Użytkownikiem będącym Przedsiębiorcą na Prawach Konsumenta.
				</li>
			</ol>
			<h2>
				14) POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA ROSZCZEŃ ORAZ ZASADY DOSTĘPU DO TYCH
				PROCEDUR
			</h2>
			<ol>
				<li>
					Metody rozwiązywania sporów bez udziału sądu to między innymi (1) umożliwienie zbliżenia stanowisk
					stron, np. poprzez mediację; (2) zaproponowanie rozwiązania sporu, np. poprzez koncyliację oraz (3)
					rozstrzygnięcie sporu i narzucenie stronom jego rozwiązania, np. w ramach arbitrażu (sąd polubowny).
					Szczegółowe informacje dotyczące możliwości skorzystania przez Konsumenta z pozasądowych sposobów
					rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur oraz przyjazna
					wyszukiwarka podmiotów zajmujących się polubownym rozwiązywaniem sporów dostępne są na stronie
					internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem:{' '}
					<a href='https://polubowne.uokik.gov.pl/'>https://polubowne.uokik.gov.pl/</a>.
				</li>
				<li>
					Przy Prezesie Urzędu Ochrony Konkurencji i Konsumentów działa także punkt kontaktowy, którego
					zadaniem jest między innymi udzielanie Konsumentom informacji w sprawach dotyczących pozasądowego
					rozwiązywania sporów konsumenckich. Z punktem Konsument może się skontaktować: (1) telefonicznie -
					dzwoniąc pod numer 22 55 60 332 lub 22 55 60 333; (2) za pomocą poczty elektronicznej - przesyłając
					wiadomość na adres: <a href='mailto:kontakt.adr@uokik.gov.pl'>kontakt.adr@uokik.gov.pl</a> lub (3)
					pisemnie lub osobiście - w Centrali Urzędu przy placu Powstańców Warszawy 1 w Warszawie (00-030).
				</li>
				<li>
					Konsument posiada następujące przykładowe możliwości skorzystania z pozasądowych sposobów
					rozpatrywania reklamacji i dochodzenia roszczeń: (1) wniosek o rozstrzygnięcie sporu do stałego
					polubownego sądu konsumenckiego (więcej informacji na stronie:{' '}
					<a href='http://www.spsk.wiih.org.pl/'>http://www.spsk.wiih.org.pl/</a>); (2) wniosek w sprawie
					pozasądowego rozwiązania sporu do wojewódzkiego inspektora Inspekcji Handlowej (więcej informacji na
					stronie inspektora właściwego ze względu na miejsce wykonywania działalności gospodarczej przez
					Usługodawcę); oraz (3) pomoc powiatowego (miejskiego) rzecznika Konsumentów lub organizacji
					społecznej, do której zadań statutowych należy ochrona Konsumentów (m. in. Federacja Konsumentów,
					Stowarzyszenie Konsumentów Polskich). Porady udzielane są między innymi mailowo pod adresem{' '}
					<a href='mailto:porady@dlakonsumentow.pl'>porady@dlakonsumentow.pl</a> oraz pod numerem infolinii
					konsumenckiej 801 440 220 (infolinia czynna w dni robocze, w godzinach 8:00 - 18:00, opłata za
					połączenie wg taryfy operatora).
				</li>
				<li>
					Pod adresem <a href='http://ec.europa.eu/odr'>http://ec.europa.eu/odr</a> dostępna jest platforma
					internetowego systemu rozstrzygania sporów konsumenckich na szczeblu unijnym (platforma ODR).
					Platforma ODR stanowi interaktywną i wielojęzyczną stronę internetową z punktem kompleksowej obsługi
					dla Konsumentów i przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu dotyczącego
					zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług
					(więcej informacji na stronie samej platformy lub pod adresem internetowym Urzędu Ochrony
					Konkurencji i Konsumentów:{' '}
					<a href='https://polubowne.uokik.gov.pl/platforma-odr,161,pl.html'>
						https://polubowne.uokik.gov.pl/platforma-odr,161,pl.html
					</a>
					).
				</li>
			</ol>
			<h2>15) POSTANOWIENIA DOTYCZĄCE PRZEDSIĘBIORCÓW</h2>
			<ol>
				<li>
					Niniejszy punkt 15. Regulaminu i wszystkie postanowienia w nim zawarte są skierowane i tym samym
					wiążą wyłącznie Użytkownika niebędącego Konsumentem, a także niebędącego Przedsiębiorcą na Prawach
					Konsumenta.
				</li>
				<li>
					Usługodawcy przysługuje prawo odstąpienia od umowy o świadczenie usługi, w tym Usługi
					Elektronicznej, zawartej z Użytkownikiem na podstawie niniejszego Regulaminu w terminie 14 dni
					kalendarzowych od dnia jej zawarcia. Odstąpienie od umowy w tym wypadku może nastąpić bez podania
					przyczyny i nie rodzi po stronie Użytkownika żadnych roszczeń w stosunku do Usługodawcy.
				</li>
				<li>
					Rezygnacja Użytkownika z odpłatnych usług, w tym Usług Elektronicznych, po ich zakupie nie uprawnia
					go do otrzymania zwrotu płatności za niewykorzystany czas ich trwania ani nie zwalnia z obowiązku
					zapłaty za rozpoczęte świadczenia, niezależnie od przyczyny takiej rezygnacji.
				</li>
				<li>
					Usługodawca jest uprawniony w każdym czasie podjąć działania zmierzające do zweryfikowania
					prawdziwości, rzetelności oraz precyzyjności informacji podanych przez Użytkownika, w szczególności
					podanych w Ogłoszeniu. W zakresie weryfikacji Usługodawca uprawniony jest m. in. do zażądania od
					Użytkownika przesłania skanu posiadanych certyfikatów, zaświadczeń lub innych dokumentów niezbędnych
					do dokonania weryfikacji, a także do wstrzymania publikacji Ogłoszenia lub świadczenia innych usług
					na czas trwania tej weryfikacji.
				</li>
				<li>
					Usługodawca ponosi odpowiedzialność w stosunku do Użytkownika, bez względu na jej podstawę prawną,
					jedynie do wysokości uiszczonych za okres ostatniego roku opłat za korzystanie z Serwisu
					Internetowego, nie więcej jednak niż do kwoty 500,00 (pięciuset) zł. Ograniczenie kwotowe, o którym
					mowa w zdaniu poprzednim, ma zastosowanie do wszelkich roszczeń kierowanych przez Użytkownika w
					stosunku do Usługodawcy, w tym także w przypadku niekorzystania z żadnej odpłatnej usługi lub
					niezwiązanych z usługami Kalendarz-motoryzacyjny.pl. Usługodawca ponosi odpowiedzialność w stosunku
					do Użytkownika tylko za typowe i rzeczywiście poniesione szkody, przewidywalne w momencie zawarcia
					umowy o korzystanie z usług Kalendarz-motoryzacyjny.pl, z wyłączeniem utraconych korzyści.
				</li>
				<li>
					Usługodawca nie ponosi wobec Użytkownika odpowiedzialności za szkody i niewypełnienie zobowiązań
					wynikające z działania zdarzenia o charakterze siły wyższej (np. włamania hackerskie, katastrofy
					naturalne, powodzie, pożary, trzęsienia ziemi, epidemie, rozruchy, zamieszki i wojny) lub wszelkich
					innych przyczyn pozostających poza kontrolą Usługodawcy.
				</li>
				<li>
					Wszelkie spory powstałe pomiędzy Usługodawcą a Użytkownikiem zostają poddane sądowi właściwemu ze
					względu na siedzibę Usługodawcy.
				</li>
			</ol>
			<h2>16) PRAWA AUTORSKIE DO KALENDARZ-MOTORYZACYJNY.PL</h2>
			<ol>
				<li>
					Prawa autorskie oraz prawa własności intelektualnej do Serwisu Internetowego jako całości oraz jego
					poszczególnych elementów, w tym treści, grafik, utworów, wzorów i znaków dostępnych w jego ramach
					należą do Usługodawcy lub innych uprawnionych podmiotów trzecich i objęte są one ochroną Prawa
					Autorskiego oraz innych przepisów powszechnie obowiązującego prawa. Ochrona Serwisu Internetowego
					obejmuje wszystkie formy jego wyrażenia.
				</li>
				<li>
					Serwis Internetowy należy traktować podobnie jak każdy inny utwór podlegający ochronie
					prawnoautorskiej. Użytkownik nie ma prawa kopiować Serwisu Internetowego za wyjątkiem przypadków
					dozwolonych przepisami bezwzględnie wiążącego prawa. Użytkownik zobowiązuje się także nie
					modyfikować, adaptować, tłumaczyć, odkodowywać, dekompilować, dezasemblować lub w jakikolwiek inny
					sposób starać się ustalić kod źródłowy Serwisu Internetowego, za wyjątkiem przypadków dozwolonych
					przepisami bezwzględnie wiążącego prawa.
				</li>
				<li>
					Znaki handlowe Usługodawcy oraz podmiotów trzecich powinny być używane zgodnie z obowiązującymi
					przepisami prawa.
				</li>
			</ol>
			<h2>17) PRZERWY I AWARIE TECHNICZNE</h2>
			<ol>
				<li>
					Usługodawca dokłada należytych starań, aby zapewnić prawidłowe i nieprzerwane funkcjonowanie Serwisu
					Internetowego. Z uwagi na złożoność oraz stopień skomplikowania Serwisu Internetowego oraz jego
					Usług Elektronicznych, a także z uwagi na czynniki zewnętrzne pozostające poza kontrolą Usługodawcy
					(np. ataki DDOS - distributed denial of service), możliwe jest jednak wystąpienie błędów oraz awarii
					technicznych uniemożliwiających lub ograniczających w jakikolwiek sposób funkcjonowanie
					Kalendarz-motoryzacyjny.pl. Usługodawca podejmie w takim wypadku wszelkie pozostające w jego
					możliwościach i uzasadnione działania mające na celu zapewnienie ograniczenia negatywnych skutków
					takich zdarzeń w jak największym stopniu.
				</li>
				<li>
					Usługodawca informuje niezwłocznie Użytkowników o wszelkich błędach oraz awariach technicznych, o
					których w mowa powyżej oraz przewidywanym terminie ich usunięcia.
				</li>
				<li>
					Poza przerwami spowodowanymi błędami i awariami technicznymi mogą wystąpić także inne przerwy
					techniczne, w czasie których Usługodawca podejmuje działania mające na celu rozwój Serwisu i Usług
					Elektronicznych oraz ich zabezpieczenie przed błędami i awariami technicznymi.
				</li>
				<li>
					Usługodawca obowiązany jest planować przerwy techniczne w taki sposób, aby były one jak najmniej
					uciążliwe dla Użytkowników, w szczególności aby planowane były na pory o zmniejszonym natężeniu
					ruchu w Kalendarz-motoryzacyjny.pl (np. godziny nocne) i jedynie na czas niezbędny do dokonania
					koniecznych działań przez Usługodawcę. Usługodawca informuje Użytkowników o planowanych przerwach
					technicznych z odpowiednim wyprzedzeniem, podając przewidywany czas trwania planowanej przerwy.
				</li>
				<li>
					Usługodawca nie ponosi wobec Użytkowników odpowiedzialności za szkody i niewypełnienie zobowiązań
					wynikających z wszelkich błędów i awarii technicznych oraz przerw technicznych, o których mowa w
					niniejszym punkcie 17. Regulaminu. Niniejszy punkt 17. Regulaminu nie ma na celu wyłączać ani
					ograniczać uprawnień Użytkownika będącego Konsumentem lub Przedsiębiorcą na Prawach Konsumenta,
					przewidzianych przez powszechnie obowiązujące przepisy prawa, szczególnie w zakresie
					odpowiedzialności Usługodawcy za nienależyte spełnienie świadczenia.
				</li>
			</ol>
			<h2>18) POSTANOWIENIA KOŃCOWE</h2>
			<ol>
				<li>
					Umowy zawierane poprzez Kalendarz-motoryzacyjny.pl na podstawie niniejszego Regulaminu zawierane są
					w języku polskim.
				</li>
				<li>
					Zmiany Regulaminu:
					<ol type='a'>
						<li>
							Usługodawca zastrzega sobie prawo do dokonywania zmian niniejszego Regulaminu z ważnych
							przyczyn, to jest: zmiany przepisów prawa; podlegania obowiązkowi prawnemu lub
							regulacyjnemu; zmiany zakresu lub formy świadczonych usług, w tym Usług Elektronicznych;
							dodania lub usunięcia usług, w tym Usług Elektronicznych; konieczności przeciwdziałania
							nieprzewidzianemu i bezpośredniemu zagrożeniu związanemu z ochroną Serwisu Internetowego, w
							tym Usług Elektronicznych i Użytkowników przed oszustwami, złośliwym oprogramowaniem,
							spamem, naruszeniem danych lub innymi zagrożeniami dla cyberbezpieczeństwa; poprawy jakości
							obsługi Użytkowników; zmiany danych Usługodawcy - w zakresie, w jakim te zmiany wpływają na
							realizację postanowień niniejszego Regulaminu.
						</li>
						<li>
							Okres powiadomienia o proponowanych zmianach przed ich wprowadzeniem wynosi co najmniej 15
							dni od dnia powiadomienia, z zastrzeżeniem pkt. 18.2 lit. d) i e) Regulaminu. Użytkownik ma
							prawo rozwiązać umowę z Usługodawcą przed upływem okresu powiadomienia. Rozwiązanie takie
							staje się skuteczne w terminie 15 dni od dnia otrzymania powiadomienia.
						</li>
						<li>
							W przypadku zawarcia umowy o charakterze ciągłym, zmieniony Regulamin wiąże Użytkownika,
							jeżeli został prawidłowo powiadomiony o zmianach, zgodnie z okresem powiadomienia przed ich
							wprowadzeniem i nie rozwiązał w tym okresie umowy. Dodatkowo, w dowolnym momencie po
							otrzymaniu powiadomienia o zmianach, Użytkownik może zaakceptować wprowadzane zmiany i tym
							samym zrezygnować z dalszego trwania okresu powiadomienia. W wypadku gdyby zmiana Regulaminu
							skutkowała wprowadzeniem jakichkolwiek nowych opłat za korzystanie z usług
							Kalendarz-motoryzacyjny.pl lub podwyższeniem obecnych Użytkownik będący Konsumentem lub
							Przedsiębiorcą na Prawach Konsumenta ma prawo odstąpienia od umowy.
						</li>
						<li>
							W przypadku zawarcia umowy o innym charakterze niż umowy ciągłe, zmiany Regulaminu nie będą
							w żaden sposób naruszać praw nabytych Użytkownika przed dniem wejścia w życie zmian
							Regulaminu, w szczególności zmiany Regulaminu nie będą miały wpływu na już wykonane i
							realizowane umowy.
						</li>
						<li>
							Usługodawca może wprowadzić zmiany Regulaminu bez zachowania 15-dniowego okresu
							powiadomienia, o którym mowa w pkt. 18.2 lit. b) Regulaminu, w przypadku gdy Usługodawca:
							<ol type='i'>
								<li>
									podlega obowiązkowi prawnemu lub regulacyjnemu, na podstawie którego zobowiązany
									jest do zmiany Regulaminu w sposób, który uniemożliwia mu dotrzymanie 15-dniowego
									okresu powiadomienia;
								</li>
								<li>
									musi w drodze wyjątku zmienić swój Regulamin, aby przeciwdziałać nieprzewidzianemu i
									bezpośredniemu zagrożeniu związanemu z ochroną Serwisu Internetowego, w tym Usług
									Elektronicznych i Użytkowników przed oszustwami, złośliwym oprogramowaniem, spamem,
									naruszeniem danych lub innymi zagrożeniami dla cyberbezpieczeństwa.
								</li>
							</ol>
						</li>
						<li>
							W przypadkach, o których mowa pkt. 18.2 lit. e) Regulaminu, wprowadzenie zmian następuje ze
							skutkiem natychmiastowym, chyba że możliwe lub konieczne jest zastosowanie dłuższego terminu
							wprowadzenia zmian, o czym każdorazowo powiadamia Usługodawca.
						</li>
					</ol>
				</li>
				<li>
					W sprawach nieuregulowanych niniejszym Regulaminem mają zastosowanie powszechnie obowiązujące
					przepisy prawa polskiego, w szczególności: Kodeksu Cywilnego; ustawy z dnia 18 lipca 2002 r. o
					świadczeniu usług drogą elektroniczną (Dz.U. 2002 nr 144, poz. 1204 ze zm.); Prawa Autorskiego;
					Ustawy o Prawach Konsumenta oraz inne właściwe przepisy powszechnie obowiązującego prawa.
				</li>
			</ol>
			{sectionBreak()}
			<p>
				<b>Dziękujemy za uważną lekturę!</b>
			</p>
			<p>W tazie pytań jesteśmy do Państwa dyspozycji - prosimy o kontakt.</p>
			<p>
				<b>
					Zapraszamy do współpracy, <br />
					Zespół Kalendarz-motoryzacyjny.pl
				</b>
			</p>
		</div>
	);
};
