import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface NotFoundContainerProps extends RouteComponentProps {}

const EventSubmittedContainer = ({ history }: NotFoundContainerProps) => {
	const { t } = useTranslation(['page', 'common']);

	const buttonGoBackHomeOnClick = () => history.push('/');
  const buttonAddAnotherEvent = () => history.push('/submit')

	return (
		<Result
			//status='success'
      icon={<ExclamationCircleOutlined/>}
			title={t('common:Success.Success')}
			subTitle={t('EventPages.SubmittedEventContainer.InfoNote')}
			extra={
        <>
          <Button type='primary' onClick={buttonAddAnotherEvent}>
            {t('page:EventPages.SubmittedEventContainer.AddAnother')}
          </Button>
          <Button type='default' onClick={buttonGoBackHomeOnClick}>
            {t('common:Actions.BackToHome')}
          </Button>
        </>
			}
		></Result>
	);
};

export default EventSubmittedContainer;
