import { Col, notification, PageHeader, Row } from 'antd';
import StatusType from 'App/types/requestStatus';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Store } from 'antd/lib/form/interface';
import { ResetPasswordRequest } from 'App/api/endpoints/account/requests';
import ResetPasswordForm from './forms/ResetPasswordForm';
import { RootState } from 'App/globalState/root.reducer';
import { resetPassword } from '../../state/account.thunk';

interface RouteParams {
	userId: string;
	passwordResetCode: string;
}

interface ResetPasswordContainerProps extends RouteComponentProps<RouteParams> {}

const ResetPasswordContainer = ({ history, match }: ResetPasswordContainerProps) => {
	type FinishFormType = (values: Store) => void;

	const dispatch = useDispatch();
	const status = useSelector((state: RootState) => state.pages.account.status.resetPassword);
	const { t } = useTranslation('page');

	const resetPasswordHandler: FinishFormType = (values: ResetPasswordRequest) => {
		let handleSuccess: () => void = () => {
			history.push('/sign-in');
			notification.success({
				message: t('common:Success.Success'),
				description: t('AccountPage.ResetPasswordContainer.ResetPasswordSuccess'),
			});
		};

		const { userId, passwordResetCode } = match.params;

		values.passwordResetCode = passwordResetCode;
		values.userId = userId;

		dispatch(resetPassword(values, handleSuccess));
	};

	return (
		<div className='resetPasswordContainer'>
			<Row align='middle' justify='center'>
				<Col xs={22} md={14} xl={10} xxl={8}>
					<br />
					<PageHeader title={t('AccountPage.ResetPasswordContainer.PageHeaderTitle')} />
					<ResetPasswordForm
						className='resetPassword-form'
						name='resetPasswordForm'
						size='large'
						onFinish={resetPasswordHandler}
						autoComplete='off'
						loading={status === StatusType.LOADING}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default ResetPasswordContainer;
