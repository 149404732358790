import agent from "App/api/agent/agent";
import { SubmitContactFormRequest } from "App/api/endpoints/contact/requests";
import { AppThunk } from "../store";
import { submitContactFormStart, submitContactFormSuccess, submitContactFormFailure } from "./contact.global.slice";

export const submitContactForm = (body: SubmitContactFormRequest, onSuccess?: () => void): AppThunk => (dispatch) => {
    dispatch(submitContactFormStart());
    agent.Contact.submitContactForm(body)
        .then(() => {
            dispatch(submitContactFormSuccess());
                if(onSuccess)
                    onSuccess();
        })
       .catch(() => dispatch(submitContactFormFailure()));
};