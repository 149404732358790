import React from 'react';
import { Form, Input, Select, Button } from 'antd';
import { CreateUserRequest } from 'App/api/endpoints/users/requests';
import { useTranslation } from 'react-i18next';
import { languages } from 'i18n';
import { formRules } from 'App/common/formRules/formRules';

interface CreateUserFormProps {
	onFinish: (values: CreateUserRequest) => void;
	initialValues?: CreateUserRequest;
	loading: boolean;
}

const CreateUserForm = ({ initialValues, loading, onFinish }: CreateUserFormProps) => {
	const { t } = useTranslation(['form', 'common']);
	const { email, password, firstName, lastName, roles, emailLanguage } = formRules.user;

	return (
		<Form layout='vertical' labelAlign='right' className='px-3' initialValues={initialValues} onFinish={onFinish}>
			<Form.Item
				messageVariables={{ arg: t('models:User.Labels.Email') }}
				label={t('models:User.Labels.Email')}
				name='email'
				rules={email()}
			>
				<Input placeholder={t('models:User.Placeholders.Email')} />
			</Form.Item>

			<Form.Item
				label={t('models:User.Labels.Password')}
				messageVariables={{ arg: t('models:User.Labels.Password') }}
				name='password'
				rules={password()}
			>
				<Input type='password' placeholder={t('models:User.Placeholders.Password')} />
			</Form.Item>

			<Form.Item
				label={t('models:User.Labels.FirstName')}
				messageVariables={{ arg: t('models:User.Labels.FirstName') }}
				name='firstName'
				rules={firstName()}
			>
				<Input placeholder={t('models:User.Placeholders.FirstName')} />
			</Form.Item>

			<Form.Item
				label={t('models:User.Labels.LastName')}
				messageVariables={{ arg: t('models:User.Labels.LastName') }}
				name='lastName'
				rules={lastName()}
			>
				<Input placeholder={t('models:User.Placeholders.LastName')} />
			</Form.Item>

			<Form.Item
				name='roles'
				label={t('models:User.Labels.Roles')}
				messageVariables={{ arg: t('models:User.Labels.Roles') }}
				rules={roles()}
			>
				<Select mode='multiple' placeholder={t('models:User.Placeholders.SelectRoles')}>
					<Select.Option value='User'>{t('common:Roles.User')}</Select.Option>
					<Select.Option value='Administrator'>{t('common:Roles.Administrator')}</Select.Option>
				</Select>
			</Form.Item>

			<Form.Item
				name='language'
				label={t('models:User.Labels.EmailLanguage')}
				messageVariables={{ arg: t('models:User.Labels.EmailLanguage') }}
				rules={emailLanguage()}
			>
				<Select placeholder={t('models:User.Placeholders.EmailLanguage')}>
					{languages.map((language) => (
						<Select.Option key={language} value={language}>
							{language}
						</Select.Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item>
				<Button block loading={loading} type='primary' htmlType='submit'>
					{t('common:Actions.Create')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default CreateUserForm;
