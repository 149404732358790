import React from 'react';
import { Form, Input, Button } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { UserOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import { formRules } from 'App/common/formRules/formRules';

interface ResendConfirmationEmailProps extends FormProps {
	loading: boolean;
}

const ResendConfirmationEmailForm = (props: ResendConfirmationEmailProps) => {
	const { t } = useTranslation(['form', 'common']);
	const { loading, ...rest } = props;

	return (
		<Form {...rest}>
			<Form.Item
				name='email'
				messageVariables={{ arg: t('models:User.Labels.Email') }}
				rules={formRules.user.email()}
			>
				<Input
					prefix={<UserOutlined className='site-form-item-icon' />}
					placeholder={t('models:User.Placeholders.Email')}
				/>
			</Form.Item>
			<Form.Item name='language' initialValue={i18n.language} hidden>
				<Input hidden />
			</Form.Item>
			<Form.Item>
				<Button loading={loading} type='primary' htmlType='submit' size='large'>
					{t('common:Actions.Submit')}
				</Button>
			</Form.Item>
		</Form>
	);
};

export default ResendConfirmationEmailForm;
