import React from 'react';
import { Result, Button } from 'antd';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

interface InternalServerErrorContainerProps extends RouteComponentProps {}

const InternalServerErrorContainer = ({ history }: InternalServerErrorContainerProps) => {
	const buttonGoBackHomeOnClick = () => history.push('/');
	const { t } = useTranslation(['page', 'common']);

	return (
		<Result
			status='500'
			title='500'
			subTitle={t('ResultsPages.InternalWeAreSorry')}
			extra={
				<Button type='primary' onClick={buttonGoBackHomeOnClick}>
					{t('common:Actions.BackToHome')}
				</Button>
			}
		></Result>
	);
};

export default InternalServerErrorContainer;
