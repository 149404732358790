import { RootState } from 'App/globalState/root.reducer';
import Role from 'App/types/role';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, RouteProps } from 'react-router';
import LoadingScreen from './LoadingScreen';

interface ProtectedRouteProps extends RouteProps {
	acceptedRoles?: Role[];
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
	const accountDetails = useSelector((state: RootState) => state.global.account.accountDetails);
	const token = useSelector((state: RootState) => state.global.auth.tokens?.accessToken);
	const appStarting = useSelector((state: RootState) => state.global.auth.flags.appStarting);
	const login = useSelector((state: RootState) => state.global.auth.flags.login);

	const { component: Component, ...rest } = props;

	if (appStarting || login) return <LoadingScreen container='screen' />;
	else {
		//jesli nie ma accountDetails lub token
		if (!accountDetails || !token) return <Redirect to={{ pathname: '/sign-in', state: props.location }} />;
		//jeśli jest token i są detale konta z rolami
		//sprawdz czy jakakolwiek rola uzytkownika ma dostep do tego ProtectedRoute
		else if (!props.acceptedRoles || accountDetails.roles?.some((role) => props.acceptedRoles.includes(role)))
			return <Route {...rest} render={(props: RouteChildrenProps) => <Component {...props} />} />;
		//jeśli nie to przekieruj na forbbiden (użytkownik jest zalogowany ale nie ma dostępu do zasobu)
		else return <Redirect to={{ pathname: '/403', state: props.location }} />;
	}
};

export default ProtectedRoute;
