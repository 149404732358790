import { Col, Row, Tag } from 'antd';
import { serverURL } from 'App/api/agent/axios/configuration';
import { EventForGetEventsResponse } from 'App/api/endpoints/events/responses'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EventUtils } from '../../../utils/EventUtils';
import moment from 'moment';

import Blank from 'App/common/assets/blank.webp';

import './EventCard.less';
import { LoadingOutlined } from '@ant-design/icons';
import { EventCategoryGrouping } from 'App/common/utils/eventCategoryGrouping.utils';

interface EventCardProps {
    event: EventForGetEventsResponse;
}

export default function EventCard({ event }: EventCardProps) {
	const { t } = useTranslation('page');

    const imgPath = (): string => `${serverURL + event?.urlBanner?.replaceAll('\\', '/')}`;

    const isLive = (): boolean => {
        const now = moment.utc();
        return moment.utc(event.dateStartUTC) <= now && moment.utc(event.dateEndUTC) >= now;
    }

    return (
        <Link to={`/event/${event.slug ?? event.id}`}>
            <Row className='event-card'>
                {isLive() &&
                    <div className='event-card__live'>
                        <span><LoadingOutlined/> LIVE</span>
                    </div>
                }
                <Col className='event-card__image'>
                    {/* <img src={serverURL + event.urlBanner} alt={event.title} /> */}
                    <div className='event-card__image__bg' style={{ backgroundImage: `url(${imgPath()}), url(${Blank})`}}></div>
                </Col>
                <Col className='event-card__content'>
                    <Row className='event-card__section'>
                        <div className='event-card__header'>
                            <div className='event-card__header__title'>
                                <b>{event.title}</b>
                            </div>
                            <div className='event-card__header__date'>
                                {EventUtils.buildDateString(event.dateStart, event.dateEnd)}
                            </div>
                        </div>
                    </Row>
                    <Row className='event-card__section'>
                        <div className='event-card__location'>
                            <b>{event.state}, {event.city}, {event.locationName?.length ? event.locationName : event.street}</b>
                        </div>
                    </Row>
                    <Row className='event-card__section'>
                        <div className='event-card__description'>
                            <b>{t('models:Event.Labels.Organizer')}:</b> {event.organizer} <br/>
                            <b>{t('models:Event.Labels.Category')}:</b>{' '}
                            {
                                EventCategoryGrouping.toGroups(t, event.categories).map(g => (
                                    <Tag color={EventUtils.categoryGroupColor(g.value)} style={{ borderRadius: '7px' }}>
                                        {g.label}
                                    </Tag>
                                ))
                            } <br/>
                            {event.priceEntry != null &&
                                <><b>{t('models:Event.Labels.PriceEntry')}:</b> {event.priceEntry} {event.priceCurrency}</>
                            }
                            {event.priceEntry != null && event.priceTicket != null &&
                                <>, {' '}</>
                            }
                            {event.priceTicket != null &&
                                <><b>{t('models:Event.Labels.PriceTicket')}:</b> {event.priceTicket} {event.priceCurrency} <br/></>
                            }
                        </div>
                    </Row>
                </Col>
            </Row>
        </Link>
    );
}
