import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App/globalState/root.reducer';
import { getEvents, getEventsCountries, getPromotedEvents, getRecentlyAddedEvents } from 'App/globalState/events/events.global.thunk';
import { useHistory, useLocation } from 'react-router';
import { Col, Row, Tabs } from 'antd';
import { setHideGlobalNavbar } from 'App/globalState/alterLayout/alterLayout.global.thunk';
import EventSearchForm from './components/eventSearchForm/EventSearchForm';
import NavbarContainer from 'App/common/containers/NavbarContainer';
import Banner from './components/banner/Banner';
import StatusType from 'App/types/requestStatus';
import PageLabel from 'App/common/components/PageLabel/PageLabel';
import LatestEventsCarousel from './components/latestEventsCarousel/LatestEventsCarousel';
import { EventList } from './components/eventList/EventList';
import { eventsInitialState } from 'App/globalState/events/events.global.state';
import { IFilterItem } from 'App/types/pagination/pagination';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import querystring from 'querystring';
import { EventSearchFormValues } from './components/eventSearchForm/EventSearchFormParams';
import { URLUtils } from './utils/URLUtils';
import { GetEventsRequest } from 'App/api/endpoints/events/requests';
import moment, { Moment } from 'moment';
import AdvertsColumn from '../../common/advertsColumn/AdvertsColumn';
import { getAdverts } from 'App/globalState/adverts/adverts.global.thunk';
import { Desktop } from 'App/common/components/Responsive/Desktop';
import { Mobile } from 'App/common/components/Responsive/Mobile';
import { Default } from 'App/common/components/Responsive/Default';
import { CalendarOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { EventCalendar } from './components/eventCalendar/EventCalendar';
import { LocalStorage } from 'App/common/utils/localStorage.utils';
import { Helmet } from "react-helmet-async";

import './HomeContainer.less';

const { LOADING } = StatusType;

const HomeContainer = () => {
	const { t } = useTranslation('page');
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const searchFormRef = useRef(null);
	const eventListRef = useRef(null);

	const defaultPageSize = LocalStorage.calendarView.get() === 'calendar' ? 100 : 10;
	const defaultSearchParams: EventSearchFormValues = {
		priceEntry: [0, 2100],
		priceTicket: [0, 600],
		categories: [],
		surfaces: [],
	}
	
    const [provider] = useState(new OpenStreetMapProvider());
	const [searchParams, setSearchParams] = useState<EventSearchFormValues>(null);
	const [displayFormat, setDisplayFormat] = useState<'list' | 'calendar'>(LocalStorage.calendarView.get() ?? 'list');
	const [firstCalChange, setFirstCalChange] = useState<boolean>(true);

	const events = useSelector((state: RootState) => state.global.events.events);
	const getEventsStatus = useSelector((state: RootState) => state.global.events.status.getEvents);
	const getEventsParams = useSelector((state: RootState) => state.global.events.getEventsParams);

    const adverts = useSelector((state: RootState) => state.global.adverts.adverts);
    const advertsParams = useSelector((state: RootState) => state.global.adverts.getAdvertsParams);

	const recentlyAddedEvents = useSelector((state: RootState) => state.global.events.recentlyAddedEvents);
	const getRecentlyAddedEventsStatus = useSelector((state: RootState) => state.global.events.status.getRecentlyAddedEvents);
	const getRecentlyAddedEventsParams = useSelector((state: RootState) => state.global.events.getRecentlyAddedEvents);

	const promotedEvents = useSelector((state: RootState) => state.global.events.promotedEvents);
	const getPromotedEventsStatus = useSelector((state: RootState) => state.global.events.status.getPromotedEvents);
	const getPromotedEventsParams = useSelector((state: RootState) => state.global.events.getAdminEventsParams);
	
	const availableCountries = useSelector((state: RootState) => state.global.events.eventsCountries);

	useEffect(() => {
		// pierwsze uruchomienie z domyślnie ustawionymi filtrami i orderby
		//dispatch(getEvents({ ...getEventsParams }));
		dispatch(getRecentlyAddedEvents({ ...getRecentlyAddedEventsParams }));
		dispatch(getPromotedEvents({ ...getPromotedEventsParams }));
		dispatch(setHideGlobalNavbar(true));
		dispatch(getAdverts({ ...advertsParams }));
		dispatch(getEventsCountries());

		return () => {
			window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
			dispatch(setHideGlobalNavbar(false));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(()=> {
		if (location.hash) {
			if(location.hash.slice(1) === 'search' ||
			   location.hash.slice(1) === 'szukaj' ) {
				smoothScrollTo(searchFormRef.current, -64);
			}
		}

		if (location.search) {
			const search = querystring.parse(location.search.substring(1));

			if(!Object.keys(search).some(key => key !== 'fbclid')) {
				setSearchParams(defaultSearchParams);
			 	return;
			}

			let newParams = { ...search };
			if(search.categories) newParams.categories = URLUtils.isArrayOfStrings(search.categories) ? search.categories : [ search.categories as string ];
			if(search.surfaces) newParams.surfaces = URLUtils.isArrayOfStrings(search.surfaces) ? search.surfaces : [ search.surfaces as string ];

			setSearchParams(newParams);
		} else {
			setSearchParams(defaultSearchParams);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location,]);

	useEffect(() => {
		//console.log("SEARCH PARAMS", searchParams)
		if(!searchParams)
			return;

		if(getEventsStatus !== LOADING)
			getEventsOfParams(searchParams);

		if(!URLUtils.compareParams(searchParams, defaultSearchParams)) {
			smoothScrollTo(eventListRef.current, -70);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	const smoothScrollTo = (elem: any, offset?: number) => {
		if(!elem) return;

		const y = elem.getBoundingClientRect().top + window.pageYOffset + offset;
		window.scrollTo({ top: y, behavior: 'smooth' });
	}

	const onSearchForm = (params: EventSearchFormValues) => {
		if(displayFormat === 'calendar') {
			params.dateStart = searchParams.dateStart;
			params.dateEnd = searchParams.dateEnd;
			params.pageSize = 100;
		}

		const search = URLUtils.paramsToURL(params, '/');
		smoothScrollTo(eventListRef.current, -70);
		history.push(search);
	};

	const onDisplayChange = (display) => {
		//console.log(display);

		const fromCalToList = displayFormat === 'calendar' && display === 'list';
		const fromListToCal = displayFormat === 'list' && display === 'calendar';

		const search = URLUtils.paramsToURL({ 
			...searchParams,
			dateStart: fromCalToList ? null : searchParams.dateStart,
			dateEnd: fromCalToList ? null : searchParams.dateEnd,
			page: fromListToCal ? null : searchParams.page,
			pageSize: fromListToCal ? 100 : null
		}, '/');

		LocalStorage.calendarView.save(display);
		setDisplayFormat(display);
		history.push(search);
		smoothScrollTo(eventListRef.current, -70);
	}

	const onPaginationChange = (pageNumber, pageSize) => {
		const search = URLUtils.paramsToURL({ 
			...searchParams,
			page: pageNumber > 1 ? pageNumber : null,
			pageSize: pageSize !== defaultPageSize ? pageSize : null,
		}, '/');

		history.push(search);
		smoothScrollTo(eventListRef.current, -70);
	};

	const onCalendarChange = (start: Moment, end: Moment) => {
		if(firstCalChange) {
			setFirstCalChange(false);
			return;
		}

		const search = URLUtils.paramsToURL({ 
			...searchParams,
			dateStart: start,
			dateEnd: end
		}, '/');

		//console.log("CHANGE CAL", search);
		history.push(search);
		smoothScrollTo(eventListRef.current, -70);
	}

	const getEventsOfParams = async (params: EventSearchFormValues) => {
		//console.log("GETTING", params)
		if(!params) return;

		let filters: IFilterItem[] = [];
		let address = {};

		if (params.safetyCageRequired)
			filters.push({ field: 'safetyCageRequired', values: [ params.safetyCageRequired.toString() ] });

		if (params.city) {
			if (params.distance) {
				// Search location and get coords
				const query = `${params.city} ${params.state ?? ''} ${params.country ?? ''}`;
        		await provider.search({ query }).then((results) => {
            		if(results.length) {
						//console.log(results)
            			const location = results[0];
						address = {
							coordinates: { latitude: location.y, longitude: location.x },
							rangeInKilometers: params.distance
						}
					}
				});
			} else {
				address = {
					city: params.city,
					state: params.state ?? null,
					country: params.country ?? null
				}
			}
		} else {
			address = {
				state: params.state ?? null,
				country: params.country ?? null
			}
		}

		const query = {
			...address,
			filters: filters ?? null,

			query: params.query,
			dateStart: params.dateStart ? moment.utc(params.dateStart) : null,
			dateEnd: params.dateEnd ? moment.utc(params.dateEnd).endOf('day'): null,
			priceTicket: params.priceTicket,
			priceEntry: params.priceEntry,
			priceCurrency: params.priceCurrency,
			pageNumber: params.page ? Number(params.page) : 1,
			pageSize: params.pageSize ? Number(params.pageSize) : defaultPageSize,
			categories: params.categories,
			surfaces: params.surfaces,

			totalItems: eventsInitialState.getEventsParams.totalItems,
			orderBy: eventsInitialState.getEventsParams.orderBy,
		} as GetEventsRequest;

		dispatch(getEvents(query));

		if(!URLUtils.compareParams(params, defaultSearchParams))
			smoothScrollTo(eventListRef.current, -70);
	}

	const eventTabs = [
		{
			key: 'list',
			label: <><UnorderedListOutlined/>{t('HomePages.Search.List')}</>,
		},
		{
			key: 'calendar',
			label: <><CalendarOutlined/>{t('HomePages.Search.Calendar')}</>,
		}
	];

	return (
		<Row className='home-container'>
			<Helmet>
				<title id='/'>Wydarzenia Motoryzacyjne</title>
				{/* META Props */}
				<meta id='/' name="description" content="Znajdź wydarzenie" />

				{/* OG Props */}
				<meta id='/' property="og:description" content="Znajdź wydarzenie" />
			</Helmet>

			<Banner/>

			<div id="search"></div>
			<div className='home__navbar ant-layout-header'>
				<NavbarContainer />
			</div>

			<div ref={searchFormRef}>
			<EventSearchForm 
				onFinish={onSearchForm} 
				loading={getEventsStatus === LOADING} 
				initialValues={searchParams}
				availableCountries={availableCountries?.countries}
			/>
			</div>

			{promotedEvents && promotedEvents?.length > 0 && <>
				<PageLabel message={t('HomePages.PromotedEvents')} />
				<LatestEventsCarousel 
					events={promotedEvents} 
					visibleSlides={3} 
					loading={getPromotedEventsStatus === LOADING} 
				/>
			</>}

			<div id="browse" ref={eventListRef}></div>
			<Default>
				<PageLabel message={t('HomePages.BrowseEvents')} messageRight={t('HomePages.SupportedBy')} />
			</Default>
			<Mobile>
				<PageLabel message={t('HomePages.BrowseEvents')} />
			</Mobile>

			<Row className='home-container__event-list-container'>
				<Col xs={24} md={18} style={{ display: 'flex', justifyContent: 'center' }}>				
					<Tabs 
						items={eventTabs} 
						onChange={onDisplayChange} 
						defaultActiveKey={displayFormat}
						centered
					/>
				</Col>
				<Col xs={24} md={18}>
					<div>
						{displayFormat === 'list' &&
							<EventList 
								events={events}
								pageNumber={getEventsParams.pageNumber}
								pageSize={getEventsParams.pageSize}
								totalItems={getEventsParams.totalItems}
								loading={getEventsStatus === StatusType.LOADING}
								onChange={onPaginationChange}
							/>
						}
						{displayFormat === 'calendar' &&
							<EventCalendar
								events={events}
								loading={getEventsStatus === StatusType.LOADING}
								onChange={onCalendarChange}
								startDate={searchParams?.dateStart ?? moment()}
								endDate={searchParams?.dateEnd ?? moment()}
							/>
						}
					</div>
				</Col>
				<Default>
					<Col xs={24} md={6}>
						<AdvertsColumn adverts={adverts} />
					</Col>
				</Default>
			</Row>

			<Mobile>
				<PageLabel message={t('HomePages.LatestEvents')} />
				<LatestEventsCarousel 
					events={recentlyAddedEvents} 
					visibleSlides={3} 
					loading={getRecentlyAddedEventsStatus === StatusType.LOADING} 
				/>

				<PageLabel messageRight={t('HomePages.SupportedBy')} />
				<AdvertsColumn adverts={adverts} />
			</Mobile>

			<Desktop>
				<PageLabel message={t('HomePages.LatestEvents')} />
				<LatestEventsCarousel 
					events={recentlyAddedEvents} 
					visibleSlides={3} 
					loading={getRecentlyAddedEventsStatus === StatusType.LOADING} 
				/>
			</Desktop>
		</Row>
	);
};

export default HomeContainer;
