import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetAdminEventResponse, GetAdminEventsResponse, GetEventResponse, GetEventsCountriesResponse, GetEventsResponse, GetPromotedEventsResponse, UpdateEventResponse } from "App/api/endpoints/events/responses";
import StatusType from "App/types/requestStatus";
import { eventsInitialState, EventsState } from "./events.global.state";

const { LOADING, SUCCESS, FAILED } = StatusType;

export const globalEventsSlice = createSlice({
    name: 'global-events',
    initialState: eventsInitialState,
    reducers: {
        getAdminEventsStart: (state: EventsState) => {
           state.status.getAdminEvents = LOADING;
        },
        getAdminEventsSuccess: (state: EventsState, action: PayloadAction<GetAdminEventsResponse>) => {
           state.status.getAdminEvents = SUCCESS;
           const { events, ...pagination } = action.payload;
           state.adminEvents = action.payload.events;
           state.getAdminEventsParams = pagination;
        },
        getAdminEventsFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getAdminEvents = FAILED;
        },

        getAdminEventStart: (state: EventsState) => {
           state.status.getAdminEvent = LOADING;
        },
        getAdminEventSuccess: (state: EventsState, action: PayloadAction<GetAdminEventResponse>) => {
           state.status.getAdminEvent = SUCCESS;
           state.adminEvent = action.payload;
        },
        getAdminEventFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getAdminEvent = FAILED;
        },

        adminCreateEventStart: (state: EventsState) => {
           state.status.adminCreateEvent = LOADING;
        },
        adminCreateEventSuccess: (state: EventsState, action: PayloadAction<any>) => {
           state.status.adminCreateEvent = SUCCESS;
        },
        adminCreateEventFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.adminCreateEvent = FAILED;
        },

        getEventsStart: (state: EventsState) => {
           state.status.getEvents = LOADING;
        },
        getEventsSuccess: (state: EventsState, action: PayloadAction<GetEventsResponse>) => {
           state.status.getEvents = SUCCESS;
           const { events, ...pagination } = action.payload;
           state.events = events;
           state.getEventsParams = pagination;
        },
        getEventsFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getEvents = FAILED;
        },

        getRecentlyAddedEventsStart: (state: EventsState) => {
           state.status.getRecentlyAddedEvents = LOADING;
        },
        getRecentlyAddedEventsSuccess: (state: EventsState, action: PayloadAction<GetEventsResponse>) => {
           state.status.getRecentlyAddedEvents = SUCCESS;
           const { events, ...pagination } = action.payload;
           state.recentlyAddedEvents = events;
           state.getRecentlyAddedEvents = pagination;
        },
        getRecentlyAddedEventsFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getRecentlyAddedEvents = FAILED;
        },

        getPromotedEventsStart: (state: EventsState) => {
           state.status.getPromotedEvents = LOADING;
        },
        getPromotedEventsSuccess: (state: EventsState, action: PayloadAction<GetPromotedEventsResponse>) => {
           state.status.getPromotedEvents = SUCCESS;
           const { events, ...pagination } = action.payload;
           state.promotedEvents = events;
           state.getPromotedEventsParams = pagination;
        },
        getPromotedEventsFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getPromotedEvents = FAILED;
        },

        getEventsCountriesStart: (state: EventsState) => {
           state.status.getEventsCountries = LOADING;
        },
        getEventsCountriesSuccess: (state: EventsState, action: PayloadAction<GetEventsCountriesResponse>) => {
           state.status.getEventsCountries = SUCCESS;
           state.eventsCountries = action.payload;
        },
        getEventsCountriesFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getEventsCountries = FAILED;
        },

        getEventStart: (state: EventsState) => {
           state.status.getEvent = LOADING;
        },
        getEventSuccess: (state: EventsState, action: PayloadAction<GetEventResponse>) => {
           state.status.getEvent = SUCCESS;
           state.event = action.payload;
        },
        getEventFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.getEvent = FAILED;
        },

        createEventStart: (state: EventsState) => {
           state.status.createEvent = LOADING;
        },
        createEventSuccess: (state: EventsState, action: PayloadAction<any>) => {
           state.status.createEvent = SUCCESS;
        },
        createEventFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.createEvent = FAILED;
        },

        confirmEventStart: (state: EventsState) => {
           state.status.confirmEvent = LOADING;
        },
        confirmEventSuccess: (state: EventsState) => {
           state.status.confirmEvent = SUCCESS;
        },
        confirmEventFailure: (state: EventsState) => {
           state.status.confirmEvent = FAILED;
        },

        updateEventStart: (state: EventsState) => {
           state.status.updateEvent = LOADING;
        },
        updateEventSuccess: (state: EventsState, action: PayloadAction<UpdateEventResponse>) => {
           state.status.updateEvent = SUCCESS;
           state.updateEvent = action.payload;
        },
        updateEventFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.updateEvent = FAILED;
        },

        updateEventStatusStart: (state: EventsState) => {
           state.status.updateEventStatus = LOADING;
        },
        updateEventStatusSuccess: (state: EventsState, action: PayloadAction<any>) => {
           state.status.updateEventStatus = SUCCESS;
        },
        updateEventStatusFailure: (state: EventsState, action: PayloadAction<any>) => {
           state.status.updateEventStatus = FAILED;
        },

        cleanUpEventsStatus: (state: EventsState) => {
			state.status = eventsInitialState.status;
		  },
        cleanUpAdminEvent: (state: EventsState) => {
         state.adminEvent = eventsInitialState.adminEvent;
         state.status.getAdminEvent = eventsInitialState.status.getAdminEvent;
        },
        cleanUpEvent: (state: EventsState) => {
         state.event = eventsInitialState.event;
        },
        cleanUpEventsTable: (state: EventsState) => {
			state.getEventsParams = eventsInitialState.getEventsParams;
			state.events = eventsInitialState.events;
			state.status.getEvents = eventsInitialState.status.getEvents;
         state.eventsCountries = eventsInitialState.eventsCountries;
         state.status.getEventsCountries = eventsInitialState.status.getEventsCountries;
		  },
        cleanUpAdminEventsTable: (state: EventsState) => {
			state.getAdminEventsParams = eventsInitialState.getAdminEventsParams;
			state.adminEvents = eventsInitialState.adminEvents;
			state.status.getAdminEvents = eventsInitialState.status.getAdminEvents;
		  },
    }
});

export default globalEventsSlice;

export const {
    getAdminEventsStart,
    getAdminEventsSuccess,
    getAdminEventsFailure,

    getAdminEventStart,
    getAdminEventSuccess,
    getAdminEventFailure,

    adminCreateEventStart,
    adminCreateEventSuccess,
    adminCreateEventFailure,

    getEventsStart,
    getEventsSuccess,
    getEventsFailure,

    getRecentlyAddedEventsStart,
    getRecentlyAddedEventsSuccess,
    getRecentlyAddedEventsFailure,

    getPromotedEventsStart,
    getPromotedEventsSuccess,
    getPromotedEventsFailure,

    getEventsCountriesStart,
    getEventsCountriesSuccess,
    getEventsCountriesFailure,

    getEventStart,
    getEventSuccess,
    getEventFailure,

    createEventStart,
    createEventSuccess,
    createEventFailure,

    confirmEventStart,
    confirmEventSuccess,
    confirmEventFailure,

    updateEventStart,
    updateEventSuccess,
    updateEventFailure,

    updateEventStatusStart,
    updateEventStatusSuccess,
    updateEventStatusFailure,

    cleanUpEventsStatus,
    cleanUpAdminEvent,
    cleanUpEvent,
    cleanUpEventsTable,
    cleanUpAdminEventsTable
} = globalEventsSlice.actions;