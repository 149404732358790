import { EventCategory } from "App/common/enums";
import { key_Drift, key_JamboreesAndSpots, key_Others, key_Races, key_Rallies, key_RcModels } from "App/common/utils/eventCategoryGrouping.utils";
import moment, { Moment } from "moment";

export const EventUtils = {
    buildDateString: (dateStart: Moment, dateEnd: Moment): string => {
        dateStart = moment(dateStart.toString());
        dateEnd = moment(dateEnd.toString());

        const endDateString = dateEnd.format("DD.MM.YYYY");

        if(dateStart.year() === dateEnd.year()) {
            if(dateStart.month() === dateEnd.month()) {
                if(dateStart.date() === dateEnd.date()) {
                    return endDateString;
                }
                return `${dateStart.format("DD")}-${endDateString}`;
            }
            return `${dateStart.format("DD.MM")}-${endDateString}`;
        }
        return `${dateStart.format("DD.MM.YYYY")}-${endDateString}`;
    },

    categoryGroupColor: (key: string | number): string => {
        if(key === key_Rallies) return '#3ECC0A';
        if(key === key_Races) return '#ab1313';
        if(key === key_Others) return 'gold';
        if(key === key_JamboreesAndSpots) return '#5c290c';
        if(key === key_Drift) return 'violet';
        if(key === key_RcModels) return 'black';

        return 'white';
    },

    categoryColor: (category: EventCategory): string => {
        let color = 'blue';

        category = EventCategory[category] as any;

        // Rajdy
        if(category === EventCategory.Rally) color = '#3ECC0A';
        if(category === EventCategory.Kjs) color = '#3ECC0A';
        if(category === EventCategory.Rallysprint) color = '#3ECC0A';
        if(category === EventCategory.SuperStage) color = '#3ECC0A';
        if(category === EventCategory.SuperSprint) color = '#3ECC0A';
        if(category === EventCategory.Offroad) color = '#3ECC0A';
        if(category === EventCategory.RSMP) color = '#3ECC0A';
        if(category === EventCategory.HRSMP) color = '#3ECC0A';
        if(category === EventCategory.RMPZ) color = '#3ECC0A';
        if(category === EventCategory.RSMSL) color = '#3ECC0A';
        if(category === EventCategory.RSMDSL) color = '#3ECC0A';
        if(category === EventCategory.RO) color = '#3ECC0A';
        if(category === EventCategory.SKJS) color = '#3ECC0A';
        if(category === EventCategory.Wrc) color = '#3ECC0A';

        // Wyścigi
        if(category === EventCategory.RaceFlat) color = '#ab1313';
        if(category === EventCategory.RaceMountain) color = '#ab1313';
        if(category === EventCategory.Rallycross) color = '#ab1313';
        if(category === EventCategory.WreckRace) color = '#ab1313';
        if(category === EventCategory.TimeAttack) color = '#ab1313';
        if(category === EventCategory.TrackDay) color = '#ab1313';
        if(category === EventCategory.DragRace) color = '#ab1313';
        if(category === EventCategory.Autocross) color = '#ab1313';
        if(category === EventCategory.Sprint) color = '#ab1313';

        // Drift
        if(category === EventCategory.DriftCompPro) color = 'violet';
        if(category === EventCategory.DriftCompAm) color = 'violet';
        if(category === EventCategory.DriftTraining) color = 'violet';
        if(category === EventCategory.DriftOther) color = 'violet';

        // Modele RC
        if(category === EventCategory.RcDrift) color = 'black';
        if(category === EventCategory.RcTrial) color = 'black';
        if(category === EventCategory.RcOffroad) color = 'black';
        if(category === EventCategory.RcRally) color = 'black';
        if(category === EventCategory.RcIndoorRace) color = 'black';
        if(category === EventCategory.RcShow) color = 'black';

        // Inne
        if(category === EventCategory.Fair) color = 'gold';
        if(category === EventCategory.Training) color = 'gold';
        if(category === EventCategory.Schooling) color = 'gold';
        if(category === EventCategory.FreeRide) color = 'gold';
        if(category === EventCategory.Charity) color = 'gold';
        if(category === EventCategory.Integration) color = 'gold';
        if(category === EventCategory.Exhibition) color = 'gold';

        // Zloty i spoty
        if(category === EventCategory.Jamboree) color = '#5c290c';
        if(category === EventCategory.Meetup) color = '#5c290c';
        if(category === EventCategory.Classic) color = '#5c290c';
        if(category === EventCategory.Oldtimer) color = '#5c290c';
        if(category === EventCategory.American) color = '#5c290c';
        if(category === EventCategory.Stance) color = '#5c290c';
        if(category === EventCategory.Tuning) color = '#5c290c';
        if(category === EventCategory.Bmw) color = '#5c290c';
        if(category === EventCategory.Vw) color = '#5c290c';
        if(category === EventCategory.Jdm) color = '#5c290c';

        return color;
    }
}